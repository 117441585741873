// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faMapMarkerAlt,
//   faDollarSign,
//   faCreditCard,
// } from '@fortawesome/free-solid-svg-icons';
// import 'tailwindcss/tailwind.css';

// export default function FinPlan() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'SERVICES'}
//         h1={'FINANCIAL'}
//         h1Blue={'PLANNING'}
//         backgroundImage={'../img/finplan-bg.jpg'}
//       />
//       <main>
//         <div className="bg-green-100 mb-6 flex px-10 py-11">
//           <div className="w-1/2 flex items-center justify-center">
//             <i
//               className="fas fa-utensils text-green-900"
//               style={{ fontSize: '15vw' }}></i>
//           </div>
//           <div className="w-1/2">
//             <div className="flex items-center gap-4 mb-6">
//               <h1 className="font-bold uppercase text-2xl">
//                 A PLAN THAT PUTS THE BEST FINANCIAL CHOICES IN YOUR HANDS
//               </h1>
//             </div>
//             <div className="flex flex-col gap-6">
//               <span className="flex gap-6 items-center">
//                 <p>
//                   These days, it's more important than ever to have a plan. Our
//                   version of financial planning not only gives you the
//                   confidence to know you're ready for anything, but is also
//                   designed to help you reach all your goals in the days ahead.
//                   Our advisors will design a personalized financial plan with
//                   the right
//                 </p>
//                 <p>
//                   insurance and investment strategies, so you can take care of
//                   the people and things that matter most. And with the highest
//                   financial strength ratings of any US life insurer, you can
//                   count on Convex Integrated to be here whenever you need us. After
//                   all, we've been going strong for over 5 years.
//                 </p>
//               </span>
//               <span className="border capitalize text-lg flex items-center w-72 bg-green-200">
//                 <a
//                   className="bg-green-900 text-center w-1/2 hover:bg-green-200 hover:text-slate-900 text-slate-50 px-4 py-2"
//                   href="/signUp">
//                   get started
//                 </a>
//                 <a
//                   className="hover:bg-green-900 text-center w-1/2 hover:text-slate-50 text-slate-950 px-4 py-2"
//                   href="/contact">
//                   contact us
//                 </a>
//               </span>
//             </div>
//           </div>
//         </div>

//         <div className="px-8">
//           {/* Centered red heart icon */}
//           <div className="flex flex-col items-center py-2">
//             <i className="fas fa-heart text-red-500 text-3xl mb-2"></i>
//             <div className="flex items-center w-full">
//               <hr className="w-1/2" />
//               <h1 className="text-center text-3xl font-semibold mx-4">
//                 WHAT OUR CLIENTS HAVE TO SAY
//               </h1>
//               <hr className="w-1/2" />
//             </div>
//           </div>

//           <div className="flex gap-4 items-center py-11">
//             <span className="border p-4 flex items-start space-x-2">
//               {/* Watch icon */}
//               <i className="fas fa-watch text-xl"></i>
//               <div className="py-8">
//                 <p className="text-lg">
//                   We met with our advisor to discuss our current and future
//                   investment needs as well as life insurance. He not only helped
//                   set us up with exactly what we needed, but he thoroughly
//                   explained everything we were doing so we knew we were making
//                   the right decisions. He made us feel comfortable from our
//                   first meeting with him.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <p className="text-lg">
//                   My advisor at Convex Integrated has been my go-to-guy for personal
//                   and business financial planning. He’s not pushy and won’t sell
//                   you products just for the commission. He sells on principle,
//                   which is why they’ll be my financial advisor for years to
//                   come.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               {/* Gym lift icon */}
//               <i className="fas fa-dumbbell text-xl"></i>
//               <div className="py-8">
//                 <p className="text-lg">
//                   Our advisor has given me peace of mind for the last 20 years.
//                   His financial knowledge and ability to communicate simply make
//                   me feel like a true partner in the finance planning process.
//                   He always has time to speak to you and allay any concerns
//                   immediately. My retirement is a solid one due to his guiding
//                   me all these years.
//                 </p>
//               </div>
//             </span>
//             <hr className="py-10" />
//           </div>

//           <div className="px-10 py-11">
//             <h1 className="font-bold text-3xl uppercase text-center mb-4">
//               OUR VERSION OF FINANCIAL PLANNING
//             </h1>
//             <p className="text-center text-lg  ">
//               Some companies only focus on insurance, Others on investments. Our
//               advisors look at your financial big picture and recommend the
//               right insurance and investment strategies in one integrated
//               financial plan, designed to help you live the life you've always
//               wanted.
//             </p>
//             <p className="text-center py-6 text-lg  ">
//               For those with significant means, our Convex Integrated Private Client
//               Group offers an exclusive comprehensive approach to private wealth
//               management combining the strength of Convex Integrated with the
//               specialized expertise of a boutique firm.
//             </p>
//           </div>
//           <div>
//             <hr />
//           </div>
//           <div className="flex items-center justify-between px-4 py-10 gap-8">
//             <div className="text-gray-600 text-lg">
//               <ul className="list-disc ml-6 ">
//                 <h2 className="mb-2">
//                   To get you where you want to go, we'll look at where you are
//                   today.
//                 </h2>
//                 <li>Your priorities, values, and goals</li>
//                 <li>Your income</li>
//                 <li>Your assets</li>
//                 <li>Your liabilities</li>
//               </ul>
//               <ul className="list-disc mt-4 ml-6">
//                 <h2 className="mb-2">
//                   Life is full of ups and downs. We'll help make sure you have
//                   everything you need for both.
//                 </h2>
//                 <li>Life insurance</li>
//                 <li>Disability insurance</li>
//                 <li>Emergency fund</li>
//                 <li>Long-term care planning</li>
//               </ul>
//               <ul className="list-disc mt-4 ml-6">
//                 <h2 className="mb-2">
//                   We'll help you balance paying down debt while you make
//                   progress on other goals.
//                 </h2>

//                 <li>Credit card debt</li>
//                 <li>Student loans</li>
//                 <li>Mortgage and other loans</li>
//               </ul>
//               <a
//                 href="/signUp"
//                 alt="certificate"
//                 className="bg-blue-600 mt-8 mb-8 block text-center uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
//                 Start Planning
//               </a>
//             </div>
//             <div className="flex space-x-4 overflow-hidden py-10 justify-center">
//               <Card
//                 title="START WITH WHERE YOU ARE"
//                 icon={faMapMarkerAlt}
//                 animationDelay="animate-delay-0"
//                 boxShadow="0 4px 20px 0 rgba(255, 0, 0, 0.5)"
//                 scale={1.3}
//               />
//               <Card
//                 title="PROTECT WHAT YOU HAVE"
//                 icon={faDollarSign}
//                 animationDelay="animate-delay-1"
//                 boxShadow="0 4px 15px 0 rgba(255, 0, 0, 0.4)"
//                 scale={1.1}
//               />
//               <Card
//                 title="TACKLE YOUR DEBT"
//                 icon={faCreditCard}
//                 animationDelay="animate-delay-2"
//                 boxShadow="0 4px 10px 0 rgba(255, 0, 0, 0.3)"
//                 scale={1}
//               />
//             </div>
//           </div>
//           <hr />

//           <div className="flex items-center justify-between px-4 py-10 gap-8">
//             <div className="flex space-x-4 overflow-hidden py-10 justify-center">
//               <CardR
//                 title="PUT YOUR PLAN INTO ACTION"
//                 icon={faMapMarkerAlt}
//                 animationDelay="animate-delay-0"
//                 boxShadow="0 4px 20px 0 rgba(255, 0, 0, 0.5)"
//                 scale={1.15}
//               />
//               <CardR
//                 title="STEP BY STEP"
//                 icon={faDollarSign}
//                 animationDelay="animate-delay-1"
//                 boxShadow="0 4px 15px 0 rgba(255, 0, 0, 0.4)"
//                 scale={1.3}
//               />
//               <CardR
//                 title="GROW YOUR MONEY"
//                 icon={faCreditCard}
//                 animationDelay="animate-delay-2"
//                 boxShadow="0 4px 10px 0 rgba(255, 0, 0, 0.3)"
//                 scale={1.4}
//               />
//             </div>
//             <div className="flex space-x-4 overflow-hidden py-10 justify-center">
//               <div className="text-gray-600 text-lg">
//                 <ul className="list-disc ml-6 ">
//                   <h2 className="mb-2">
//                     Get realistic saving and investing strategies to help
//                     achieve each of your goals.
//                   </h2>
//                   <li>Retirement</li>
//                   <li>Growing your family</li>
//                   <li>Buying a home</li>
//                   <li>Saving for college</li>
//                 </ul>
//                 <ul className="list-disc mt-4 ml-6">
//                   <h2 className="mb-2">
//                     Get a free, step-by-step guide that will help you bring your
//                     financial plan to life.
//                   </h2>
//                   <li>A personalized budget</li>
//                   <li>Goal calendar and contribution trackers</li>
//                   <li>Insurance and investment advice</li>
//                   <li>Estate considerations</li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//       <hr />
//       <Footer />
//     </div>
//   );
// }

// const Card = ({ title, icon, animationDelay, boxShadow, scale }) => (
//   <div
//     className={`bg-white p-6 rounded-lg transform transition-all duration-500 ease-out ${animationDelay}`}
//     style={{
//       boxShadow,
//       width: `${scale * 200}px`,
//       height: `${scale * 200}px`,
//     }}>
//     <div
//       className={`animate-slide-in flex flex-col items-center justify-center h-full space-y-4`}>
//       <FontAwesomeIcon
//         icon={icon}
//         className="text-6xl text-red-500"
//       />
//       <h1 className="text-xl font-bold text-center">{title}</h1>
//     </div>
//   </div>
// );

// const CardR = ({ title, icon, animationDelay, boxShadow, scale }) => (
//   <div
//     className={`bg-white p-6 rounded-full transform transition-all duration-500 ease-out ${animationDelay}`}
//     style={{
//       boxShadow,
//       width: `${scale * 200}px`,
//       height: `${scale * 200}px`,
//     }}>
//     <div
//       className={`animate-slide-in flex flex-col items-center justify-center h-full space-y-4`}>
//       <FontAwesomeIcon
//         icon={icon}
//         className="text-6xl text-red-500"
//       />
//       <h1 className="text-xl font-bold text-center">{title}</h1>
//     </div>
//   </div>
// );
// // const CardR = ({ title, icon, animationDelay, boxShadow, scale }) => {
// //   return (
// //     <div
// //       className={`bg-white rounded-full overflow-hidden shadow-lg ${animationDelay} transform scale-${scale}`}>
// //       <div className="p-6 flex items-center justify-center">
// //         <FontAwesomeIcon
// //           icon={icon}
// //           className="text-red-500 text-4xl"
// //         />
// //       </div>
// //       <div className="px-6 py-4">
// //         <h2 className="font-bold text-xl text-center">{title}</h2>
// //       </div>
// //     </div>
// //   );
// // };

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faDollarSign,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';
import 'tailwindcss/tailwind.css';

export default function FinPlan() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'FINANCIAL'}
        h1Blue={'PLANNING'}
        backgroundImage={'../img/finplan-bg.jpg'}
      />
      <main>
        <div className="bg-green-100 mb-6 flex flex-col sm:flex-row px-10 py-11">
          <div className="w-full sm:w-1/2 flex items-center justify-center mb-6 sm:mb-0">
            <i className="fas fa-utensils text-green-900 text-6xl sm:text-8xl"></i>
          </div>
          <div className="w-full sm:w-1/2">
            <div className="flex items-center gap-4 mb-6">
              <h1 className="font-bold uppercase text-lg sm:text-3xl">
                A PLAN THAT PUTS THE BEST FINANCIAL CHOICES IN YOUR HANDS
              </h1>
            </div>
            <div className="flex flex-col gap-6">
              <p className=" text-sm sm:text-lg">
                These days, it's more important than ever to have a plan. Our
                version of financial planning not only gives you the confidence
                to know you're ready for anything, but is also designed to help
                you reach all your goals in the days ahead. Our advisors will
                design a personalized financial plan with the right insurance
                and investment strategies, so you can take care of the people
                and things that matter most. And with the highest financial
                strength ratings of any US life insurer, you can count on Convex
                Integrated to be here whenever you need us. After all, we've
                been going strong for over 5 years.
              </p>
              <div className="flex gap-4 items-center">
                <a
                  href="/signUp"
                  className="bg-green-900 text-center hover:bg-green-200 hover:text-slate-900 text-slate-50 px-4 py-2 uppercase text-sm sm:text-base">
                  Get Started
                </a>
                <a
                  href="/contact"
                  className="text-center hover:bg-green-900 hover:text-slate-50 text-slate-950 px-4 py-2 uppercase text-sm sm:text-base">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="px-8">
          {/* Centered red heart icon */}
          <div className="flex flex-col items-center py-2">
            <i className="fas fa-heart text-red-500 text-3xl mb-2"></i>
            <div className="flex items-center w-full">
              <hr className="w-1/2" />
              <h1 className="sm:text-center text-left sm:text-3xl font-semibold mx-4">
                WHAT OUR CLIENTS HAVE TO SAY
              </h1>
              <hr className="w-1/2" />
            </div>
          </div>

          <div className="flex flex-col gap-4 sm:flex-row items-start py-11">
            <Testimonial
              icon="fas fa-watch"
              text="We met with our advisor to discuss our current and future investment needs as well as life insurance. He not only helped set us up with exactly what we needed, but he thoroughly explained everything we were doing so we knew we were making the right decisions. He made us feel comfortable from our first meeting with him."
            />
            <Testimonial
              icon="fas fa-apple-alt"
              text="My advisor at Convex Integrated has been my go-to-guy for personal and business financial planning. He’s not pushy and won’t sell you products just for the commission. He sells on principle, which is why they’ll be my financial advisor for years to come."
            />
            <Testimonial
              icon="fas fa-dumbbell"
              text="Our advisor has given me peace of mind for the last 20 years. His financial knowledge and ability to communicate simply make me feel like a true partner in the finance planning process. He always has time to speak to you and allay any concerns immediately. My retirement is a solid one due to his guiding me all these years."
            />
          </div>

          <div className="px-10 py-11 text-sm">
            <h1 className="font-bold text-lg sm:text-3xl uppercase text-center mb-4">
              OUR VERSION OF FINANCIAL PLANNING
            </h1>
            <p className="sm:text-center  sm:text-lg">
              Some companies only focus on insurance, Others on investments. Our
              advisors look at your financial big picture and recommend the
              right insurance and investment strategies in one integrated
              financial plan, designed to help you live the life you've always
              wanted.
            </p>
            <p className="sm:text-center mt-4  sm:text-lg">
              For those with significant means, our Convex Integrated Private
              Client Group offers an exclusive comprehensive approach to private
              wealth management combining the strength of Convex Integrated with
              the specialized expertise of a boutique firm.
            </p>
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-10 gap-8">
            <StrategyCard
              title="START WITH WHERE YOU ARE"
              icon={faMapMarkerAlt}
              delayClass="animate-delay-0"
              boxShadow="0 4px 20px 0 rgba(255, 0, 0, 0.5)"
              scale={1.3}
            />
            <StrategyCard
              title="PROTECT WHAT YOU HAVE"
              icon={faDollarSign}
              delayClass="animate-delay-1"
              boxShadow="0 4px 15px 0 rgba(255, 0, 0, 0.4)"
              scale={1.1}
            />
            <StrategyCard
              title="TACKLE YOUR DEBT"
              icon={faCreditCard}
              delayClass="animate-delay-2"
              boxShadow="0 4px 10px 0 rgba(255, 0, 0, 0.3)"
              scale={1}
            />
          </div>

          <hr className="my-8" />

          <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-10 gap-8">
            <ActionCard
              title="PUT YOUR PLAN INTO ACTION"
              icon={faMapMarkerAlt}
              delayClass="animate-delay-0"
              boxShadow="0 4px 20px 0 rgba(255, 0, 0, 0.5)"
              scale={1.15}
            />
            <ActionCard
              title="STEP BY STEP"
              icon={faDollarSign}
              delayClass="animate-delay-1"
              boxShadow="0 4px 15px 0 rgba(255, 0, 0, 0.4)"
              scale={1.3}
            />
            <ActionCard
              title="GROW YOUR MONEY"
              icon={faCreditCard}
              delayClass="animate-delay-2"
              boxShadow="0 4px 10px 0 rgba(255, 0, 0, 0.3)"
              scale={1.4}
            />
          </div>

          <div className="px-4 py-8 text-sm">
            <p className="sm:text-lg text-sm text-gray-600">
              To get you where you want to go, we'll look at where you are
              today:
            </p>
            <ul className="list-disc ml-8 mb-4  sm:text-lg text-gray-600">
              <li>Your priorities, values, and goals</li>
              <li>Your income, assets, and liabilities</li>
            </ul>
            <p className="text-sm sm:text-lg text-gray-600">
              Life is full of ups and downs. We'll help make sure you have
              everything you need for both:
            </p>
            <ul className="list-disc ml-8 mb-4 text-sm sm:text-lg text-gray-600">
              <li>Life insurance, disability insurance, and emergency fund</li>
              <li>Long-term care planning and more</li>
            </ul>
            <p className="text-sm sm:text-lg text-gray-600">
              We'll help you balance paying down debt while you make progress on
              other goals:
            </p>
            <ul className="list-disc ml-8 mb-4 text-sm sm:text-lg text-gray-600">
              <li>Credit card debt, student loans, and mortgage</li>
            </ul>
            <a
              href="/signUp"
              className="bg-blue-600 mt-4 mb-8 block text-center uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
              Start Planning
            </a>
          </div>
        </div>
      </main>
      <hr className="my-8" />
      <Footer />
    </div>
  );
}

const Testimonial = ({ icon, text }) => (
  <div className="border p-4 flex flex-col items-start space-x-2 mb-6 sm:w-1/3">
    <i className={`${icon} text-xl`}></i>
    <p className="sm:text-lg text-sm">{text}</p>
  </div>
);

const StrategyCard = ({ title, icon, delayClass, boxShadow, scale }) => (
  <div
    className={`bg-white p-6 rounded-lg transform transition-all duration-500 ease-out ${delayClass}`}
    style={{
      boxShadow,
      width: `${scale * 200}px`,
      height: `${scale * 200}px`,
    }}>
    <div className="animate-slide-in flex flex-col items-center justify-center h-full space-y-4">
      <FontAwesomeIcon
        icon={icon}
        className="text-6xl text-red-500"
      />
      <h1 className="text-xl font-bold text-center">{title}</h1>
    </div>
  </div>
);

const ActionCard = ({ title, icon, delayClass, boxShadow, scale }) => (
  <div
    className={`bg-white p-6 rounded-full transform transition-all duration-500 ease-out ${delayClass}`}
    style={{
      boxShadow,
      width: `${scale * 200}px`,
      height: `${scale * 200}px`,
    }}>
    <div className="animate-slide-in flex flex-col items-center justify-center h-full space-y-4">
      <FontAwesomeIcon
        icon={icon}
        className="text-6xl text-red-500"
      />
      <h1 className="text-xl font-bold text-center">{title}</h1>
    </div>
  </div>
);
