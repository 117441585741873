import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';

const DepositHistory = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    const token = Cookies.get('token');

    try {
      const response = await axios.get(`${BASEURLDEV}/account/getTx`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const filteredTransactions = response.data.doc.transactions.filter(
        (transaction) => transaction.type === 'credit'
      );
      setTransactions(filteredTransactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'declined':
        return 'text-red-500';
      case 'pending':
        return 'text-yellow-500';
      case 'successful':
        return 'text-green-500';
      default:
        return '';
    }
  };

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-4">Transaction History</h2>
      {transactions.length === 0 ? (
        <p className="text-center text-gray-500">
          No deposits to show for now.
        </p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-900 text-white">
            <thead>
              <tr className="bg-gray-800">
                <th className="py-2 px-4 border-b border-gray-700">
                  Transaction ID
                </th>
                <th className="py-2 px-4 border-b border-gray-700">Amount</th>
                <th className="py-2 px-4 border-b border-gray-700">Type</th>
                <th className="py-2 px-4 border-b border-gray-700">Status</th>
                <th className="py-2 px-4 border-b border-gray-700">Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-700">
                  <td className="py-2 px-4">{transaction.transactionId}</td>
                  <td className="py-2 px-4">{transaction.amount}</td>
                  <td className="py-2 px-4">{transaction.type}</td>
                  <td
                    className={`py-2 px-4 ${getStatusColor(
                      transaction.status
                    )}`}>
                    {transaction.status}
                  </td>
                  <td className="py-2 px-4">
                    {new Date(transaction.createdAt).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DepositHistory;
