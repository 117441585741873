// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function MultiAsset() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'INVESTMENT PRODUCTS'}
//         h1={'MULTI'}
//         h1Blue={'ASSET'}
//         backgroundImage={'../img/multi-bg.jpg'}
//       />
//       <main className="">
//         {/* <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">
//             TRADE CRYPTOCURRENCY CFDS WITH COIN BROKAGE
//           </h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               What is a cryptocurrency? A cryptocurrency is a digital asset
//               conceived for use as a medium of exchange, which uses cryptography
//               to secure transactions, control the supply of additional units and
//               corroborate transfers. In short, cryptocurrency is a decentralised
//               electronic currency. Why trade cryptocurrencies? Cryptocurrencies
//               have the tendency to be
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               particularly volatile, so they provide various opportunities for
//               traders to open positions with big movements. Leveraged trading
//               provides high liquidity, matched with the reputation
//               cryptocurrencies have for being highly volatile, means that
//               trading cryptocurrencies with Capital.com provides greater
//               opportunities in markets.
//             </p>
//           </span>
//         </div> */}
//         <h1 className="font-bold font-sans w-1/2 mx-auto py-4 mt-6 text-2xl text-center">
//           OUR EXPERTISE ACROSS A WIDE RANGE OF STRATEGIES DRIVES INNOVATIVE
//           SOLUTIONS.
//         </h1>
//         <p>
//           Since 2002, against the backdrop of some of the most challenging
//           financial conditions in history, our robust and repeatable multi asset
//           investment process and experienced Multi Asset Group have achieved
//           equity-like returns with lower relative volatility.
//         </p>

//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//         <div className="flex gap-11 py-11 px-6">
//           <span className="w-full flex  flex-grow-0  gap-8 text-lg">
//             <span className="w-1/2">
//               <h1 className="font-bold text-xl mb-4">MULTI ASSET INCOME</h1>
//               <p>
//                 Multi-asset income solutions designed to provide clients with
//                 stable, sustainable income in today’s low-yield environment..
//               </p>
//             </span>
//             <span className="w-1/2">
//               <h1 className="font-bold text-xl mb-4">
//                 MULTI ASSET TARGETED RETURN
//               </h1>
//               <p>
//                 Multi-asset income solutions designed to provide clients with
//                 stable, sustainable income in today’s low-yield environment.
//               </p>
//             </span>
//             <span className="grow-0">
//               <h1 className="font-bold text-xl mb-4">BALANCED</h1>
//               <p>
//                 Aims to deliver a long-term annualized return in excess of cash
//                 / inflation, with considerably less risk than equity.
//               </p>
//             </span>
//           </span>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function MultiAsset() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'INVESTMENT PRODUCTS'}
        h1={'MULTI'}
        h1Blue={'ASSET'}
        backgroundImage={'../img/multi-bg.jpg'}
      />
      <main>
        <h1 className="font-bold font-sans w-11/12 sm:w-1/2 mx-auto py-4 mt-6 text-xl sm:text-2xl text-center">
          OUR EXPERTISE ACROSS A WIDE RANGE OF STRATEGIES DRIVES INNOVATIVE
          SOLUTIONS.
        </h1>
        <p className="w-11/12 sm:w-3/4 mx-auto text-sm sm:text-base text-center mt-4">
          Since 2002, against the backdrop of some of the most challenging
          financial conditions in history, our robust and repeatable multi asset
          investment process and experienced Multi Asset Group have achieved
          equity-like returns with lower relative volatility.
        </p>

        <hr className="mx-auto w-4/5 mt-10 pb-4" />

        <div className="flex flex-col lg:flex-row gap-11 py-11 px-6">
          <div className="w-full lg:w-1/3">
            <h1 className="font-bold text-lg sm:text-xl mb-4">
              MULTI ASSET INCOME
            </h1>
            <p className="text-sm sm:text-base">
              Multi-asset income solutions designed to provide clients with
              stable, sustainable income in today’s low-yield environment.
            </p>
          </div>
          <div className="w-full lg:w-1/3">
            <h1 className="font-bold text-lg sm:text-xl mb-4">
              MULTI ASSET TARGETED RETURN
            </h1>
            <p className="text-sm sm:text-base">
              Multi-asset income solutions designed to provide clients with
              stable, sustainable income in today’s low-yield environment.
            </p>
          </div>
          <div className="w-full lg:w-1/3">
            <h1 className="font-bold text-lg sm:text-xl mb-4">BALANCED</h1>
            <p className="text-sm sm:text-base">
              Aims to deliver a long-term annualized return in excess of cash /
              inflation, with considerably less risk than equity.
            </p>
          </div>
        </div>

        <hr className="mx-auto w-4/5 mt-10 pb-4" />
      </main>
      <Footer />
    </div>
  );
}
