import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function LongTermCare() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'LONG'}
        h1Blue={'TERM CARE'}
        backgroundImage={'../img/retire-bg.jpg'}
      />
      <main className="text-sm">
        <div className="bg-gray-200 text-lg px-11 py-11">
          <h1 className="text-gray-500 mb-4 font-bold text-lg sm:text-3xl">
            What is long-term care?
          </h1>

          <p className="pb-11 text-sm">
            What is long-term care? Long-term care is something that most people
            may not think they need, or might think is covered by health
            insurance or Medicare. The fact is, if you live to be 65, there's a
            70% chance you'll eventually need some kind of long-term care.1 But
            aging isn't the only reason to plan for long-term care—it's there
            for you if a chronic illness or disabling injury prevents you from
            living on your own or properly caring for yourself, no matter how
            old you are. Long-term care helps with day-to-day tasks like
            bathing, eating, getting dressed, and getting in and out of bed. And
            it includes care provided by nursing homes, assisted living
            facilities, adult day care centers, hospice facilities, and skilled
            nurses or home health aides in your (or your loved one's) home. If
            you're not the one who needs it, there's a good chance you'll need
            to help care for a loved one.2 And having a long-term care plan in
            place can help you continue to live well without sacrificing the
            income, investments, and savings you've worked so hard for. Reasons
            to plan for long-term care
          </p>
        </div>

        {/* <div className="py-11 my-11">
          <h1 className="text-center font-bold uppercase text-3xl my-4">
            OUR SERVICES, TAILORED TO YOU
          </h1>
          <hr className="w-1/2 mx-auto" />
          <div className="flex gap-6 items-center justify-center">
            <div class="text-gray-900 text-lg my-4">
              <ul class="list-disc ml-6">
                <li>Financial Planning</li>
                <li>Investment Planning & Strategies</li>
                <li>Advanced Portfolio Management</li>
                <li>Retirement Planning & Income Strategies</li>
                <li>Estate Planning & Trust Services</li>
                <li>Risk Management</li>
              </ul>
            </div>
            <div class="text-gray-900 text-lg">
              <ul class="list-disc ml-6">
                <li>Tax Efficient Strategies</li>
                <li>Advisor Coordination</li>
                <li>Business Planning & Succession</li>
                <li>Multigenerational Planning</li>
                <li>Charitable Planning</li>
                <li>Education Funding</li>
              </ul>
            </div>
          </div>
        </div> */}
      </main>
      <hr />
      <Footer />
    </div>
  );
}
