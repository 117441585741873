import { Link } from 'react-router-dom';

export default function RecentTab({ title, headers, data, link }) {
  return (
    <div className="p-4 bg-slate-800 shadow rounded-md">
      <div className="flex justify-between items-center mb-4">
        <p className="text-xl font-bold text-white">{title}</p>
        <Link
          to={link}
          className="text-blue-400 hover:text-blue-600 transition">
          View All
        </Link>
      </div>
      {data.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-slate-900 text-white border border-gray-700">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className="px-4 py-2 border-b border-gray-600 bg-gray-700 text-left text-sm font-medium text-gray-300">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="border-b border-gray-700 hover:bg-gray-600 transition">
                  {headers.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      className="px-4 py-2 text-sm text-gray-300 whitespace-nowrap">
                      {row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center py-6 text-gray-400">
          No recent {title.toLowerCase()} to display.
        </div>
      )}
    </div>
  );
}
