import React, { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { handleServerError } from '../utils/error.handler';
import { BASEURLDEV } from '../constants/api';
import Notify from './toastify.comp';

const FundAccount = () => {
  const [step, setStep] = useState(1); // State to track current step
  const [paymentPlatform, setPaymentPlatform] = useState(''); // State for selected payment platform
  const [accountAddress, setAccountAddress] = useState(''); // State for account address
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [amount, setAmount] = useState(0);

  const accountAddresses = {
    btc: 'bc1qa05v5vqk7frzvqq4hc7py98acgt5tfdx2knyxz',
    eth: '0x17c95DEc0e9a553B973505bb34377e9d4d026617',
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  // Function to handle payment platform selection
  const handlePlatformSelect = (platform) => {
    setPaymentPlatform(platform);
    setAccountAddress(accountAddresses[platform]);
  };

  // Function to copy text to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(accountAddress);
    alert('Address copied to clipboard!');
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    const token = Cookies.get('token');
    const amountInNum = Number(amount); // Extract token from cookies

    try {
      // Make an API call with the token
      const response = await axios.post(
        `${BASEURLDEV}/account/fundAccount`,
        { amount: amountInNum },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle response (optional)
      console.log(response.data);

      setIsSubmitted(true);
    } catch (error) {
      // Handle error
      console.error('Error funding account:', error);
      handleServerError(error.response.status, error.response.data.message);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-gray-900 text-white rounded-md shadow-md">
      {/* Step 1: Choose Payment Platform and Amount */}
      {!isSubmitted && step === 1 && (
        <div>
          <h2 className="text-lg font-semibold mb-4">
            Step 1: Choose Payment Platform and Amount
          </h2>
          {/* Payment Platform Selection */}
          <div className="mb-4 relative">
            <label
              htmlFor="paymentPlatform"
              className="block font-medium mb-1">
              Choose Payment Platform:
            </label>
            <select
              id="paymentPlatform"
              name="paymentPlatform"
              value={paymentPlatform}
              onChange={(e) => handlePlatformSelect(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-600 rounded">
              <option
                value=""
                disabled>
                Select a platform
              </option>
              <option value="btc">Bitcoin (BTC)</option>
              <option value="eth">Ethereum</option>
              {/* Add more options as needed */}
            </select>
          </div>
          {/* Amount Input */}
          <div className="mb-4">
            <label
              htmlFor="amount"
              className="block font-medium mb-1">
              Enter Amount:
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              onChange={(e) => setAmount(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-600 rounded"
              placeholder="Enter amount to fund"
            />
          </div>
          {/* Navigation Buttons */}
          <div className="flex justify-end space-x-3">
            <button
              onClick={nextStep}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 focus:outline-none focus:shadow-outline">
              Next
            </button>
          </div>
        </div>
      )}

      {/* Step 2: Activation Instruction */}
      {!isSubmitted && step === 2 && (
        <div>
          <h2 className="text-lg font-semibold mb-4">
            Step 2: Activation Instruction
          </h2>
          <p className="mb-4">
            Kindly make payment to the account provided and contact support team
            with proof of payment (eg. screentshot) to confirm deposit
          </p>

          <div className="mb-4">
            <label
              htmlFor="accountAddress"
              className="block font-medium mb-1">
              Account Address:
            </label>
            <div className="flex items-center">
              <input
                type="text"
                id="accountAddress"
                name="accountAddress"
                className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-600 rounded"
                value={accountAddress}
                readOnly
              />
              <button
                onClick={copyToClipboard}
                className="ml-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 focus:outline-none focus:shadow-outline">
                Copy
              </button>
            </div>
          </div>
          {/* Navigation Buttons */}
          <div className="flex justify-between">
            <button
              onClick={prevStep}
              className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 focus:outline-none focus:shadow-outline">
              Back
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 focus:outline-none focus:shadow-outline">
              Submit
            </button>
          </div>
        </div>
      )}

      {/* Congratulations Message */}
      {isSubmitted && (
        <div className="text-center p-4 bg-green-900 text-white rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Congratulations!</h2>
          <p>You are one step closer</p>
          <a
            className="w-full bg-slate-50 px-2 capitalize py-2 rounded-md mt-4 block text-slate-950"
            href="mailto:support@convexintegrate.com">
            confirm deposit
          </a>
        </div>
      )}
      <Notify />
    </div>
  );
};

export default FundAccount;
