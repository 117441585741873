import { Link } from 'react-router-dom';
export default function Logo() {
  return (
    <Link to="/">
      <div className="max-h-10 max-w-10 rounded-full">
        {' '}
        <img
          src="../img/convex-log.jpg"
          alt="a logo prop"
          className=" rounded-full"
        />
      </div>
    </Link>
  );
}
