import React from 'react';
import NavBar from '../component/home/homenav';
import Footer from '../component/home/footer.comp';

export default function Voc() {
  return (
    <>
      <NavBar />
      <div className="mx-auto pt-11 py-11 w-1/2">
        <img
          src="../img/cert.jfif"
          alt="voc"
          className="h-40vh" // Ensure this class is defined, or use inline styles
          style={{ height: '50vh' }}
        />
      </div>
      <Footer />
    </>
  );
}
