import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  FaBars,
  FaUser,
  FaGlobe,
  FaHome,
  FaMoneyCheckAlt,
  FaChartLine,
  FaWallet,
  FaMoneyBillAlt,
  FaExchangeAlt,
  FaLifeRing,
  FaChevronDown,
} from 'react-icons/fa';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { useAuth } from '../context/user.context';

const getUser = () => {
  const userStr = localStorage.getItem('user');
  return JSON.parse(userStr);
};

export default function DashboardPage() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const location = useLocation();
  const [user, setUser] = useState(getUser());
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const userStr = localStorage.getItem('user');
    const userParsed = JSON.parse(userStr);

    setUser(userParsed);
  }, [setUser, user]);

  const toggleProfileMenu = () => {
    setIsProfileOpen(!isProfileOpen);
    if (isQuickLinksOpen) setIsQuickLinksOpen(false);
  };

  async function handleLogout() {
    const isLoggedOut = await logout();
    if (isLoggedOut) navigate('/login');
  }
  const toggleQuickLinks = () => {
    setIsQuickLinksOpen(!isQuickLinksOpen);
    if (isProfileOpen) setIsProfileOpen(false);
  };

  const closeMenus = () => {
    setIsProfileOpen(false);
    setIsQuickLinksOpen(false);
  };

  const toggleDropdown = (label) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = async () => {
    try {
      const response = await axios.post('/api/updateUserProfile', userData);
      console.log('Profile updated successfully:', response.data);
      setIsModalOpen(false); // Close modal on successful submit
    } catch (error) {
      console.error('Error updating user profile:', error);
      // Handle error state or display error message
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const navItems = [
    { path: '/dashboard', icon: <FaHome />, label: 'Dashboard' },
    { path: '/deposits', icon: <FaMoneyCheckAlt />, label: 'Deposits' },
    { path: '/investment', icon: <FaChartLine />, label: 'Investment' },
    { path: '/wallets', icon: <FaWallet />, label: 'Wallets' },
    { path: '/withdrawal', icon: <FaMoneyBillAlt />, label: 'Withdrawal' },
    {
      path: '/dashboard/my-tx',
      icon: <FaExchangeAlt />,
      label: 'Transactions',
    },
    { path: '/support', icon: <FaLifeRing />, label: 'Support' },
  ];

  const dropdownItems = {
    Deposits: [
      { path: '/dashboard/deposit', label: 'Fund Account' },
      { path: '/dashboard/deposit-history', label: 'All Deposit' },
    ],
    Investment: [
      { path: '/dashboard/new-investment', label: 'New' },
      { path: '/dashboard/my-investment', label: 'My Portfolio' },
    ],
    Wallets: [
      { path: '/dashboard/add', label: 'Add Wallet' },
      { path: '/dashboard/all-wallet', label: 'See All Wallet' },
    ],
    Withdrawal: [
      { path: '/dashboard/request-withdrawal', label: 'Request' },
      { path: '/dashboard/see-withdrawal', label: 'Withdrawals' },
    ],
    Support: [
      { path: '/dashboard/support', label: 'New Ticket' },
      { path: '/dashboard/allTickets', label: 'My Tickets' },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta
          name="viewport"
          content="width=1024"
        />
      </Helmet>

      <div className="flex h-screen overflow-hidden">
        {/* Top Navigation Bar */}
        <header className="bg-slate-800 text-white shadow-md fixed w-full z-50">
          <div className="flex justify-between items-center py-4 px-6">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="text-2xl lg:hidden">
                <FaBars />
              </button>
              <Link to={'/'}>
                <img
                  src="../img/convex-log.jpg"
                  alt="Logo"
                  className="h-12 w-12 object-cover rounded-full"
                />
              </Link>
            </div>
            <div className="flex items-center gap-4">
              <FaGlobe className="text-2xl cursor-pointer relative">
                <FaChevronDown className="absolute top-0 right-0" />
              </FaGlobe>
              <div className="relative">
                <button
                  onClick={toggleQuickLinks}
                  className="flex items-center focus:outline-none">
                  <FaBars className="text-2xl mr-2" />
                  <span className="hidden sm:inline-block">Quick Links</span>
                </button>
                {isQuickLinksOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-1">
                    <Link
                      to="/financial-planning"
                      onClick={closeMenus}
                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                      Financial Planning
                    </Link>
                    <Link
                      to="/real-estate"
                      onClick={closeMenus}
                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                      Real Estate
                    </Link>
                    <Link
                      to="/dashboard/learn"
                      onClick={closeMenus}
                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                      How to make Deposit?
                    </Link>
                    <Link
                      to="/dashboard/pdf"
                      onClick={closeMenus}
                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                      Download Docs?
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        <main className="flex flex-1 pt-20">
          {/* Sidebar */}
          <aside
            className={`bg-slate-900 text-white fixed top-0 left-0 h-full z-40 transition-transform transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } lg:relative lg:translate-x-0 ${
              isCollapsed ? 'w-20' : 'w-64'
            } overflow-y-auto`}>
            <div className="flex items-center gap-4 justify-center border-b border-gray-700 py-4 px-6">
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="text-gray-400 hover:text-white focus:outline-none">
                <FaBars className="text-2xl" />
              </button>
              {!isCollapsed && <h1 className="text-xl font-bold">Dashboard</h1>}
            </div>
            <nav className="flex-1 py-4 px-4 text-lg">
              {navItems.map((item) => (
                <div
                  key={item.path}
                  className="relative">
                  <Link
                    to={dropdownItems[item.label] ? '#' : item.path}
                    className={`flex items-center mt-4 gap-3 px-4 py-2 text-gray-200 rounded-md hover:bg-blue-700 ${
                      location.pathname === item.path ? 'bg-blue-700' : ''
                    }`}
                    onClick={() => {
                      if (dropdownItems[item.label]) {
                        toggleDropdown(item.label);
                      } else {
                        closeMenus();
                      }
                    }}>
                    {item.icon} {!isCollapsed && item.label}
                    {dropdownItems[item.label] && !isCollapsed && (
                      <FaChevronDown
                        className={`ml-auto transition-transform ${
                          openDropdowns[item.label] ? 'rotate-180' : ''
                        }`}
                      />
                    )}
                  </Link>
                  {dropdownItems[item.label] && openDropdowns[item.label] && (
                    <div className="ml-8 mt-2">
                      {dropdownItems[item.label].map((dropdownItem) => (
                        <Link
                          key={dropdownItem.path}
                          to={dropdownItem.path}
                          className="block px-4 py-2 text-gray-300 hover:bg-blue-700 rounded-md"
                          onClick={closeMenus}>
                          {dropdownItem.label}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {/* User Profile Dropdown */}
              {/* <div className="relative mt-8 w-full">
              <button
                onClick={toggleProfileMenu}
                className="flex items-center px-4 py-2 text-gray-200 rounded-md hover:bg-blue-700 w-full">
                <FaUser className="text-2xl mr-2" />
                {!isCollapsed && (
                  <span>
                    {user.firstname} <FaChevronDown className="ml-2" />
                  </span>
                )}
              </button>
              {isProfileOpen && (
                <div className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg py-1">
                  <Link
                    to="/dashboard/updateMe"
                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                    View Profile
                  </Link>
                  <a
                    onClick={() => {
                      console.log('I clicked');
                    }}
                    href={'/dashboard/updateMe'}
                    className="block px-4 py-2 cursor-pointer text-sm text-gray-800 hover:bg-gray-100">
                    Settings
                  </a>
                  <Link
                    to="/logout"
                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                    Logout
                  </Link>
                </div>
              )}
            </div> */}
              <div className="relative mt-8 w-full">
                <button
                  onClick={toggleProfileMenu}
                  className="flex items-center px-4 py-2 text-gray-200 rounded-md hover:bg-blue-700 w-full">
                  <FaUser className="text-2xl mr-2" />
                  {!isCollapsed && (
                    <div className="flex w-full items-center justify-between">
                      <span className="px-2 py-2 capitalize">
                        {user.firstname}
                      </span>
                      <FaChevronDown className="ml-2" />
                    </div>
                  )}
                </button>
                {isProfileOpen && (
                  <div className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg py-1">
                    <Link
                      to="/dashboard/viewMe"
                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                      View Profile
                    </Link>
                    <a
                      onClick={() => {
                        console.log('I clicked');
                      }}
                      href={'/dashboard/updateMe'}
                      className="block px-4 py-2 cursor-pointer text-sm text-gray-800 hover:bg-gray-100">
                      Settings
                    </a>
                    <button
                      onClick={handleLogout}
                      className="block text-left w-full px-4 py-2 text-sm text-gray-800 hover:bg-gray-100">
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </nav>
          </aside>

          <section className="flex-1 p-8 bg-slate-950 text-slate-50 w-full overflow-x-auto">
            <Outlet />
          </section>
        </main>
      </div>
    </>
  );
}
