// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function Stock() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'PRODUCT'}
//         h1={'STOCKS'}
//         h1Blue={''}
//         backgroundImage={'../img/stock-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">
//             OWN A PIECE OF A COMPANY'S FUTURE.
//           </h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               While stocks fluctuate, growth may help you keep ahead of
//               inflation. Potentially generate income with dividends. Flexibility
//               for long- and short-
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               term investing strategies. Placing a stock trade is about a lot
//               more than pushing a button and entering your order. This brief
//               video can help you prepare before you open a position and develop
//               a plan for managing it.
//             </p>
//           </span>
//         </div>

//         <div className="flex mx-auto w-1/2 py-10  flex-col gap-4 items-center justify-items-center">
//           <h1 className="font-bold pt-10 text-3xl">WHY TRADE STOCKS?</h1>
//           <p className="text-gray-500 text-lg pb-10">
//             Stocks let you own a piece of a company’s future. They’re available
//             for a wide variety of industries—so you can tap into your knowledge
//             of specific businesses, or help you to diversify your portfolio.
//           </p>
//         </div>

//         <div>
//           <hr className="w-4/5 mx-auto py-4" />
//           <div className="flex gap-4 py-10 px-6 justify-around">
//             <span className="flex flex-col items-center p-4">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-chart-line text-xl"></i>
//                 <h2 className="text-lg font-semibold">Growth potential</h2>
//               </div>
//               <p className="text-center mt-2">
//                 While stock performance changes over time, successful stocks can
//                 help your money grow—at times, they can even outrun inflation
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-coins text-xl"></i>
//                 <h2 className="text-lg font-semibold">Income</h2>
//               </div>
//               <p className="text-center mt-2">
//                 Some stocks pay regular dividends—that’s income you can keep or
//                 reinvest
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-arrows-alt text-xl"></i>
//                 <h2 className="text-lg font-semibold">Flexibility</h2>
//               </div>
//               <p className="text-center mt-2">
//                 Since stocks trade by the millions every day, you can move
//                 quickly when you’re buying or selling
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-arrows-alt text-xl"></i>
//                 <h2 className="text-lg font-semibold">Flexibility</h2>
//               </div>
//               <p className="text-center mt-2">
//                 Since stocks trade by the millions every day, you can move
//                 quickly when you’re buying or selling
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-hand-holding-usd text-xl"></i>
//                 <h2 className="text-lg font-semibold">Control</h2>
//               </div>
//               <p className="text-center mt-2">
//                 You decide which company to invest in, when it’s time to buy,
//                 and when it’s time to sell
//               </p>
//             </span>
//           </div>
//         </div>

//         <div className="flex gap-6 items-center py-11 px-10">
//           <span className="w-1/2 mb-4 text-lg">
//             <h1 className="w-1/2 font-bold text-2xl mb-6">
//               WHY TRADE STOCKS WITH Convex integrated ?
//             </h1>
//             <p className="w-4/5 mb-5 ">
//               Our tools, info, and professional guidance mean you’ll never have
//               to face the markets on your own.
//             </p>
//             <ul className="list-none space-y-4">
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-chart-pie text-xl"></i>
//                 <span>Get market data and easy-to-read charts</span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-search text-xl"></i>
//                 <span>
//                   Use our stock screeners to find companies that fit into your
//                   portfolio
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-tachometer-alt text-xl"></i>
//                 <span>Trade quickly and easily with our stock ticker page</span>
//               </li>
//             </ul>
//           </span>

//           <span>
//             <h1 className="w-1/2 font-bold text-2xl mb-6">Fast facts</h1>
//             <ul className="list-none space-y-4">
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-shield-alt text-xl"></i>
//                 <span>
//                   Stop and conditional orders may help protect your portfolio
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-balance-scale text-xl"></i>
//                 <span>
//                   The price-to-earning (P/E) ratio can help you identify value
//                   stocks
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-chart-bar text-xl"></i>
//                 <span>
//                   Compare earnings-per-share (EPS) between similar companies
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-dollar-sign text-xl"></i>
//                 <span>
//                   Market capitalization (market cap) is the dollar value of a
//                   company
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-chart-line text-xl"></i>
//                 <span>
//                   Stock performance can fluctuate depending on market conditions
//                 </span>
//               </li>
//             </ul>
//           </span>
//         </div>

//         <div className="px-8">
//           <div className="flex  items-center py-2">
//             <hr className="w-1/2" />
//             <h1 className="text-center text-3xl font-semibold my-4">
//               TOP FIVE DIVIDEND YIELDING STOCKS
//             </h1>
//             <hr className="w-1/2" />
//           </div>
//           <p className="text-gray-500 text-lg mx-auto text-center pb-4">
//             Learn more about stocks
//           </p>

//           <div className="flex gap-4 items-center py-11 ">
//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">WHAT IS A DIVIDEND?</h2>
//                 <p className="text-lg">
//                   A dividend is a payment made by a corporation to its
//                   stockholders, usually out of its profits. Dividends are
//                   typically paid regularly (e.g., quarterly) and made as a fixed
//                   amount per share of stock.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">
//                   THE BASICS OF STOCK SELECTION
//                 </h2>
//                 <p className="text-lg">
//                   Selecting stocks for investing and trading should not be a
//                   guessing game in today's market. Join us as we review the
//                   basics of technical analysis and other stock selection
//                   techniques you should know before buying a stock.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">
//                   WHAT TO KNOW BEFORE YOU BUY STOCKS
//                 </h2>
//                 <p className="text-lg">
//                   Placing a stock trade is about a lot more than pushing a
//                   button and entering your order. This brief video can help you
//                   prepare before you open a position and develop a plan for
//                   managing it.
//                 </p>
//               </div>
//             </span>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';

// export default function Stock() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'PRODUCT'}
//         h1={'STOCKS'}
//         h1Blue={''}
//         backgroundImage={'../img/stock-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10 text-center sm:text-left">
//             OWN A PIECE OF A COMPANY'S FUTURE.
//           </h1>
//           <div className="flex flex-col sm:flex-row gap-6 items-center">
//             <p className="w-full sm:w-1/2 leading-10 text-xs sm:text-lg">
//               While stocks fluctuate, growth may help you keep ahead of
//               inflation. Potentially generate income with dividends. Flexibility
//               for long- and short-term investing strategies. Placing a stock
//               trade is about a lot more than pushing a button and entering your
//               order. This brief video can help you prepare before you open a
//               position and develop a plan for managing it.
//             </p>
//             <p className="w-full sm:w-1/2 leading-10 text-xs sm:text-lg">
//               Some stocks pay regular dividends—that’s income you can keep or
//               reinvest. Stocks let you own a piece of a company’s future.
//               They’re available for a wide variety of industries—so you can tap
//               into your knowledge of specific businesses or help you to
//               diversify your portfolio.
//             </p>
//           </div>
//         </div>

//         <div className="flex mx-auto w-full sm:w-1/2 py-10 flex-col gap-4 items-center">
//           <h1 className="font-bold pt-10 text-3xl text-center sm:text-left">
//             WHY TRADE STOCKS?
//           </h1>
//           <p className="text-gray-500 text-lg text-center sm:text-left">
//             Stocks let you own a piece of a company’s future. They’re available
//             for a wide variety of industries—so you can tap into your knowledge
//             of specific businesses, or help you to diversify your portfolio.
//           </p>
//         </div>

//         <div>
//           <hr className="w-4/5 mx-auto py-4" />
//           <div className="flex flex-col sm:flex-row gap-4 py-10 px-6 justify-around">
//             <span className="flex flex-col items-center p-4 text-center sm:text-left">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-chart-line text-xl"></i>
//                 <h2 className="text-lg font-semibold">Growth potential</h2>
//               </div>
//               <p className="text-center mt-2">
//                 While stock performance changes over time, successful stocks can
//                 help your money grow—at times, they can even outrun inflation.
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4 text-center sm:text-left">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-coins text-xl"></i>
//                 <h2 className="text-lg font-semibold">Income</h2>
//               </div>
//               <p className="text-center mt-2">
//                 Some stocks pay regular dividends—that’s income you can keep or
//                 reinvest.
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4 text-center sm:text-left">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-arrows-alt text-xl"></i>
//                 <h2 className="text-lg font-semibold">Flexibility</h2>
//               </div>
//               <p className="text-center mt-2">
//                 Since stocks trade by the millions every day, you can move
//                 quickly when you’re buying or selling.
//               </p>
//             </span>

//             <span className="flex flex-col items-center p-4 text-center sm:text-left">
//               <div className="flex items-center space-x-2">
//                 <i className="fas fa-arrows-alt text-xl"></i>
//                 <h2 className="text-lg font-semibold">Control</h2>
//               </div>
//               <p className="text-center mt-2">
//                 You decide which company to invest in, when it’s time to buy,
//                 and when it’s time to sell.
//               </p>
//             </span>
//           </div>
//         </div>

//         <div className="flex flex-col sm:flex-row gap-6 items-center py-11 px-10">
//           <span className="w-full sm:w-1/2 mb-4 text-lg">
//             <h1 className="font-bold text-2xl mb-6 text-center sm:text-left">
//               WHY TRADE STOCKS WITH Convex integrated?
//             </h1>
//             <p className="mb-5 text-center sm:text-left">
//               Our tools, info, and professional guidance mean you’ll never have
//               to face the markets on your own.
//             </p>
//             <ul className="list-none space-y-4">
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-chart-pie text-xl"></i>
//                 <span>Get market data and easy-to-read charts</span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-search text-xl"></i>
//                 <span>
//                   Use our stock screeners to find companies that fit into your
//                   portfolio
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-tachometer-alt text-xl"></i>
//                 <span>Trade quickly and easily with our stock ticker page</span>
//               </li>
//             </ul>
//           </span>

//           <span className="w-full sm:w-1/2">
//             <h1 className="font-bold text-2xl mb-6 text-center sm:text-left">
//               Fast facts
//             </h1>
//             <ul className="list-none space-y-4">
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-shield-alt text-xl"></i>
//                 <span>
//                   Stop and conditional orders may help protect your portfolio
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-balance-scale text-xl"></i>
//                 <span>
//                   The price-to-earning (P/E) ratio can help you identify value
//                   stocks
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-chart-bar text-xl"></i>
//                 <span>
//                   Compare earnings-per-share (EPS) between similar companies
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-dollar-sign text-xl"></i>
//                 <span>
//                   Market capitalization (market cap) is the dollar value of a
//                   company
//                 </span>
//               </li>
//               <li className="flex items-center space-x-2">
//                 <i className="fas fa-chart-line text-xl"></i>
//                 <span>
//                   Stock performance can fluctuate depending on market conditions
//                 </span>
//               </li>
//             </ul>
//           </span>
//         </div>

//         <div className="px-8">
//           <div className="flex items-center py-2">
//             <hr className="w-1/2" />
//             <h1 className="text-3xl font-semibold my-4">
//               TOP FIVE DIVIDEND YIELDING STOCKS
//             </h1>
//             <hr className="w-1/2" />
//           </div>
//           <p className="text-gray-500 text-lg mx-auto text-center pb-4">
//             Learn more about stocks
//           </p>

//           <div className="flex flex-col sm:flex-row gap-4 items-start py-11 ">
//             <span className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">WHAT IS A DIVIDEND?</h2>
//                 <p className="text-lg">
//                   A dividend is a payment made by a corporation to its
//                   stockholders, usually out of its profits. Dividends are
//                   typically paid regularly (e.g., quarterly) and made as a fixed
//                   amount per share of stock.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">
//                   THE BASICS OF STOCK SELECTION
//                 </h2>
//                 <p className="text-lg">
//                   Selecting stocks for investing and trading should not be a
//                   guessing game in today's market. Join us as we review the
//                   basics of technical analysis and other stock selection
//                   techniques you should know before buying a stock.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">
//                   WHAT TO KNOW BEFORE YOU BUY STOCKS
//                 </h2>
//                 <p className="text-lg">
//                   Placing a stock trade is about a lot more than pushing a
//                   button and entering your order. This brief video can help you
//                   prepare before you open a position and develop a plan for
//                   managing it.
//                 </p>
//               </div>
//             </span>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

import React from 'react';
import { Helmet } from 'react-helmet';
import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function Stock() {
  return (
    <div>
      <Helmet>
        <title>Home | stocks</title>
        {/* <meta
          name="viewport"
          content="width=1024"
        /> */}
      </Helmet>
      <NavBar />
      <BackgroundImageWithText
        p={'PRODUCT'}
        h1={'STOCKS'}
        h1Blue={''}
        backgroundImage={'../img/stock-bg.jpg'}
      />
      <main className="">
        <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
          <h1 className="font-bold text-3xl py-4 mb-4 pt-10 text-center sm:text-left">
            OWN A PIECE OF A COMPANY'S FUTURE.
          </h1>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <p className="w-full sm:w-1/2 leading-10 text-xs sm:text-lg">
              While stocks fluctuate, growth may help you keep ahead of
              inflation. Potentially generate income with dividends. Flexibility
              for long- and short-term investing strategies. Placing a stock
              trade is about a lot more than pushing a button and entering your
              order. This brief video can help you prepare before you open a
              position and develop a plan for managing it.
            </p>
            <p className="w-full sm:w-1/2 leading-10 text-xs sm:text-lg">
              Some stocks pay regular dividends—that’s income you can keep or
              reinvest. Stocks let you own a piece of a company’s future.
              They’re available for a wide variety of industries—so you can tap
              into your knowledge of specific businesses or help you to
              diversify your portfolio.
            </p>
          </div>
        </div>

        <div className="flex mx-auto w-full sm:w-1/2 py-10 flex-col gap-4 items-center">
          <h1 className="font-bold pt-10 text-3xl text-center sm:text-left">
            WHY TRADE STOCKS?
          </h1>
          <p className="text-gray-500 text-lg text-center sm:text-left">
            Stocks let you own a piece of a company’s future. They’re available
            for a wide variety of industries—so you can tap into your knowledge
            of specific businesses, or help you to diversify your portfolio.
          </p>
        </div>

        <div>
          <hr className="w-4/5 mx-auto py-4" />
          <div className="flex flex-col sm:flex-row gap-4 py-10 px-6 justify-around">
            <span className="flex flex-col items-center p-4 text-center sm:text-left">
              <div className="flex items-center space-x-2">
                <i className="fas fa-chart-line text-xl"></i>
                <h2 className="text-lg font-semibold">Growth potential</h2>
              </div>
              <p className="text-center mt-2">
                While stock performance changes over time, successful stocks can
                help your money grow—at times, they can even outrun inflation.
              </p>
            </span>

            <span className="flex flex-col items-center p-4 text-center sm:text-left">
              <div className="flex items-center space-x-2">
                <i className="fas fa-coins text-xl"></i>
                <h2 className="text-lg font-semibold">Income</h2>
              </div>
              <p className="text-center mt-2">
                Some stocks pay regular dividends—that’s income you can keep or
                reinvest.
              </p>
            </span>

            <span className="flex flex-col items-center p-4 text-center sm:text-left">
              <div className="flex items-center space-x-2">
                <i className="fas fa-arrows-alt text-xl"></i>
                <h2 className="text-lg font-semibold">Flexibility</h2>
              </div>
              <p className="text-center mt-2">
                Since stocks trade by the millions every day, you can move
                quickly when you’re buying or selling.
              </p>
            </span>

            <span className="flex flex-col items-center p-4 text-center sm:text-left">
              <div className="flex items-center space-x-2">
                <i className="fas fa-arrows-alt text-xl"></i>
                <h2 className="text-lg font-semibold">Control</h2>
              </div>
              <p className="text-center mt-2">
                You decide which company to invest in, when it’s time to buy,
                and when it’s time to sell.
              </p>
            </span>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-6 items-center py-11 px-10">
          <span className="w-full sm:w-1/2 mb-4 text-lg">
            <h1 className="font-bold text-2xl mb-6 uppercase text-center sm:text-left">
              WHY TRADE STOCKS WITH Convex integrated?
            </h1>
            <p className="mb-5 text-center sm:text-left">
              Our tools, info, and professional guidance mean you’ll never have
              to face the markets on your own.
            </p>
            <ul className="list-none space-y-4">
              <li className="flex items-center space-x-2">
                <i className="fas fa-chart-pie text-xl"></i>
                <span>Get market data and easy-to-read charts</span>
              </li>
              <li className="flex items-center space-x-2">
                <i className="fas fa-search text-xl"></i>
                <span>
                  Use our stock screeners to find companies that fit into your
                  portfolio
                </span>
              </li>
              <li className="flex items-center space-x-2">
                <i className="fas fa-tachometer-alt text-xl"></i>
                <span>Trade quickly and easily with our stock ticker page</span>
              </li>
            </ul>
          </span>

          <span className="w-full sm:w-1/2">
            <h1 className="font-bold text-2xl mb-6 text-center sm:text-left">
              Fast facts
            </h1>
            <ul className="list-none space-y-4">
              <li className="flex items-center space-x-2">
                <i className="fas fa-shield-alt text-xl"></i>
                <span>
                  Stop and conditional orders may help protect your portfolio
                </span>
              </li>
              <li className="flex items-center space-x-2">
                <i className="fas fa-balance-scale text-xl"></i>
                <span>
                  The price-to-earning (P/E) ratio can help you identify value
                  stocks
                </span>
              </li>
              <li className="flex items-center space-x-2">
                <i className="fas fa-chart-bar text-xl"></i>
                <span>
                  Compare earnings-per-share (EPS) between similar companies
                </span>
              </li>
              <li className="flex items-center space-x-2">
                <i className="fas fa-dollar-sign text-xl"></i>
                <span>
                  Market capitalization (market cap) is the dollar value of a
                  company
                </span>
              </li>
              <li className="flex items-center space-x-2">
                <i className="fas fa-chart-line text-xl"></i>
                <span>
                  Stock performance can fluctuate depending on market conditions
                </span>
              </li>
            </ul>
          </span>
        </div>

        <div className="px-8">
          <div className="flex items-center py-2">
            <hr className="w-1/2" />
            <h1 className="text-3xl font-semibold my-4">
              TOP FIVE DIVIDEND YIELDING STOCKS
            </h1>
            <hr className="w-1/2" />
          </div>
          <p className="text-gray-500 text-lg mx-auto text-center pb-4">
            Learn more about stocks
          </p>

          <div className="flex flex-col sm:flex-row gap-4 items-start py-11 ">
            <span className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
              <i className="fas fa-apple-alt text-xl"></i>
              <div className="py-8">
                <h2 className="text-lg font-semibold">WHAT IS A DIVIDEND?</h2>
                <p className="mt-2 text-sm">
                  Dividends are payments some companies make to shareholders to
                  reward them for investing in their stock.
                </p>
              </div>
            </span>
            <span className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
              <i className="fas fa-balance-scale text-xl"></i>
              <div className="py-8">
                <h2 className="text-lg font-semibold">
                  HOW TO RESEARCH STOCKS?
                </h2>
                <p className="mt-2 text-sm">
                  Analyzing and evaluating stocks involves using resources like
                  earnings reports, analyst ratings, and financial news.
                </p>
              </div>
            </span>
            <span className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
              <i className="fas fa-file-alt text-xl"></i>
              <div className="py-8">
                <h2 className="text-lg font-semibold">FINANCIAL STATEMENTS</h2>
                <p className="mt-2 text-sm">
                  Financial statements provide important insights into a
                  company’s profitability, stability, and growth potential.
                </p>
              </div>
            </span>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
