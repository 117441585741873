import React from 'react';
import { useEffect } from 'react';

const BackgroundImageWithText = ({ backgroundImage, h1, h1Blue, p }) => {
  useEffect(() => {
    const textElements = document.querySelectorAll('.animate-text');

    const handleScroll = () => {
      textElements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom > 0;

        if (isVisible) {
          el.classList.add('translate-x-0', 'opacity-100');
          el.classList.remove('-translate-x-10', 'opacity-0');
        } else {
          el.classList.remove('translate-x-0', 'opacity-100');
          el.classList.add('-translate-x-10', 'opacity-0');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Run on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className="relative bg-cover bg-center h-60 sm:h-96 w-full"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="absolute inset-0 flex flex-col gap-2 items-start px-6 justify-center">
        <p className="text-slate-50 text-sm animate-text transition-transform duration-500 transform -translate-x-10 opacity-0">
          {p}
        </p>
        <h1 className="text-white text-3xl font-bold animate-text transition-transform duration-500 transform -translate-x-10 opacity-0">
          {h1}
        </h1>
        <h1 className="text-blue-600 text-3xl font-bold animate-text transition-transform duration-500 transform -translate-x-10 opacity-0">
          {h1Blue}
        </h1>
      </div>
    </div>
  );
};

export default BackgroundImageWithText;
