import Logo from './logocomp';

export default function UserAuthComp({ children, type, handler }) {
  return (
    <div className="bg-slate-900 h-screen w-screen fixed flex justify-center items-center">
      <form
        onSubmit={(e) => handler(e)}
        className="bg-gradient-to-tr md:w-1/2 sm:w-4/5 h-auto from-black bg-stone-600 p-8 rounded-lg shadow-lg">
        <div className="flex items-center  gap-8 text-slate-50 mb-8">
          <Logo />
          <p className="text-3xl"> {type}</p>
        </div>

        {children}
      </form>
    </div>
  );
}
