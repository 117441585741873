// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function Agric() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'INVESTMENT PRODUCTS'}
//         h1={'AGRICULTURE'}
//         h1Blue={''}
//         backgroundImage={'../img/agric-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">AGRICULTURE</h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               Coin Brokage and its agriculture division,manage its agriculture
//               investments in a manner that integrates the development,
//               management,
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               and operation of agricultural lands for useful products with a
//               commitment to conserve soil, air, and water quality; biological
//               diversity; wildlife habitats; and participation in vibrant,
//               healthy communities.
//             </p>
//           </span>
//         </div>

//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//         <div className="flex items-start gap-8 text-lg  px-10 py-8">
//           <div className="w-1/2 flex flex-col gap-8 ">
//             <p>
//               Convex Integrated uses practical metrics to track, improve, and
//               report sustainability performance of its agriculture investments.
//               Matrix Ace practices with regard to ESG are embodied within the
//               principles, policies, and best practices/procedures associated
//               with the Matrix Ace’s Stewardship Program. Matrix Ace’s
//               stewardship principles provide higher level philosophical guidance
//               on matters relating to ESG across our agricultural investments.
//               Stewardship policies align with the principles and are intended to
//               add value to client investments and Matrix Ace’s business by
//               decreasing operational risk and creating efficiencies. At local
//               and regional levels, stewardship best practices and procedures
//               provide specific guidelines that facilitate implementation of
//               Matrix Ace’s Stewardship
//             </p>
//             <span className="flex-col gap-4 flex ">
//               <h1 className="font-bold text-gray-500 text-xl mb-4">
//                 SUSTAINABLE AGRICULTURE
//               </h1>
//               <p>
//                 Our clients’ assets are located across 336,000 acres of prime
//                 farmland in major agricultural regions including California, the
//                 Midwest, the Mississippi Delta, the Pacific Northwest, the
//                 Southern Plains, and Southeast United States; Queensland,
//                 Victoria, South Australia and New South Wales Australia; and
//                 Quebec and Alberta, Canada.
//               </p>
//               <p>
//                 We offer individually managed separate accounts that are fully
//                 customized to meet specific client investment objectives.
//                 Agricultural investments seek to provide institutional investors
//                 with a number of benefits including:
//               </p>
//               <ul className="list-none space-y-4">
//                 <li className="flex items-center space-x-2">
//                   <i className="fas fa-chart-pie text-xl"></i>
//                   <span>Attractive returns</span>
//                 </li>
//                 <li className="flex items-center space-x-2">
//                   <i className="fas fa-piggy-bank text-xl"></i>
//                   <span>Excellent capital preservation</span>
//                 </li>
//                 <li className="flex items-center space-x-2">
//                   <i className="fas fa-briefcase text-xl"></i>
//                   <span>Portfolio diversification</span>
//                 </li>
//                 <li className="flex items-center space-x-2">
//                   <i className="fas fa-sliders-h text-xl"></i>
//                   <span>Low to moderate risk</span>
//                 </li>
//                 <li className="flex items-center space-x-2">
//                   <i className="fas fa-random text-xl"></i>
//                   <span>
//                     Low correlation to traditional assets such as equities and
//                     bonds
//                   </span>
//                 </li>
//               </ul>

//               <p>
//                 This site provides an introduction to farmland as an investment
//                 opportunity and describes the investment capabilities of HAIG.
//                 Please contact us if you have questions about farmland
//                 investment fundamentals or if you would like to know more about
//                 how we can value add your portfolio.
//               </p>
//             </span>
//           </div>
//           <div className="w-1/2 h-1/2">
//             <img
//               src="../img/agric-1.jpg"
//               alt="agric"
//             />
//           </div>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function Agric() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'INVESTMENT PRODUCTS'}
        h1={'AGRICULTURE'}
        h1Blue={''}
        backgroundImage={'../img/agric-bg.jpg'}
      />
      <main className="">
        <div className="bg-gray-200 px-6 py-10">
          <h1 className="font-bold text-2xl md:text-3xl py-4 mb-4">
            AGRICULTURE
          </h1>
          <div className="flex flex-col md:flex-row items-center gap-6 pb-10">
            <p className="w-full md:w-1/2 leading-7 text-sm md:text-lg">
              Convex Integrated and its agriculture division manage its
              agriculture investments in a manner that integrates the
              development, management,
            </p>
            <p className="w-full md:w-1/2 leading-7 text-sm md:text-lg">
              and operation of agricultural lands for useful products with a
              commitment to conserve soil, air, and water quality; biological
              diversity; wildlife habitats; and participation in vibrant,
              healthy communities.
            </p>
          </div>
        </div>

        <hr className="mx-auto w-4/5 mt-10 pb-4" />
        <div className="flex flex-col md:flex-row items-start gap-8 text-sm md:text-lg px-6 md:px-10 py-8">
          <div className="w-full md:w-1/2 flex flex-col gap-8">
            <p>
              Convex Integrated uses practical metrics to track, improve, and
              report sustainability performance of its agriculture investments.
              Convex Integrated practices with regard to ESG are embodied within
              the principles, policies, and best practices/procedures associated
              with the Convex Integrated's Stewardship Program. Convex
              Integrated's stewardship principles provide higher level
              philosophical guidance on matters relating to ESG across our
              agricultural investments. Stewardship policies align with the
              principles and are intended to add value to client investments and
              Convex Integrated's business by decreasing operational risk and
              creating efficiencies. At local and regional levels, stewardship
              best practices and procedures provide specific guidelines that
              facilitate implementation of Convex Integrated's Stewardship
            </p>
            <div className="flex flex-col gap-4">
              <h2 className="font-bold text-gray-500 text-lg md:text-xl mb-4">
                SUSTAINABLE AGRICULTURE
              </h2>
              <p>
                Our clients’ assets are located across 336,000 acres of prime
                farmland in major agricultural regions including California, the
                Midwest, the Mississippi Delta, the Pacific Northwest, the
                Southern Plains, and Southeast United States; Queensland,
                Victoria, South Australia and New South Wales Australia; and
                Quebec and Alberta, Canada.
              </p>
              <p>
                We offer individually managed separate accounts that are fully
                customized to meet specific client investment objectives.
                Agricultural investments seek to provide institutional investors
                with a number of benefits including:
              </p>
              <ul className="list-none space-y-4">
                <li className="flex items-center space-x-2">
                  <i className="fas fa-chart-pie text-xl"></i>
                  <span>Attractive returns</span>
                </li>
                <li className="flex items-center space-x-2">
                  <i className="fas fa-piggy-bank text-xl"></i>
                  <span>Excellent capital preservation</span>
                </li>
                <li className="flex items-center space-x-2">
                  <i className="fas fa-briefcase text-xl"></i>
                  <span>Portfolio diversification</span>
                </li>
                <li className="flex items-center space-x-2">
                  <i className="fas fa-sliders-h text-xl"></i>
                  <span>Low to moderate risk</span>
                </li>
                <li className="flex items-center space-x-2">
                  <i className="fas fa-random text-xl"></i>
                  <span>
                    Low correlation to traditional assets such as equities and
                    bonds
                  </span>
                </li>
              </ul>

              <p>
                This site provides an introduction to farmland as an investment
                opportunity and describes the investment capabilities of HAIG.
                Please contact us if you have questions about farmland
                investment fundamentals or if you would like to know more about
                how we can value add your portfolio.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="../img/agric-1.jpg"
              alt="agric"
              className="w-full h-auto"
            />
          </div>
        </div>
        <hr className="mx-auto w-4/5 mt-10 pb-4" />
      </main>
      <Footer />
    </div>
  );
}
