import React, { useState, useEffect } from 'react';

export default function HeroSection() {
  const [currentSection, setCurrentSection] = useState('Stocks');

  const sections = {
    Stocks: {
      backgroundImage: '../img/pc.png',
      heading: 'FULLY INVESTED IN BETTER OUTCOMES',
      text: 'As a global investment firm, we foster diverse perspectives and embrace innovation to help our clients navigate the uncertainty of capital markets.',
      linkText: 'Get Started',
    },
    Infrastructure: {
      backgroundImage: '../img/infrastructure.jpg',
      heading: 'BUILDING THE FUTURE',
      text: 'Invest in sustainable and innovative infrastructure projects that drive growth and improve quality of life.',
      linkText: 'Explore More',
    },
    Services: {
      backgroundImage: '../img/stock-bg.jpg',
      heading: 'EXCELLENCE IN SERVICE',
      text: 'Providing top-notch services to ensure your investments are well-managed and yield the best returns.',
      linkText: 'Learn More',
    },
  };

  // Automatically change sections every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSection((prev) => {
        const sectionKeys = Object.keys(sections);
        const currentIndex = sectionKeys.indexOf(prev);
        const nextIndex = (currentIndex + 1) % sectionKeys.length;
        return sectionKeys[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const { backgroundImage, heading, text, linkText } = sections[currentSection];

  return (
    <div
      className="w-screen overflow-hidden relative py-11 px-6 sm:min-h-[60vh] min-h-[35vh] bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      {/* Overlay to ensure text visibility */}
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
        <div className="text-center flex-col h-auto box-border items-center justify-center text-slate-300 px-4">
          <h1 className="text-2xl py-2 md:text-4xl font-bold mb-2">
            {heading.split(' ').slice(0, -1).join(' ')}{' '}
            <span className="text-blue-500 py-2 underline">
              {heading.split(' ').slice(-1)}
            </span>
          </h1>
          <p className="text-sm md:text-xl mb-4">{text}</p>
          <a
            href="/login"
            className="block px-4 py-2 z-40 w-60 mx-auto bg-blue-600 text-slate-300">
            {linkText}
          </a>
        </div>
        {/* Div with border placed at the bottom */}
        <div className="absolute bottom-0 w-full bg-opacity-20 bg-gray-900 py-4">
          <div className="flex justify-around border-t border-gray-300 pt-2 text-sm text-slate-300">
            {Object.keys(sections).map((section) => (
              <span
                key={section}
                onClick={() => setCurrentSection(section)}
                className={`cursor-pointer ${
                  currentSection === section ? 'text-blue-500' : ''
                }`}>
                {section}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
