// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function Crypto() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'PRODUCTS'}
//         h1={'CRYPTO'}
//         h1Blue={'ASSET'}
//         backgroundImage={'../img/forex-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">
//             TRADE CRYPTOCURRENCY CFDS WITH COIN BROKAGE
//           </h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               What is a cryptocurrency? A cryptocurrency is a digital asset
//               conceived for use as a medium of exchange, which uses cryptography
//               to secure transactions, control the supply of additional units and
//               corroborate transfers. In short, cryptocurrency is a decentralised
//               electronic currency. Why trade cryptocurrencies? Cryptocurrencies
//               have the tendency to be
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               particularly volatile, so they provide various opportunities for
//               traders to open positions with big movements. Leveraged trading
//               provides high liquidity, matched with the reputation
//               cryptocurrencies have for being highly volatile, means that
//               trading cryptocurrencies with Capital.com provides greater
//               opportunities in markets.
//             </p>
//           </span>
//         </div>
//         <h1 className="font-bold font-sans w-1/2 mx-auto py-4 mt-6 text-2xl text-center">
//           WHAT CRYPTOCURRENCIES CAN BE TRADED WITH CONVEX INTEGRATED
//         </h1>

//         <div className="flex gap-11 py-11 px-6">
//           <span className="w-1/2 flex flex-col gap-4 text-lg">
//             <p>
//               Matrix Ace offers a variety of cryptocurrencies that can be traded
//               in relation to many different currencies. We offer pairings with
//               the following cryptocurrencies: Bitcoin, Litecoin, Ripple, TRON,
//               Ethereum and many others. Study cryptocurrency price charts with
//               Matrix Ace
//             </p>
//             <p>
//               Investing vs. trading cryptocurrency There are two options when
//               trading in the cryptocurrency market. You can buy actual
//               cryptocurrency on exchanges, where you own the underlying asset.
//               This is considered a long-term investment, as you are waiting for
//               the price to rise significantly before selling.
//             </p>
//           </span>

//           <span className="w-1/2 flex flex-col gap-8 text-lg">
//             <p>
//               Forex CFDs Trade 24/5 on a wide range of Forex. Buy and sell Forex
//               to take advantage of rises and falls in price
//             </p>
//             <p>
//               Profit and loss control You can easily define Stops and Limits to
//               request positions to close at a specified price. Set up price
//               alerts to be informed of big moves.
//             </p>
//           </span>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//         <div className="flex gap-11 py-11 px-6">
//           <span className="w-1/2 flex flex-col gap-4 text-lg">
//             <p>
//               Alternatively, you can trade cryptocurrency CFDs. A CFD is a
//               popular type of derivative that allows you to trade on margin,
//               providing you with greater exposure to the financial markets. CFDs
//               are a type of derivative so you do not buy the underlying asset
//               itself. Instead, you buy or sell units for a given financial
//               instrument depending on whether you think the underlying price
//               will rise or fall.
//             </p>
//             <p>
//               When buying cryptocurrency, it is stored in a wallet, but when
//               trading CFDs the position is held in your trading account, which
//               is regulated by a financial authority.
//             </p>
//           </span>

//           <span className="w-1/2 flex flex-col gap-8 text-lg">
//             <span>
//               <h1 className="font-bold text-xl mb-4">
//                 SEE LIVE CRYPTOCURRENCY PRICES HERE.
//               </h1>
//               <p>
//                 Trade cryptocurrencies with leverage Cryptos are available to
//                 trade with up to 2:1 leverage. Start trading with as little as
//                 $100 to control a position of $200
//               </p>
//             </span>
//             <span>
//               <h1 className="font-bold text-xl mb-4">Crypto CFD’s</h1>
//               <p>
//                 Trade 24/7 on a wide range of cryptos Buy and sell cryptos to
//                 take advantage of rises and falls in price No need to setup
//                 crypto wallets and private keys Profit and loss control You can
//                 easily define Stops and Limits to request positions to close at
//                 a specified price. Set up price alerts to be informed of big
//                 moves.
//               </p>
//             </span>
//           </span>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function Crypto() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'PRODUCTS'}
        h1={'CRYPTO'}
        h1Blue={'ASSET'}
        backgroundImage={'../img/forex-bg.jpg'}
      />
      <main className="text-sm">
        <div className="bg-gray-200 sm:px-6 px-4 py-10">
          <h1 className="font-bold text-lg sm:text-3xl py-4 mb-4">
            TRADE CRYPTOCURRENCY CFDS WITH CONVEX INTEGRATED
          </h1>
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6">
            <p className="w-full sm:w-1/2 leading-7 text-sm sm:text-lg">
              What is a cryptocurrency? A cryptocurrency is a digital asset
              conceived for use as a medium of exchange, which uses cryptography
              to secure transactions, control the supply of additional units and
              corroborate transfers. In short, cryptocurrency is a decentralised
              electronic currency. Why trade cryptocurrencies? Cryptocurrencies
              have the tendency to be particularly volatile, so they provide
              various opportunities for traders to open positions with big
              movements. Leveraged trading provides high liquidity, matched with
              the reputation cryptocurrencies have for being highly volatile,
              means that trading cryptocurrencies with Capital.com provides
              greater opportunities in markets.
            </p>
            <p className="w-full sm:w-1/2 leading-7 text-sm sm:text-lg">
              Convex Integrated offers a variety of cryptocurrencies that can be
              traded in relation to many different currencies. We offer pairings
              with the following cryptocurrencies: Bitcoin, Litecoin, Ripple,
              TRON, Ethereum and many others. Study cryptocurrency price charts
              with Convex Integrated. Investing vs. trading cryptocurrency There
              are two options when trading in the cryptocurrency market. You can
              buy actual cryptocurrency on exchanges, where you own the
              underlying asset. This is considered a long-term investment, as
              you are waiting for the price to rise significantly before
              selling.
            </p>
          </div>
        </div>
        <h1 className="font-bold font-sans text-lg sm:text-2xl text-center my-6">
          WHAT CRYPTOCURRENCIES CAN BE TRADED WITH CONVEX INTEGRATED
        </h1>
        <div className="flex px-6 flex-col sm:flex-row gap-6 py-6">
          <div className="w-full sm:w-1/2 flex flex-col gap-4">
            <p className="sm:text-lg">
              Alternatively, you can trade cryptocurrency CFDs. A CFD is a
              popular type of derivative that allows you to trade on margin,
              providing you with greater exposure to the financial markets. CFDs
              are a type of derivative so you do not buy the underlying asset
              itself. Instead, you buy or sell units for a given financial
              instrument depending on whether you think the underlying price
              will rise or fall. When buying cryptocurrency, it is stored in a
              wallet, but when trading CFDs the position is held in your trading
              account, which is regulated by a financial authority.
            </p>
            <p className="text-lg">
              Trade cryptocurrencies with leverage Cryptos are available to
              trade with up to 2:1 leverage. Start trading with as little as
              $100 to control a position of $200.
            </p>
          </div>
          <div className="w-full sm:w-1/2 flex flex-col gap-4">
            <h1 className="font-bold text-lg sm:text-xl mb-2">
              SEE LIVE CRYPTOCURRENCY PRICES HERE.
            </h1>
            <p className="sm;text-lg">
              Forex CFDs Trade 24/5 on a wide range of Forex. Buy and sell Forex
              to take advantage of rises and falls in price. Profit and loss
              control You can easily define Stops and Limits to request
              positions to close at a specified price. Set up price alerts to be
              informed of big moves.
            </p>
            <h1 className="font-bold text-lg sm:text-xl mt-4">Crypto CFD’s</h1>
            <p className="sm:text-lg">
              Trade 24/7 on a wide range of cryptos. Buy and sell cryptos to
              take advantage of rises and falls in price. No need to setup
              crypto wallets and private keys. Profit and loss control You can
              easily define Stops and Limits to request positions to close at a
              specified price. Set up price alerts to be informed of big moves.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
