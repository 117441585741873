import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function BusinessPlan() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'BUSINESS'}
        h1Blue={'SERVICE'}
        backgroundImage={'../img/bs-bg.jpg'}
      />
      <main>
        <div className="bg-gray-200 text-lg px-11 py-11">
          {/* <h1 className="text-gray-500 mb-4 font-bold text-3xl">
            What is long-term care?
          </h1> */}

          <p className="pb-11">
            From initial startup to eventually passing your business on, we're
            here to help you at every stage. From protecting against risk and
            managing growth, to attracting and retaining the top talent, our
            business services will be there to guide you.
          </p>
        </div>

        {/* <div className="py-11 my-11">
          <h1 className="text-center font-bold uppercase text-3xl my-4">
            OUR SERVICES, TAILORED TO YOU
          </h1>
          <hr className="w-1/2 mx-auto" />
          <div className="flex gap-6 items-center justify-center">
            <div class="text-gray-900 text-lg my-4">
              <ul class="list-disc ml-6">
                <li>Financial Planning</li>
                <li>Investment Planning & Strategies</li>
                <li>Advanced Portfolio Management</li>
                <li>Retirement Planning & Income Strategies</li>
                <li>Estate Planning & Trust Services</li>
                <li>Risk Management</li>
              </ul>
            </div>
            <div class="text-gray-900 text-lg">
              <ul class="list-disc ml-6">
                <li>Tax Efficient Strategies</li>
                <li>Advisor Coordination</li>
                <li>Business Planning & Succession</li>
                <li>Multigenerational Planning</li>
                <li>Charitable Planning</li>
                <li>Education Funding</li>
              </ul>
            </div>
          </div>
        </div> */}
      </main>
      <hr />
      <Footer />
    </div>
  );
}
