import React, { useState } from 'react';
import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

const ContactPage = () => {
  const [form, setForm] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Replace this with your form submission logic
    const isValid =
      form.name && form.lastName && form.email && form.phone && form.message;
    if (isValid) {
      setStatus('success');
      // You can also add API call to submit the form here
    } else {
      setStatus('error');
    }
  };

  return (
    <>
      {' '}
      <NavBar />
      <BackgroundImageWithText
        p={'CONTACT US'}
        h1={'REACH OUT TO US'}
        h1Blue={''}
        backgroundImage={'../img/contact.jpg'}
      />
      <div className="container mx-auto p-4">
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Contact Details</h2>
          <p className="font-bold mb-4">Location: </p>
          <span className="flex flex-col gap-6">
            <span>
              6th Floor Point House 6 Front Street Hamilton, HM 11.Bermuda 52
            </span>
            <span> Lime Street, London, EC3M 7AF, UK Bucktrout House.</span>
            <span>
              Glategny Esplanade, Guernsey GY1 1WR, Guernsey 37 Boulevard Joseph
              II, 2ème étage,
            </span>
            <span> L-1840 Luxembourg, Grand-Duchy of Luxembourg.</span>
          </span>
          <p className="mt-4">
            Email:{' '}
            <a
              href="mailto:support@matrixace.org"
              className="text-blue-500 hover:underline">
              support@convexintegrated.com
            </a>
          </p>
          <p>
            Phone:{' '}
            <a
              href="#"
              className="text-blue-500 hover:underline">
              VIP Only
            </a>
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Contact Form</h2>
          <p>
            Fill the form below and we’ll get back to you as soon as possible,
            usually less than 24 hours.
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={form.name}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="block mb-1">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={form.lastName}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block mb-1">
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={form.phone}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block mb-1">
              Type your message here...
            </label>
            <textarea
              id="message"
              name="message"
              value={form.message}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2">
            Submit
          </button>
        </form>

        {status === 'success' && (
          <p className="text-green-500 mt-4">
            Thank you! Your submission has been received!
          </p>
        )}
        {status === 'error' && (
          <p className="text-red-500 mt-4">
            Oops! Something went wrong while submitting the form.
          </p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
