// src/components/ChatWidget.jsx

import React from 'react';
import { FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';

const ChatWidget = () => {
  return (
    <div className="fixed bottom-4 right-90 flex flex-col space-y-2">
      <a
        href="https://wa.me/+447529446569"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-500 p-3 rounded-full text-white hover:bg-green-600 transition">
        <FaWhatsapp size={24} />
      </a>
      <a
        href="https://t.me/+447529287920
"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 p-3 rounded-full text-white hover:bg-blue-600 transition">
        <FaTelegramPlane size={24} />
      </a>
    </div>
  );
};

export default ChatWidget;
