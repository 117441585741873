import React, { useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';
import Notify from './toastify.comp';

import notifySuccessMsg from '../utils/notify.succes';

const WithdrawalRequest = () => {
  const [amount, setAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const token = Cookies.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const amountInNum = Number(amount);

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const requestBody = {
        amount: amountInNum,
        wallet: walletAddress,
      };

      const response = await axios.post(
        `${BASEURLDEV}/account/withdraw`,
        requestBody,
        {
          headers,
        }
      );
      setSuccess(true);
      notifySuccessMsg(response.data.message);
    } catch (error) {
      console.error('Error requesting withdrawal:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccess = () => {
    setSuccess(false);
    setAmount('');
    setWalletAddress('');
  };

  return (
    <div className="mt-8 bg-gray-900 text-white rounded p-6">
      <h2 className="text-lg font-semibold mb-4">Request Withdrawal</h2>
      {success ? (
        <div className="p-4 bg-green-500 text-white rounded">
          <h3 className="text-lg font-semibold">Congratulations!</h3>
          <p>Your withdrawal request was successful.</p>
          <button
            onClick={handleCloseSuccess}
            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Close
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="amount"
              className="block font-medium text-gray-300">
              Amount
            </label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="walletAddress"
              className="block font-medium text-gray-300">
              Wallet Address
            </label>
            <input
              type="text"
              id="walletAddress"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
              required
            />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Withdrawal Request'}
          </button>
        </form>
      )}

      <Notify />
    </div>
  );
};

export default WithdrawalRequest;
