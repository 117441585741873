import Footer from './footer.comp';
import HeroSection from './herosection';
import NavBar from './homenav';
import MainSection from './mainsection';

export default function HomeComp() {
  return (
    <div className="overflow-hidden">
      <NavBar />
      <HeroSection />
      <MainSection />
      <Footer />
    </div>
  );
}
