// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function RealEstate() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'INVESTMENT PRODUCTS'}
//         h1={'REAL'}
//         h1Blue={'ESTATE'}
//         backgroundImage={'../img/estate-bg-2.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">REAL ESTATE</h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               As one of the world's largest investors in real estate, we own and
//               operate iconic properties in the world's most dynamic markets. Our
//               global portfolio includes office, retail, multifamily, logistics,
//               hospitality, triple net lease, manufactured housing and student
//               housing assets on five continents. Our goal is to generate stable
//               and growing distributions for our investors while protecting them
//               against downside risk. Our assets are diversified by sector
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               and geography, reducing exposure to movement in any single market
//               and minimizing volatility. In our public and private investment
//               vehicles, we seek to generate superior returns by leveraging our
//               operating expertise and focusing on our core real estate
//               capabilities—leasing, financing, development, design and
//               construction, and property and facilities management.
//             </p>
//           </span>
//         </div>
//         <div className="py-11 px-6">
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-center justify-center">
//               <p>
//                 Manhattan West is one of Coin Brokage’s most ambitious
//                 large-scale development projects. The eight-acre, six-building,
//                 mixed-use complex includes all the elements of Coin Brokage’s
//                 renowned placemaking destinations throughout the world.
//               </p>
//               <div>
//                 <h2 className="font-bold text-xl mb-4 text-gray-500 mt-10">
//                   REAL ESTATE LEADERSHIP
//                 </h2>
//                 <p>
//                   Our leaders share a commitment to our time-tested approach to
//                   investing, hands-on value creation and practices that have a
//                   positive impact on our communities.
//                 </p>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/estate-1.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             Office
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 We own, develop and manage office properties in key gateway
//                 cities in the U.S., Canada, the U.K., Germany, Australia,
//                 Brazil, India and South Korea
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     318
//                   </h1>
//                   <p className="text-gray-600">PROPERTIES</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     167M SF
//                   </h1>
//                   <p className="text-gray-600">COMMERCIAL SPACE</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/estate-2.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             retail
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our high-quality retail destinations are central gathering
//                 places for the communities they serve, combining shopping,
//                 dining, entertainment and other activities.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     179
//                   </h1>
//                   <p className="text-gray-600">
//                     LOCATIONS, PREDOMINANTLY IN THE U.S AND UK
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     500 million
//                   </h1>
//                   <p className="text-gray-600">TOTAL SQUARE FEET</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/estate-3.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             multifamily
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our extensive multifamily portfolio makes us one of the largest
//                 owners and managers of residential apartment properties in the
//                 U.S. with ownership stakes in approximately 58,000 apartments.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     156
//                   </h1>
//                   <p className="text-gray-600 uppercase">properties</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     51,595
//                   </h1>
//                   <p className="text-gray-600">APARTMENTS</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/estate-4.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             multifamily
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 We own and actively manage full-service hotels and leisure-style
//                 hospitality assets in high-barrier markets across North America,
//                 the U.K. and Australia.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     16
//                   </h1>
//                   <p className="text-gray-600 uppercase">
//                     FULL-SERVICE HOSPITALITY PROPERTIES
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     140
//                   </h1>
//                   <p className="text-gray-600 uppercase">
//                     EXTENDED-STAY HOTELS
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     3000
//                   </h1>
//                   <p className="text-gray-600 uppercase">room</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/estate-5.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             other
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 We drive value in high-quality assets in other sectors such as
//                 logistics, triple net lease, manufactured housing and student
//                 housing by focusing on leasing, financing, development,
//                 construction and facilities management.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     159
//                   </h1>
//                   <p className="text-gray-600 uppercase">
//                     STUDENT HOUSING PROPERTIES
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     306
//                   </h1>
//                   <p className="text-gray-600 uppercase">
//                     TRIPLE NET LEASE ASSETS
//                   </p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/estate-6.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function RealEstate() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'INVESTMENT PRODUCTS'}
        h1={'REAL'}
        h1Blue={'ESTATE'}
        backgroundImage={'../img/estate-bg-2.jpg'}
      />
      <main className="bg-gray-200 px-6 py-10 pb-10 pt-10">
        <div className="max-w-7xl mx-auto">
          <h1 className="font-bold text-3xl sm:text-4xl lg:text-5xl py-4 mb-4 pt-10">
            REAL ESTATE
          </h1>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <p className="w-full sm:w-1/2 leading-7 sm:text-lg">
              As one of the world's largest investors in real estate, we own and
              operate iconic properties in the world's most dynamic markets. Our
              global portfolio includes office, retail, multifamily, logistics,
              hospitality, triple net lease, manufactured housing and student
              housing assets on five continents. Our goal is to generate stable
              and growing distributions for our investors while protecting them
              against downside risk. Our assets are diversified by sector and
              geography, reducing exposure to movement in any single market and
              minimizing volatility. In our public and private investment
              vehicles, we seek to generate superior returns by leveraging our
              operating expertise and focusing on our core real estate
              capabilities—leasing, financing, development, design and
              construction, and property and facilities management.
            </p>
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/estate-1.jpg"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <hr className="my-8 mx-auto max-w-7xl" />

        <div className="max-w-7xl mx-auto">
          <h2 className="font-bold uppercase text-xl sm:text-2xl mb-4 text-gray-500 mt-10">
            Office
          </h2>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/estate-2.png"
                alt="estate-2"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <p className="leading-7 sm:text-lg">
                We own, develop and manage office properties in key gateway
                cities in the U.S., Canada, the U.K., Germany, Australia,
                Brazil, India and South Korea.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    318
                  </h1>
                  <p className="text-gray-600">PROPERTIES</p>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    167M SF
                  </h1>
                  <p className="text-gray-600">COMMERCIAL SPACE</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-8 mx-auto max-w-7xl" />

        <div className="max-w-7xl mx-auto">
          <h2 className="font-bold uppercase text-xl sm:text-2xl mb-4 text-gray-500 mt-10">
            Retail
          </h2>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/estate-3.jpg"
                alt="estate-3"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <p className="leading-7 sm:text-lg">
                Our high-quality retail destinations are central gathering
                places for the communities they serve, combining shopping,
                dining, entertainment and other activities.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    179
                  </h1>
                  <p className="text-gray-600">
                    LOCATIONS, PREDOMINANTLY IN THE U.S AND UK
                  </p>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    500 million
                  </h1>
                  <p className="text-gray-600">TOTAL SQUARE FEET</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-8 mx-auto max-w-7xl" />

        <div className="max-w-7xl mx-auto">
          <h2 className="font-bold uppercase text-xl sm:text-2xl mb-4 text-gray-500 mt-10">
            Multifamily
          </h2>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/estate-4.png"
                alt="estate-4"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <p className="leading-7 sm:text-lg">
                Our extensive multifamily portfolio makes us one of the largest
                owners and managers of residential apartment properties in the
                U.S. with ownership stakes in approximately 58,000 apartments.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    156
                  </h1>
                  <p className="text-gray-600 uppercase">PROPERTIES</p>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    51,595
                  </h1>
                  <p className="text-gray-600">APARTMENTS</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-8 mx-auto max-w-7xl" />

        <div className="max-w-7xl mx-auto">
          <h2 className="font-bold uppercase text-xl sm:text-2xl mb-4 text-gray-500 mt-10">
            Hospitality
          </h2>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/estate-5.jpg"
                alt="estate-5"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <p className="leading-7 sm:text-lg">
                We own and actively manage full-service hotels and leisure-style
                hospitality assets in high-barrier markets across North America,
                the U.K. and Australia.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    16
                  </h1>
                  <p className="text-gray-600 uppercase">
                    FULL-SERVICE HOSPITALITY PROPERTIES
                  </p>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    140
                  </h1>
                  <p className="text-gray-600 uppercase">
                    EXTENDED-STAY HOTELS
                  </p>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    3000
                  </h1>
                  <p className="text-gray-600 uppercase">ROOMS</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-8 mx-auto max-w-7xl" />

        <div className="max-w-7xl mx-auto">
          <h2 className="font-bold uppercase text-xl sm:text-2xl mb-4 text-gray-500 mt-10">
            Other
          </h2>
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/estate-6.jpg"
                alt="estate-6"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <p className="leading-7 sm:text-lg">
                We drive value in high-quality assets in other sectors such as
                logistics, triple net lease, manufactured housing and student
                housing by focusing on leasing, financing, development,
                construction and facilities management.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    159
                  </h1>
                  <p className="text-gray-600 uppercase">
                    STUDENT HOUSING PROPERTIES
                  </p>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    306
                  </h1>
                  <p className="text-gray-600 uppercase">
                    TRIPLE NET LEASE ASSETS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
