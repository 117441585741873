// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function Forex() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'PRODUCTS'}
//         h1={'FOREX'}
//         h1Blue={'TRADING'}
//         backgroundImage={'../img/forex-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">
//             TRADE FOREX CFDS WITH Convex Integrated
//           </h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               The FX market is one of the world’s biggest markets. You can trade
//               currency pairs from every corner of the world. However, there are
//               a handful of pairs that are worth mentioning: more traded than any
//               other currency pairs, the ‘majors’ dominate the FX market. The
//               four most traded currency pairs in the world have been coined the
//               ‘majors’. They involve the following currencies: euro, US dollar,
//               Japanese yen and pound sterling. The most
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               popular pairing is the EUR/USD, followed by the USD/JPY, GBP/USD
//               and USD/CHF pairs respectively. There are a few other currencies
//               that deserve to be mentioned. Informally known as the ‘commodity
//               pairs’, the AUD/USD or ‘Aussie’, USD/CAD and NZD/USD are all
//               frequently traded currency pairs. Unsurprisingly, this group of
//               currencies derives its nickname from the fact that they come from
//               countries that possess large quantities of natural resources.
//             </p>
//           </span>
//         </div>
//         <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
//           ADVANTAGES OF TRADING CURRENCY PAIRS
//         </h1>

//         <div className="flex gap-11 py-11 px-6">
//           <span className="w-1/2 flex flex-col gap-4 text-lg">
//             <p>
//               The FX market is one of the most exciting and fast-paced for
//               traders. Open 24/7 and being the biggest global market means it is
//               extremely liquid; you can instantly buy and sell, so you’re never
//               stuck in a trade. Daily currency fluctuations are usually fairly
//               small, political disasters aside, moving around 1% or 100 pips.
//               Study live forex rates and charts here. You can trade forex CFDs
//               with Convex Integrated. The availability of leveraged trading in this
//               market can amplify wins, but similarly, can amplify losses.
//             </p>
//             <p>
//               Trade forex with leverage Currencies are available to trade with
//               up to 30:1 leverage. Start trading with as little as $100 to
//               control a position of $3000
//             </p>
//           </span>

//           <span className="w-1/2 flex flex-col gap-8 text-lg">
//             <p>
//               Forex CFDs Trade 24/5 on a wide range of Forex. Buy and sell Forex
//               to take advantage of rises and falls in price
//             </p>
//             <p>
//               Profit and loss control You can easily define Stops and Limits to
//               request positions to close at a specified price. Set up price
//               alerts to be informed of big moves.
//             </p>
//           </span>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//         <div className="flex gap-11 py-11 px-6">
//           <span className="w-1/2 flex flex-col gap-4 text-lg">
//             <p>
//               Convex Integrated finance offers a broad array of professional services
//               and access to the global foreign exchange markets for commercial
//               and institutional clients. execution and clearing services in
//               virtually all tradable currency pairs and derivative instruments.
//             </p>
//             <p>
//               Currency pair trading, also known as FX or forex (foreign
//               exchange), enables traders to take advantage of increases and
//               decreases in a currency’s value. The foreign exchange market is
//               the most liquid in the world, with a daily trading volume of over
//               $5 trillion. We are specialists in leveraged trading, giving you
//               the potential to generate financial returns on both rising and
//               falling prices across FX, market. Whether you’re an experienced
//               trader or completely new to it, we’re here to help you find
//               freedom in the financial markets.
//             </p>
//           </span>

//           <span className="w-1/2 flex flex-col gap-8 text-lg">
//             <p>
//               While a lot of foreign exchange is done for practical purposes,
//               the vast majority of currency conversion is undertaken with the
//               aim of earning a profit. The amount of currency converted every
//               day can make price movements of some currencies extremely
//               volatile. It is this volatility that can make forex so attractive
//               to traders: bringing about a greater chance of high profits, while
//               also increasing the risk.
//             </p>
//             <span>
//               <h1 className="font-bold text-xl mb-4">What is forex trading?</h1>
//               <p>
//                 Forex, or foreign exchange, can be explained as a network of
//                 buyers and sellers, who transfer currency between each other at
//                 an agreed price. It is the means by which individuals, companies
//                 and central banks convert one currency into another – if you
//                 have ever travelled abroad, then it is likely you have made a
//                 forex transaction.
//               </p>
//             </span>
//           </span>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />

//         <div className="px-11 text-lg py-11">
//           <h className="mb-4 py-6 font-bold text-xl">WHAT ARE THE BENEFITS?</h>
//           <ul className="list-none mt-8 space-y-4">
//             <li className="flex items-center space-x-2">
//               <i className="fas fa-arrow-up text-xl"></i>
//               <span>Go long or short</span>
//             </li>
//             <li className="flex items-center space-x-2">
//               <i className="fas fa-clock text-xl"></i>
//               <span>24-hour trading</span>
//             </li>
//             <li className="flex items-center space-x-2">
//               <i className="fas fa-water text-xl"></i>
//               <span>High liquidity</span>
//             </li>
//             <li className="flex items-center space-x-2">
//               <i className="fas fa-bolt text-xl"></i>
//               <span>Constant opportunities</span>
//             </li>
//             <li className="flex items-center space-x-2">
//               <i className="fas fa-chart-line text-xl"></i>
//               <span>Trade on leverage</span>
//             </li>
//             <li className="flex items-center space-x-2">
//               <i className="fas fa-exchange-alt text-xl"></i>
//               <span>Wide range of FX pairs</span>
//             </li>
//           </ul>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function Forex() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'PRODUCTS'}
        h1={'FOREX'}
        h1Blue={'TRADING'}
        backgroundImage={'../img/forex-bg.jpg'}
      />
      <main className="bg-gray-200 px-6 py-10 sm:pb-20 sm:pt-16">
        <div className="max-w-7xl mx-auto">
          <h1 className="font-bold text-3xl uppercase py-4 mb-4 sm:pt-10 text-center">
            TRADE FOREX CFDS WITH Convex Integrated
          </h1>
          <div className="flex flex-col sm:flex-row gap-6">
            <p className="w-full sm:w-1/2 leading-8 text-sm sm:text-lg">
              The FX market is one of the world’s biggest markets. You can trade
              currency pairs from every corner of the world. However, there are
              a handful of pairs that are worth mentioning: more traded than any
              other currency pairs, the ‘majors’ dominate the FX market. The
              four most traded currency pairs in the world have been coined the
              ‘majors’. They involve the following currencies: euro, US dollar,
              Japanese yen and pound sterling. The most
            </p>
            <p className="w-full sm:w-1/2 leading-8 text-sm sm:text-lg">
              popular pairing is the EUR/USD, followed by the USD/JPY, GBP/USD
              and USD/CHF pairs respectively. There are a few other currencies
              that deserve to be mentioned. Informally known as the ‘commodity
              pairs’, the AUD/USD or ‘Aussie’, USD/CAD and NZD/USD are all
              frequently traded currency pairs. Unsurprisingly, this group of
              currencies derives its nickname from the fact that they come from
              countries that possess large quantities of natural resources.
            </p>
          </div>
        </div>

        <h2 className="font-bold text-2xl mt-12 mb-8 text-center">
          ADVANTAGES OF TRADING CURRENCY PAIRS
        </h2>

        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row gap-12">
          <div className="w-full sm:w-1/2 flex flex-col gap-4">
            <p className="text-lg">
              The FX market is one of the most exciting and fast-paced for
              traders. Open 24/7 and being the biggest global market means it is
              extremely liquid; you can instantly buy and sell, so you’re never
              stuck in a trade. Daily currency fluctuations are usually fairly
              small, political disasters aside, moving around 1% or 100 pips.
              Study live forex rates and charts here. You can trade forex CFDs
              with Convex Integrated. The availability of leveraged trading in
              this market can amplify wins, but similarly, can amplify losses.
            </p>
            <p className="text-lg">
              Trade forex with leverage Currencies are available to trade with
              up to 30:1 leverage. Start trading with as little as $100 to
              control a position of $3000
            </p>
          </div>

          <div className="w-full sm:w-1/2 flex flex-col gap-8">
            <p className="text-lg">
              Forex CFDs Trade 24/5 on a wide range of Forex. Buy and sell Forex
              to take advantage of rises and falls in price
            </p>
            <p className="text-lg">
              Profit and loss control You can easily define Stops and Limits to
              request positions to close at a specified price. Set up price
              alerts to be informed of big moves.
            </p>
          </div>
        </div>

        <hr className="mx-auto w-4/5 mt-12 mb-8" />

        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row gap-12">
          <div className="w-full sm:w-1/2 flex flex-col gap-4">
            <p className="text-lg">
              Convex Integrated finance offers a broad array of professional
              services and access to the global foreign exchange markets for
              commercial and institutional clients. execution and clearing
              services in virtually all tradable currency pairs and derivative
              instruments.
            </p>
            <p className="text-lg">
              Currency pair trading, also known as FX or forex (foreign
              exchange), enables traders to take advantage of increases and
              decreases in a currency’s value. The foreign exchange market is
              the most liquid in the world, with a daily trading volume of over
              $5 trillion. We are specialists in leveraged trading, giving you
              the potential to generate financial returns on both rising and
              falling prices across FX, market. Whether you’re an experienced
              trader or completely new to it, we’re here to help you find
              freedom in the financial markets.
            </p>
          </div>

          <div className="w-full sm:w-1/2 flex flex-col gap-8">
            <p className="text-lg">
              While a lot of foreign exchange is done for practical purposes,
              the vast majority of currency conversion is undertaken with the
              aim of earning a profit. The amount of currency converted every
              day can make price movements of some currencies extremely
              volatile. It is this volatility that can make forex so attractive
              to traders: bringing about a greater chance of high profits, while
              also increasing the risk.
            </p>
            <div className="flex flex-col gap-4">
              <h3 className="font-bold text-xl">What is forex trading?</h3>
              <p>
                Forex, or foreign exchange, can be explained as a network of
                buyers and sellers, who transfer currency between each other at
                an agreed price. It is the means by which individuals, companies
                and central banks convert one currency into another – if you
                have ever travelled abroad, then it is likely you have made a
                forex transaction.
              </p>
            </div>
          </div>
        </div>

        <hr className="mx-auto w-4/5 mt-12 mb-8" />

        <div className="max-w-7xl mx-auto px-6 text-lg py-8">
          <h2 className="font-bold text-2xl mb-4">WHAT ARE THE BENEFITS?</h2>
          <ul className="list-none space-y-4">
            <li className="flex items-center space-x-2">
              <i className="fas fa-arrow-up text-xl"></i>
              <span>Go long or short</span>
            </li>
            <li className="flex items-center space-x-2">
              <i className="fas fa-clock text-xl"></i>
              <span>24-hour trading</span>
            </li>
            <li className="flex items-center space-x-2">
              <i className="fas fa-water text-xl"></i>
              <span>High liquidity</span>
            </li>
            <li className="flex items-center space-x-2">
              <i className="fas fa-bolt text-xl"></i>
              <span>Constant opportunities</span>
            </li>
            <li className="flex items-center space-x-2">
              <i className="fas fa-chart-line text-xl"></i>
              <span>Trade on leverage</span>
            </li>
            <li className="flex items-center space-x-2">
              <i className="fas fa-exchange-alt text-xl"></i>
              <span>Wide range of FX pairs</span>
            </li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
}
