import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Notify from './toastify.comp';
import notifySuccessMsg from '../utils/notify.succes';

function UserUpdatePage() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    country: '',
    state: '',
    address: '',
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data when the component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    // First, check local storage for user data
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setFormData({
        firstname: storedUser.firstname,
        lastname: storedUser.lastname,
        email: storedUser.email,
        phone: storedUser.phone,
        country: storedUser.country,
        state: storedUser.state,
        address: storedUser.address,
      });
    } else {
      try {
        const token = Cookies.get('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${BASEURLDEV}/user/`, { headers });

        setFormData({
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          email: response.data.email,
          phone: response.data.phone,
          country: response.data.country,
          state: response.data.state,
          address: response.data.address,
        });
      } catch (error) {
        console.error('Error fetching user data:', error.response);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = Cookies.get('token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.patch(
        `${BASEURLDEV}/user/updateMe`,
        formData,
        { headers }
      );

      console.log(response, 'user');
      // Update local storage with the updated user data
      localStorage.setItem('user', JSON.stringify(response.data.doc));

      // Redirect to profile page after successful update
      navigate('/dashboard');
      notifySuccessMsg(response.data.message);
    } catch (error) {
      console.error('Error updating profile:', error.response);
      handleServerError(error.response?.status, error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-start justify-center bg-darkBlue-900 py-12">
      <div className="bg-darkBlue-900 text-white rounded-lg shadow-lg w-full max-w-md mx-auto p-6">
        <div className="flex justify-between items-center border-b pb-3">
          <h3 className="text-lg font-semibold">Update Profile</h3>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mt-4">
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="firstname">
              First Name
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="lastname">
              Last Name
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="phone">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="country">
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="state">
              State
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded"
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => navigate('/dashboard')} // Redirect to profile page if canceled
              className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
              disabled={loading}>
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded"
              disabled={loading}>
              {loading ? 'Updating...' : 'Update'}
            </button>
          </div>
        </form>
      </div>
      <Notify />
    </div>
  );
}

export default UserUpdatePage;
