import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';
import BlogPost from './blog';
export default function Insight() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'LATEST NEWS'}
        h1={'NEW INSIGHT'}
        // h1Blue={'INVESTMENT MANAGER'}
        backgroundImage={'../img/res-bg.jpg'}
      />
      <main>
        <BlogPost />
      </main>
      <hr />
      <Footer />
    </div>
  );
}
