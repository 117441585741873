import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../../constants/api';
import { handleAdminServerError } from './handleAdminErr';
import Cookies from 'js-cookie';

const AccountList = () => {
  const [accounts, setAccounts] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [newBalance, setNewBalance] = useState('');
  const [loadingId, setLoadingId] = useState(null);
  const token = Cookies.get('adminToken');

  // Fetch accounts on component mount
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(`${BASEURLDEV}/admin/accounts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        setAccounts(response.data.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        handleAdminServerError(
          error.response?.status,
          error.response?.data.message
        );
      }
    };

    fetchAccounts();
  }, [token]);

  // Handle balance editing
  const handleEditClick = (accountId, currentBalance) => {
    setEditingId(accountId);
    setNewBalance(currentBalance);
  };

  // Save updated balance
  const handleSaveClick = async (userId) => {
    setLoadingId(userId);
    console.log(userId);
    try {
      await axios.patch(
        `${BASEURLDEV}/admin/account/balance`,
        { newBalance: Number(newBalance), userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditingId(null);
      setNewBalance('');
      setLoadingId(null); // Reset loading state

      // Refresh account list
      const response = await axios.get(`${BASEURLDEV}/admin/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAccounts(response.data.data);
    } catch (error) {
      console.error('Error updating balance:', error.response);
      setLoadingId(null);
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Accounts</h1>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              User ID
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Balance
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {accounts.map((account) => (
            <tr key={account._id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {account.userId ? account.userId._id : 'N/A'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {account.userId ? account.userId.email : 'N/A'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {account.userId ? account.userId.firstname : 'N/A'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {editingId === account._id ? (
                  <input
                    type="number"
                    value={newBalance}
                    onChange={(e) => setNewBalance(e.target.value)}
                    className="border border-gray-300 rounded-md p-1"
                  />
                ) : (
                  account.balance
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                {editingId === account._id ? (
                  <button
                    onClick={() =>
                      handleSaveClick(account.userId ? account.userId._id : '')
                    }
                    className={`px-4 py-2 rounded-md ${
                      loadingId === account.userId?._id
                        ? 'bg-blue-300 text-white cursor-not-allowed'
                        : 'bg-blue-500 text-white'
                    }`}
                    disabled={loadingId === account.userId?._id} // Disable button while loading
                  >
                    {loadingId === account.userId?._id ? 'Saving...' : 'Save'}
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      handleEditClick(account._id, account.balance)
                    }
                    className="bg-yellow-500 text-white px-4 py-2 rounded-md">
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountList;
