import React, { useState, useEffect } from 'react';

// Helper functions for generating random date and amount
const generateRandomDate = () => {
  const start = new Date();
  const end = new Date();
  end.setDate(end.getDate() - Math.floor(Math.random() * 30));
  return new Date(
    start.getTime() - Math.random() * (start.getTime() - end.getTime())
  ).toLocaleDateString();
};

const generateRandomAmount = () => (Math.random() * 1000000).toFixed(2); // Random amount in millions

const names = [
  'Alice Johnson',
  'Bob Smith',
  'Eva Brown',
  'Michael Davis',
  'Sophia Wilson',
  'Daniel Lee',
];

const LatestTransactionTable = () => {
  const [latestDeposits, setLatestDeposits] = useState([]);
  const [latestWithdrawals, setLatestWithdrawals] = useState([]);

  // Function to update latest deposits and withdrawals
  const updateTransactions = () => {
    const newDeposits = Array.from({ length: 6 }, () => ({
      name: names[Math.floor(Math.random() * names.length)],
      date: generateRandomDate(),
      amount: generateRandomAmount(),
    }));

    const newWithdrawals = Array.from({ length: 6 }, () => ({
      name: names[Math.floor(Math.random() * names.length)],
      date: generateRandomDate(),
      amount: generateRandomAmount(),
    }));

    setLatestDeposits(newDeposits);
    setLatestWithdrawals(newWithdrawals);
  };

  // Update transactions every 5 seconds
  useEffect(() => {
    const interval = setInterval(updateTransactions, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="space-y-8 sm:space-y-0 sm:flex sm:gap-8">
        {/* Latest Deposits Table */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex-1">
          <h2 className="bg-blue-600 text-white px-6 py-4 text-lg font-semibold uppercase text-center">
            Latest Deposits
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount ($M)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {latestDeposits.map((transaction, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm font-medium text-gray-900">
                      {transaction.name}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600">
                      {transaction.date}
                    </td>
                    <td className="px-4 py-2 text-sm font-medium text-gray-900">
                      {parseFloat(transaction.amount).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Latest Withdrawals Table */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex-1">
          <h2 className="bg-red-600 text-white px-6 py-4 text-lg font-semibold uppercase text-center">
            Latest Withdrawals
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount ($M)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {latestWithdrawals.map((transaction, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm font-medium text-gray-900">
                      {transaction.name}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600">
                      {transaction.date}
                    </td>
                    <td className="px-4 py-2 text-sm font-medium text-gray-900">
                      {parseFloat(transaction.amount).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestTransactionTable;
