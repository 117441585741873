import RecentTab from './overview.comp';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';

export default function RecentInvComp() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const token = Cookies.get('token');
  const headers = [
    '#',
    'Date',
    'Type',
    'Amount',
    'Method',
    'Balance',
    'Remark',
    'TxId',
  ];

  useEffect(() => {
    fetchRecentInvestments();
  }, []);
  const fetchRecentInvestments = async () => {
    try {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BASEURLDEV}/investment/getInv`, {
        headers,
      });
      console.log(response);
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      // Assuming response.data.data.investments is an array of investments
      const recentInvestments = response.data.data.investments.filter(
        (investment) => new Date(investment.createdAt) >= twentyFourHoursAgo
      );

      setData(recentInvestments);
    } catch (error) {
      console.error('Error fetching recent investments:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RecentTab
      title="Recent Investment"
      headers={headers}
      data={data || []}
      link={'/dashboard/my-investment'}
    />
  );
}
