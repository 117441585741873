// // import React from 'react';
// // import { Link } from 'react-router-dom';

// // const CardComponent = ({ image, heading, paragraph, link }) => {
// //   return (
// //     <Link
// //       to={link}
// //       className="block group">
// //       <div className="w-full h-full pb-4 bg-blue-500 text-slate-50 border border-gray-200 hover:shadow-lg transition transform hover:-translate-y-2 duration-300">
// //         <div
// //           className="w-full h-80 bg-cover bg-center mb-4"
// //           style={{ backgroundImage: `url(${image})` }}></div>
// //         <p className="px-4 uppercase">{paragraph}</p>
// //         <h2 className="px-4 text-xl font-bold capitalize mb-2 group-hover:text-blue-600 transition-colors duration-300">
// //           {heading}
// //         </h2>
// //       </div>
// //     </Link>
// //   );
// // };

// // const CardGrid = () => {
// //   const cards = [
// //     {
// //       image: '../img/bitcoin.jpg',
// //       heading: 'cryptocurrency',
// //       paragraph: 'services',
// //       link: '/crypto',
// //     },
// //     {
// //       image: '../img/agric.jpg',
// //       heading: 'agricultural',
// //       paragraph: 'services',
// //       link: '/agriculture',
// //     },
// //     {
// //       image: '../img/trading.jpg',
// //       heading: 'NFP',
// //       paragraph: 'services',
// //       link: '/nfp',
// //     },
// //     {
// //       image: '../img/oil-gas.jpg',
// //       heading: 'Oil and gas',
// //       paragraph: 'services',
// //       link: '/page4',
// //     },
// //     {
// //       image: '../img/estate.jpg',
// //       heading: 'Real Estate',
// //       paragraph: 'services',
// //       link: '/nfp',
// //     },
// //     {
// //       image: '../img/forex1.jpg',
// //       heading: 'forex trading',
// //       paragraph: 'services',
// //       link: '/forex',
// //     },
// //   ];

// //   return (
// //     <div className="container mx-auto w-full px-4 py-8">
// //       <div className="grid grid-cols-3 grid-rows-2 gap-6 overflow-x-auto">
// //         {cards.map((card, index) => (
// //           <CardComponent
// //             key={index}
// //             image={card.image}
// //             heading={card.heading}
// //             paragraph={card.paragraph}
// //             link={card.link}
// //           />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default CardGrid;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const CardComponent = ({ image, heading, paragraph, link }) => {
//   return (
//     <Link
//       to={link}
//       className="block group">
//       <div className="w-full h-full pb-4 bg-blue-500 text-slate-50 border border-gray-200 hover:shadow-lg transition transform hover:-translate-y-2 duration-300">
//         <div
//           className="w-full h-80 bg-cover bg-center mb-4"
//           style={{ backgroundImage: `url(${image})` }}></div>
//         <p className="px-4 uppercase">{paragraph}</p>
//         <h2 className="px-4 text-xl font-bold capitalize mb-2 group-hover:text-blue-600 transition-colors duration-300">
//           {heading}
//         </h2>
//       </div>
//     </Link>
//   );
// };

// const CardGrid = () => {
//   const cards = [
//     {
//       image: '../img/bitcoin.jpg',
//       heading: 'cryptocurrency',
//       paragraph: 'services',
//       link: '/crypto',
//     },
//     {
//       image: '../img/agric.jpg',
//       heading: 'agricultural',
//       paragraph: 'services',
//       link: '/agriculture',
//     },
//     {
//       image: '../img/trading.jpg',
//       heading: 'NFP',
//       paragraph: 'services',
//       link: '/nfp',
//     },
//     {
//       image: '../img/oil-gas.jpg',
//       heading: 'Oil and gas',
//       paragraph: 'services',
//       link: '/page4',
//     },
//     {
//       image: '../img/estate.jpg',
//       heading: 'Real Estate',
//       paragraph: 'services',
//       link: '/nfp',
//     },
//     {
//       image: '../img/forex1.jpg',
//       heading: 'forex trading',
//       paragraph: 'services',
//       link: '/forex',
//     },
//   ];

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           initialSlide: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="container mx-auto w-full px-4 py-8">
//       <div className="hidden md:grid md:grid-cols-3 grid-rows-2 gap-6 overflow-x-auto">
//         {cards.map((card, index) => (
//           <CardComponent
//             key={index}
//             image={card.image}
//             heading={card.heading}
//             paragraph={card.paragraph}
//             link={card.link}
//           />
//         ))}
//       </div>
//       <div className="md:hidden">
//         <Slider {...settings}>
//           {cards.map((card, index) => (
//             <CardComponent
//               key={index}
//               image={card.image}
//               heading={card.heading}
//               paragraph={card.paragraph}
//               link={card.link}
//             />
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default CardGrid;

import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CardComponent = ({ image, heading, paragraph, link }) => {
  return (
    <Link
      to={link}
      className="block group">
      <div className="w-full h-full bg-blue-600 pb-4 text-slate-50 border border-gray-200 hover:shadow-lg transition transform hover:-translate-y-2 duration-300">
        <div
          className="w-full h-80 bg-cover bg-center mb-4"
          style={{ backgroundImage: `url(${image})` }}></div>
        <p className="px-4 uppercase">{paragraph}</p>
        <h2 className="px-4 text-xl font-bold capitalize mb-2 group-hover:text-blue-600 transition-colors duration-300">
          {heading}
        </h2>
      </div>
    </Link>
  );
};

const CardGrid = () => {
  const cards = [
    {
      image: '../img/bitcoin.jpg',
      heading: 'cryptocurrency',
      paragraph: 'services',
      link: '/crypto',
    },
    {
      image: '../img/agric.jpg',
      heading: 'agricultural',
      paragraph: 'services',
      link: '/agriculture',
    },
    {
      image: '../img/trading.jpg',
      heading: 'NFP',
      paragraph: 'services',
      link: '/nfp',
    },
    {
      image: '../img/oil-gas.jpg',
      heading: 'Oil and gas',
      paragraph: 'services',
      link: '/oil-&-gas',
    },
    {
      image: '../img/estate.jpg',
      heading: 'Real Estate',
      paragraph: 'services',
      link: '/real-estate',
    },
    {
      image: '../img/forex1.jpg',
      heading: 'forex trading',
      paragraph: 'services',
      link: '/forex',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto w-full px-4">
      <div className="hidden md:grid md:grid-cols-3 grid-rows-2 gap-6 overflow-x-auto">
        {cards.map((card, index) => (
          <CardComponent
            key={index}
            image={card.image}
            heading={card.heading}
            paragraph={card.paragraph}
            link={card.link}
          />
        ))}
      </div>
      <div className="md:hidden">
        <Slider {...settings}>
          {cards.map((card, index) => (
            <div
              key={index}
              className="px-2">
              <CardComponent
                image={card.image}
                heading={card.heading}
                paragraph={card.paragraph}
                link={card.link}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CardGrid;
