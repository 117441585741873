import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function OilGas() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'OIL'}
        h1Blue={'& GAS'}
        backgroundImage={'../img/og-bg.jpg'}
      />
      <main>
        <div className="bg-gray-200 text-lg px-11 py-11">
          {/* <h1 className="text-gray-500 mb-4 font-bold text-3xl">
            What is long-term care?
          </h1> */}

          <p className="pb-11">
            The oil and gas industry is one of the largest sectors in the world
            in terms of dollar value, generating an estimated $3.3 trillion in
            revenue annually. Oil is crucial to the global economic framework,
            especially for its largest producers: the United States, Saudi
            Arabia, Russia, Canada and China Giving you the best
          </p>
        </div>

        {/* <div className="py-11 my-11">
          <h1 className="text-center font-bold uppercase text-3xl my-4">
            OUR SERVICES, TAILORED TO YOU
          </h1>
          <hr className="w-1/2 mx-auto" />
          <div className="flex gap-6 items-center justify-center">
            <div class="text-gray-900 text-lg my-4">
              <ul class="list-disc ml-6">
                <li>Financial Planning</li>
                <li>Investment Planning & Strategies</li>
                <li>Advanced Portfolio Management</li>
                <li>Retirement Planning & Income Strategies</li>
                <li>Estate Planning & Trust Services</li>
                <li>Risk Management</li>
              </ul>
            </div>
            <div class="text-gray-900 text-lg">
              <ul class="list-disc ml-6">
                <li>Tax Efficient Strategies</li>
                <li>Advisor Coordination</li>
                <li>Business Planning & Succession</li>
                <li>Multigenerational Planning</li>
                <li>Charitable Planning</li>
                <li>Education Funding</li>
              </ul>
            </div>
          </div>
        </div> */}
      </main>
      <hr />
      <Footer />
    </div>
  );
}
