import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';

const AllTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = Cookies.get('token');

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BASEURLDEV}/account/getTx`, {
        headers,
      });
      console.log(response);
      setTransactions(response.data.doc.transactions); // Assuming response.data.doc.transactions is an array of transactions
    } catch (error) {
      console.error('Error fetching transactions:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500';
      case 'declined':
        return 'text-blue-500';
      case 'successful':
        return 'text-green-500';
      default:
        return 'text-gray-300';
    }
  };

  return (
    <div className="mt-8 bg-gray-900 text-white rounded p-6">
      <h2 className="text-lg font-semibold mb-4">All Transactions</h2>
      {loading ? (
        <p className="text-gray-300">Loading transactions...</p>
      ) : transactions.length === 0 ? (
        <p className="text-gray-300">No transactions found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {transactions.map((transaction, index) => (
            <div
              key={index}
              className="p-4 bg-gray-800 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold text-blue-500 mb-2">
                Transaction ID: {transaction.transactionId}
              </h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="text-gray-300">
                  <span className="font-medium">Type:</span> {transaction.type}
                </div>
                <div className="text-gray-300">
                  <span className="font-medium">Amount:</span> $
                  {transaction.amount}
                </div>
                <div className="text-gray-300">
                  <span className="font-medium">Date:</span>{' '}
                  {new Date(transaction.createdAt).toLocaleDateString()}
                </div>
                <div
                  className={`text-gray-300 ${getStatusColor(
                    transaction.status
                  )}`}>
                  <span className="font-medium">Status:</span>{' '}
                  {transaction.status}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AllTransactions;
