// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function Infrastructure() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'INVESTMENT PRODUCTS'}
//         h1={'INFRASTRUCTURE'}
//         h1Blue={''}
//         backgroundImage={'../img/infra-bg-2.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">INFRASTRUCTURE</h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               We are one of the world’s largest infrastructure investors, owning
//               and operating assets across the utilities, transport, energy and
//               data infrastructure sectors. Our portfolio, grounded in 12 years
//               of investment experience, provides diversified exposure to scarce,
//               high-quality businesses
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               with significant barriers to entry. We invest in infrastructure
//               assets that deliver essential goods and services—from the movement
//               of passengers and freight over toll roads and rail networks to the
//               distribution of energy and other products through ports and
//               pipelines, and much more.
//             </p>
//           </span>
//         </div>
//         <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
//           EXPANDING A VIRTUOUS ENERGY CIRCLE
//         </h1>

//         <div className="py-11 px-6">
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-center justify-center">
//               <p>
//                 Enwave provides large-scale commercial heating and cooling
//                 services in 11 cities across North America. Its brand has become
//                 synonymous with innovative renewable technologies and
//                 environmental stewardship.
//               </p>
//               <div>
//                 <h2 className="font-bold text-xl mb-4 text-gray-500 mt-10">
//                   REAL ESTATE LEADERSHIP
//                 </h2>
//                 <p>
//                   Our leaders share a commitment to our time-tested approach to
//                   investing, hands-on value creation and practices that have a
//                   positive impact on our communities.
//                 </p>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/infra-1.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>
//         <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
//           INFRASTRUCTURE SECTORS
//         </h1>
//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             utilities
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Spanning four continents, our utilities portfolio benefits from
//                 steady, inflation-linked cash flows.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 uppercase mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     6.6 million
//                   </h1>
//                   <p className="text-gray-600">
//                     ELECTRICITY AND GAS CONNECTIONS
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl sm:text-4xl font-bold">
//                     2,700km
//                   </h1>
//                   <p className="text-gray-600">
//                     REGULATED NATURAL GAS PIPELINE
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl sm:text-4xl font-bold">
//                     2,700km
//                   </h1>
//                   <p className="text-gray-600">
//                     ELECTRICITY TRANSMISSION LINES
//                   </p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/infra-2.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             Transport
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 We own and operate port, rail and toll road assets moving
//                 freight, bulk commodities and passengers across five continents.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     32,300 km
//                   </h1>
//                   <p className="text-gray-600">RAIL OPERATIONS</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     4,000 km
//                   </h1>
//                   <p className="text-gray-600">TOLL ROADS</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     13
//                   </h1>
//                   <p className="text-gray-600">PORT</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/infra-3.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             ENERGY
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our energy businesses in North America and Asia Pacific generate
//                 stable revenues through long-term contracts with large-scale
//                 customers.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     16,500 km
//                   </h1>
//                   <p className="text-gray-600">NATURAL GAS PIPELINE</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     600 BCF
//                   </h1>
//                   <p className="text-gray-600">NATURAL GAS STORAGE</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     1.6 million
//                   </h1>
//                   <p className="text-gray-600">
//                     RESIDENTIAL INFRASTRUCTURE CUSTOMERS
//                   </p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/infra-4.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             DATA INFRASTRUCTURE
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 We own and operate businesses that provide essential services
//                 and critical infrastructure to transmit and store data globally.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     9100
//                   </h1>
//                   <p className="text-gray-600">
//                     MULTIPURPOSE TOWERS AND ACTIVE ROOFTOP SITES
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     20,000 km
//                   </h1>
//                   <p className="text-gray-600">OF FIBER BACKBONE</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     52
//                   </h1>
//                   <p className="text-gray-600">DATA CENTERS</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/infra-5.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function Infrastructure() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'INVESTMENT PRODUCTS'}
        h1={'INFRASTRUCTURE'}
        h1Blue={''}
        backgroundImage={'../img/infra-bg-2.jpg'}
      />
      <main className="">
        <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
          <h1 className="font-bold text-3xl py-4 mb-4 pt-10">INFRASTRUCTURE</h1>
          <div className="flex flex-col sm:flex-row gap-6 pb-10">
            <p className="w-full sm:w-1/2 leading-10 text-xs sm:text-lg">
              We are one of the world’s largest infrastructure investors, owning
              and operating assets across the utilities, transport, energy and
              data infrastructure sectors. Our portfolio, grounded in 12 years
              of investment experience, provides diversified exposure to scarce,
              high-quality businesses
            </p>
            <p className="w-full sm:w-1/2 leading-10 text-xs sm:text-lg">
              with significant barriers to entry. We invest in infrastructure
              assets that deliver essential goods and services—from the movement
              of passengers and freight over toll roads and rail networks to the
              distribution of energy and other products through ports and
              pipelines, and much more.
            </p>
          </div>
        </div>

        <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
          EXPANDING A VIRTUOUS ENERGY CIRCLE
        </h1>

        <div className="py-11 px-6">
          <hr className="mx-auto py-8" />
          <div className="flex flex-col sm:flex-row gap-8 justify-center">
            <div className="w-full sm:w-1/2 flex items-center justify-center">
              <p className="text-lg">
                Enwave provides large-scale commercial heating and cooling
                services in 11 cities across North America. Its brand has become
                synonymous with innovative renewable technologies and
                environmental stewardship.
              </p>
            </div>
            <div className="w-full sm:w-1/2">
              <h2 className="font-bold text-xl mb-4 text-gray-500 mt-10">
                REAL ESTATE LEADERSHIP
              </h2>
              <p>
                Our leaders share a commitment to our time-tested approach to
                investing, hands-on value creation and practices that have a
                positive impact on our communities.
              </p>
            </div>
          </div>
        </div>

        <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
          INFRASTRUCTURE SECTORS
        </h1>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
            Utilities
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col sm:flex-row gap-8 justify-center">
            <div className="w-full sm:w-1/2 flex flex-col gap-8">
              <p>
                Spanning four continents, our utilities portfolio benefits from
                steady, inflation-linked cash flows.
              </p>
              <div className="flex flex-col gap-4">
                <span>
                  <h1 className="border-b-4 py-2 uppercase mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    6.6 million
                  </h1>
                  <p className="text-gray-600">
                    ELECTRICITY AND GAS CONNECTIONS
                  </p>
                </span>
                <span>
                  <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl sm:text-4xl font-bold">
                    2,700 km
                  </h1>
                  <p className="text-gray-600">
                    REGULATED NATURAL GAS PIPELINE
                  </p>
                </span>
                <span>
                  <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl sm:text-4xl font-bold">
                    2,700 km
                  </h1>
                  <p className="text-gray-600">
                    ELECTRICITY TRANSMISSION LINES
                  </p>
                </span>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/infra-2.jpg"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
            Transport
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col sm:flex-row gap-8 justify-center">
            <div className="w-full sm:w-1/2 flex flex-col gap-8">
              <p>
                We own and operate port, rail and toll road assets moving
                freight, bulk commodities and passengers across five continents.
              </p>
              <div className="flex flex-col gap-4">
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    32,300 km
                  </h1>
                  <p className="text-gray-600">RAIL OPERATIONS</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    4,000 km
                  </h1>
                  <p className="text-gray-600">TOLL ROADS</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    13
                  </h1>
                  <p className="text-gray-600">PORT</p>
                </span>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/infra-3.jpg"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
            Energy
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col sm:flex-row gap-8 justify-center">
            <div className="w-full sm:w-1/2 flex flex-col gap-8">
              <p>
                Our energy businesses in North America and Asia Pacific generate
                stable revenues through long-term contracts with large-scale
                customers.
              </p>
              <div className="flex flex-col gap-4">
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    16,500 km
                  </h1>
                  <p className="text-gray-600">NATURAL GAS PIPELINE</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    600 BCF
                  </h1>
                  <p className="text-gray-600">NATURAL GAS STORAGE</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    1.6 million
                  </h1>
                  <p className="text-gray-600">
                    RESIDENTIAL INFRASTRUCTURE CUSTOMERS
                  </p>
                </span>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/infra-4.jpg"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
            Data Infrastructure
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col sm:flex-row gap-8 justify-center">
            <div className="w-full sm:w-1/2 flex flex-col gap-8">
              <p>
                We own and operate businesses that provide essential services
                and critical infrastructure to transmit and store data globally.
              </p>
              <div className="flex flex-col gap-4">
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    9100
                  </h1>
                  <p className="text-gray-600">
                    MULTIPURPOSE TOWERS AND ACTIVE ROOFTOP SITES
                  </p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    20,000 km
                  </h1>
                  <p className="text-gray-600">OF FIBER BACKBONE</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
                    52
                  </h1>
                  <p className="text-gray-600">DATA CENTERS</p>
                </span>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <img
                className="w-full"
                src="../img/infra-5.jpg"
                alt="estate-1"
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
