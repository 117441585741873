import React from 'react';
import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';
const Asset = () => {
  return (
    <>
      <NavBar />
      <BackgroundImageWithText
        p={'ABOUT US'}
        h1={'WE ARE A GLOBAL SYSTEMATIC'}
        h1Blue={'INVESTMENT MANAGER'}
        backgroundImage={'../img/og-bg.jpg'}
      />
      <div className="max-w-6xl  px-10 py-8">
        <h1 className="text-4xl font-bold mb-8">About Responsibility</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div>
            <h2 className="text-2xl font-bold mb-4">Investment Products</h2>
            <ul className="list-disc pl-6">
              <li>REAL ESTATE</li>
              <li>STOCKS</li>
              <li>INFRASTRUCTURE</li>
              <li>FOREX TRADING</li>
              <li>CRYPTO ASSET</li>
              <li>FIXED INCOME</li>
              <li>MULTI ASSETS</li>
              <li>RENEWABLE POWER</li>
              <li>AGRICULTURE</li>
              <li>NFP</li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4">Planning Services</h2>
            <ul className="list-disc pl-6">
              <li>FINANCIAL PLANNING</li>
              <li>RETIREMENT PLANNING</li>
              <li>PRIVATE WEALTH MANAGEMENT</li>
              <li>ESTATE PLANNING</li>
              <li>LONG-TERM CARE</li>
              <li>BUSINESS SERVICES</li>
              <li>OIL & GAS</li>
            </ul>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">SECTORS</h2>
          <div className="bg-gray-100 rounded-lg p-6 shadow-md">
            <h3 className="text-xl font-semibold mb-2">
              Real Asset Access Solutions
            </h3>
            <p className="mb-4">
              Investors looking to add a broad real assets allocation to their
              private markets portfolio and gain access to key market sectors,
              including energy, power, infrastructure, and natural resources
              turn to Convex Integrated. Real assets also serve as a crucial
              portfolio diversifier as they tend to respond to different
              economic conditions than other private asset classes. The
              performance of real assets, for example, has been positively
              correlated with inflation which has made them an effective hedge
              during periods when prices have risen.
            </p>
            <h3 className="text-xl font-semibold mb-2">
              INVESTORS: WHY CHOOSE Convex Integrated
            </h3>
            <p className="mb-4">
              Investing in real assets, which can be highly complex in nature,
              requires extensive domain expertise and strong deal execution
              capabilities. Convex Integrated employs a differentiated approach
              to investing in real assets, which includes a secondary
              markets-focused strategy, a flexible investment mandate, and an
              asset selection framework designed to help mitigate risk while
              harnessing upside potential. The investment team has deep industry
              experience, which is complemented by the broader capabilities of
              the firm’s global investment platform.
            </p>
            <p className="mb-4">
              When you select Convex Integrated as a private markets investment
              partner, you benefit from:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Specialized expertise: Investments in real assets can pose
                unique tax, regulatory, contractual, or structural challenges.
                Convex Integrated brings decades of highly specialized
                experience to bear for our clients, including extensive
                operational capabilities, a deep familiarity with asset-level
                complexity, and tax and structuring knowledge that can help
                offset these and other risks.
              </li>
              <li>
                Approach to underwriting: Convex Integrated’s asset selection
                framework imposes financial discipline on underwriting and seeks
                real assets investments with limited downside risk to deliver
                attractive risk-adjusted returns throughout cycles.
              </li>
              <li>
                Customization: Whether investors are looking to gain targeted
                exposure to certain real assets sub-sectors, geographies, or
                investment types, Convex Integrated creates programs that can
                effectively meet its clients’ unique needs.
              </li>
            </ul>
            <p className="mb-4">
              Convex Integrated is more than just finance, it’s hope for an
              average entrepreneur to stand, evolve.
            </p>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Main Services</h2>
          <ul className="list-disc pl-6">
            <li>Cryptocurrency</li>
            <li>Real Estate</li>
            <li>Stock</li>
            <li>Fixed Income</li>
            <li>Multi Assets</li>
          </ul>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Useful Links</h2>
          <ul className="list-disc pl-6">
            <li>About Us</li>
            <li>Corporate responsibility</li>
            <li>Planning services</li>
            <li>Contact Us</li>
            <li>FAQs</li>
          </ul>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
          <div>
            <p className="text-lg leading-relaxed mb-2">
              For inquiries and more information, contact us:
            </p>
            <ul className="list-disc pl-6">
              <li>Phone: VIP ONLY</li>
              <li>
                Email:{' '}
                <a href="mailto:support@convexintegrated.com">
                  support@convexintegrated.com
                </a>
              </li>
              <span className="mt-4 flex flex-col gap-4">
                <p className="font-bold">Locations:</p>
                <ul className="flex flex-col gap-2">
                  <li>
                    6th Floor, Point House, 6 Front Street, Hamilton, HM 11,
                    Bermuda.
                  </li>
                  <li>Glategny Esplanade, Guernsey, GY1 1WR, Guernsey.</li>
                  <li>
                    37 Boulevard Joseph II, 2ème étage, Luxembourg City, L-1840,
                    Grand-Duchy of Luxembourg.
                  </li>
                  <li>Lime Street, London, EC3M 7AF, UK.</li>
                </ul>
              </span>
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-300 pt-4 text-center">
          <ul className="flex justify-center space-x-4">
            <li>
              <a
                href="/about-us"
                className="text-blue-500 hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a
                href="/faq"
                className="text-blue-500 hover:underline">
                Faq
              </a>
            </li>
            <li>
              <a
                href="/blog"
                className="text-blue-500 hover:underline">
                Blog
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="text-blue-500 hover:underline">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Asset;
