import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import Cookies from 'js-cookie';

const ConnectedWallets = () => {
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    fetchWallets();
  }, []);

  const fetchWallets = async () => {
    const token = Cookies.get('token');
    try {
      const response = await axios.get(`${BASEURLDEV}/user/myWallet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setWallets(response.data.doc.wallet);
    } catch (error) {
      console.error('Error fetching wallets:', error);
    }
  };

  const handleCopyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    alert(`Copied ${address} to clipboard`);
  };

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-4">Connected Wallets</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-900 text-white">
          <thead>
            <tr className="bg-gray-800">
              <th className="py-2 px-4 border-b border-gray-700">
                Wallet Address
              </th>
              <th className="py-2 px-4 border-b border-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {wallets.length > 0 ? (
              wallets.map((address, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-700">
                  <td className="py-2 px-4 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[200px]">
                    {address}
                  </td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => handleCopyToClipboard(address)}
                      className="flex items-center justify-center text-blue-500 hover:text-blue-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mr-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M14 3H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2zM6 5h8v10H6V5zm7 2a1 1 0 00-2 0v6a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Copy
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="py-2 px-4 text-center"
                  colSpan="2">
                  No wallets connected
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConnectedWallets;
