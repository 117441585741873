import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-xl text-gray-600 mb-6">
          Oops! The page you’re looking for doesn’t exist.
        </p>
        <p className="text-base text-gray-500 mb-8">
          It might have been moved, or the URL might be incorrect.
        </p>
        <Link
          to="/"
          className="inline-block px-6 py-3 bg-blue-600 text-white text-base font-medium rounded-md hover:bg-blue-700 transition-colors">
          Go Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
