// import React from 'react';

// const CardDisplay = () => {
//   const slides = [
//     {
//       image: '../img/forest.jpg',
//       heading: 'SECTORS INSTITUTIONAL INVESTMENT MANAGEMENT',
//       paragraph:
//         'When you select Coin Brokerage to manage institutional assets, you will discover...',
//       buttonText: 'Learn More',
//       link: '/alternative',
//     },
//     {
//       image: '../img/worker.jpg',
//       heading: 'REAL ASSETS',
//       paragraph:
//         'Investors looking to add a broad real assets allocation to their private markets...',
//       buttonText: 'Learn More',
//       link: '/asset',
//     },
//     {
//       image: '../img/investment.jpg',
//       heading: 'ALTERNATIVES',
//       paragraph:
//         'As a pioneer in alternative investing, Coin Brokerage has a long track record of managing...',
//       buttonText: 'Learn More',
//       link: '/alternative',
//     },
//   ];

//   return (
//     <div className="relative z-50 mt-10 pt-10 w-full px-4 overflow-x-auto">
//       <div className="flex md:justify-between space-x-4 gap-4 md:space-x-0">
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className="flex-none z-50 pb-4 py-6 w-80 md:flex-1 h-80 overflow-hidden shadow-lg bg-cover bg-center relative"
//             style={{
//               backgroundImage: `url(${slide.image})`,
//             }}>
//             <div className="absolute inset-0 bg-black opacity-60"></div>
//             <div className="absolute inset-0 flex flex-col gap-3 justify-center items-start text-white px-6">
//               <p className="text-gray-200 text-base mb-2">SECTORS</p>
//               <div className="font-bold text-3xl mb-2 uppercase border-b-4 border-blue-800">
//                 {slide.heading}
//               </div>
//               <p className="text-gray-200 text-base">{slide.paragraph}</p>
//               <a
//                 href={slide.link}
//                 className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4">
//                 {slide.buttonText}
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CardDisplay;

import React from 'react';

const CardDisplay = () => {
  const slides = [
    {
      image: '../img/forest.jpg',
      heading: 'SECTORS INSTITUTIONAL INVESTMENT MANAGEMENT',
      paragraph:
        'When you select Convex Intergrated to manage institutional assets, you will discover...',
      buttonText: 'Learn More',
      link: '/alternative',
    },
    {
      image: '../img/worker.jpg',
      heading: 'REAL ASSETS',
      paragraph:
        'Investors looking to add a broad real assets allocation to their private markets...',
      buttonText: 'Learn More',
      link: '/asset',
    },
    {
      image: '../img/investment.jpg',
      heading: 'ALTERNATIVES',
      paragraph:
        'As a pioneer in alternative investing, Convex Integrated has a long track record of managing...',
      buttonText: 'Learn More',
      link: '/alternative',
    },
  ];

  return (
    <div className="relative py-11 mt-10 pt-10 w-full px-4 overflow-x-auto">
      <div className="flex md:justify-between space-x-4 gap-4 md:space-x-0">
        {slides.map((slide, index) => (
          <div
            key={index}
            className="flex-none pb-4 py-11 w-80 md:flex-1 h-80 overflow-hidden shadow-lg bg-cover bg-center relative"
            style={{
              backgroundImage: `url(${slide.image})`,
            }}>
            <div className="absolute inset-0 bg-black opacity-60"></div>
            <div className="absolute inset-0 flex flex-col gap-3 justify-center items-start text-white px-6">
              <p className="text-gray-200 text-base mb-2">SECTORS</p>
              <div className="font-bold text-lg w-full mb-2 uppercase border-b-4 border-blue-800">
                {slide.heading}
              </div>
              <p className="text-gray-200 text-base mb-4">{slide.paragraph}</p>
              <a
                href={slide.link}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-auto">
                {slide.buttonText}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardDisplay;
