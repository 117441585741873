// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';

// export default function About() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'ABOUT US'}
//         h1={'WE ARE A GLOBAL SYSTEMATIC'}
//         h1Blue={'INVESTMENT MANAGER'}
//         backgroundImage={'../img/og-bg.jpg'}
//       />
//       <main>
//         <div className="bg-gray-200 text-lg px-11 py-11">
//           {/* <h1 className="text-gray-500 mb-4 font-bold text-3xl">
//             What is long-term care?
//           </h1> */}

//           <p className="pb-11 text-lg">
//             Convex Integrated is a global systematic investment manager. Our
//             centralized research focuses on the development of advanced
//             quantitative techniques for uncovering market opportunities and
//             employs them within a disciplined framework that results in
//             efficient exposures
//           </p>
//           <p className="pb-11 text-lg">
//             With a robust infrastructure and talented investment professionals,
//             Convex Integrated offers clients the scale of a large asset management
//             firm, with the benefits of a versatile investment platform –
//             flexibility and customization. Our firm is able to offer
//             institutional and retail investors the essential building blocks for
//             today’s changing investment landscape.
//           </p>
//         </div>
//         <div className="px-10 py-11">
//           <div className="flex  items-center py-2">
//             <hr className="w-1/2" />
//             <h1 className="text-center text-3xl font-semibold my-4">
//               A LEADING GLOBAL INVESTMENT MANAGER
//             </h1>
//             <hr className="w-1/2" />
//           </div>
//           <div className="flex basis-9 justify-around my-11 text-gray-600 items-center gap-11">
//             <span>
//               <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                 $625B
//               </h1>
//               <p className="text-gray-600 capitalize">
//                 Assets under management.
//               </p>
//             </span>
//             <span>
//               <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                 $1.5T
//               </h1>
//               <p className="text-gray-600 capitalize">Assets under advice.</p>
//             </span>
//             <span>
//               <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                 995
//               </h1>
//               <p className="text-gray-600 capitalize"> Employees.</p>
//             </span>
//           </div>
//         </div>
//         <hr />
//         <div className="flex items-center gap-8 py-11 px-10">
//           <div className="flex-col text-lg gap-8 w-1/2 ">
//             <h1 className="font-bold text-3xl w-1/2 my-6">
//               ACTIVE MANAGEMENT ACROSS ASSET CLASSES
//             </h1>
//             <p>
//               Matrix Ace offers regional and global high-active-share equities,
//               fixed income across the yield curve, liquidity solutions backed by
//               four decades of experience as a core capability and, in private
//               markets, real estate, infrastructure, private equity and private
//               debt. Beyond investment management, Matrix Ace provides engagement
//               in equity and bond markets, proxy voting and policy advocacy..
//             </p>
//           </div>
//           <div className="w-1/2">
//             <img
//               alt="work"
//               src="../img/work.png"
//             />
//           </div>
//         </div>

//         <hr />
//         <div className="my-11">
//           <h1 className="font-bold text-3xl text-center mx-auto w-1/2 my-6">
//             COMMITMENT TO RESPONSIBLE INVESTING
//           </h1>
//           <p className="text-lg w-1/2 mx-auto text-center">
//             As responsible investors, our goals are to help individuals invest
//             and retire better, to help clients achieve better risk-adjusted
//             returns, and to contribute to positive outcomes in the wider world
//           </p>
//         </div>

//         <div className="bg-gray-200 text-lg px-11 py-11">
//           <h1 className="font-bold text-3xl text-center mx-auto w-1/2 my-6">
//             THE MATRIX ACE PLEDGE
//           </h1>
//           <p className="text-lg w-1/2 mx-auto text-center">
//             The Matrix Ace Pledge underpins our firm-wide commitment to always
//             put clients first, and to act responsibly and transparently.
//           </p>
//           <p className="text-lg w-1/2 mx-auto text-center">
//             I pledge to fulfill, to the best of my ability and judgment and in
//             accordance with my role, this covenant:
//           </p>
//           <div className="flex gap-4 items-center py-11 ">
//             <span className="border  border-slate-950 p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 {/* <h2 className="text-lg font-semibold">
//                   INVESTMENT GRADE CORPORATES
//                 </h2> */}
//                 <p className="text-lg">
//                   I will act ethically, responsibly and with integrity
//                 </p>
//               </div>
//             </span>

//             <span className="border  border-slate-950 p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 {/* <h2 className="text-lg font-semibold">UNCONSTRAINED BONDS</h2> */}
//                 <p className="text-lg">
//                   I will put the interests of our clients first, consistent with
//                   our fiduciary responsibilities
//                 </p>
//               </div>
//             </span>

//             <span className="border border-slate-950 p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 {/* <h2 className="text-lg font-semibold">CORE PLUS</h2> */}
//                 <p className="text-lg">
//                   I will encourage responsible behavior in the firms in which we
//                   invest and on which we engage
//                 </p>
//               </div>
//             </span>
//           </div>
//           <div className="flex gap-4 items-center  ">
//             <span className="border  border-slate-950 p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 {/* <h2 className="text-lg font-semibold">
//                   INVESTMENT GRADE CORPORATES
//                 </h2> */}
//                 <p className="text-lg">
//                   I will act with consideration for our community and the
//                   environment both now and in the future. I will encourage
//                   others to do the same
//                 </p>
//               </div>
//             </span>

//             <span className="border  border-slate-950 p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 {/* <h2 className="text-lg font-semibold">UNCONSTRAINED BONDS</h2> */}
//                 <p className="text-lg">
//                   I will work with industry colleagues and other key
//                   stakeholders to develop and improve our industry’s
//                   contribution to society
//                 </p>
//               </div>
//             </span>

//             <span className="border border-slate-950 p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 {/* <h2 className="text-lg font-semibold">CORE PLUS</h2> */}
//                 <p className="text-lg">
//                   I will treat my clients and all other stakeholders with
//                   dignity and respect and as I would wish to be treated
//                 </p>
//               </div>
//             </span>
//           </div>
//         </div>
//       </main>
//       <hr />
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function About() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'ABOUT US'}
        h1={'WE ARE A GLOBAL SYSTEMATIC'}
        h1Blue={'INVESTMENT MANAGER'}
        backgroundImage={'../img/og-bg.jpg'}
      />
      <main>
        {/* Introduction Section */}
        <div className="bg-gray-200 px-4 sm:px-11 py-6 sm:py-11 text-lg sm:text-xl">
          <p className="pb-6">
            Convex Integrated is a global systematic investment manager. Our
            centralized research focuses on the development of advanced
            quantitative techniques for uncovering market opportunities and
            employs them within a disciplined framework that results in
            efficient exposures
          </p>
          <p className="pb-6">
            With a robust infrastructure and talented investment professionals,
            Convex Integrated offers clients the scale of a large asset
            management firm, with the benefits of a versatile investment
            platform – flexibility and customization. Our firm is able to offer
            institutional and retail investors the essential building blocks for
            today’s changing investment landscape.
          </p>
        </div>

        {/* Key Statistics Section */}
        <div className="px-4 sm:px-10 py-6 sm:py-11">
          <div className="flex flex-wrap justify-around gap-4 sm:gap-11 text-gray-600">
            <div className="w-full sm:w-1/3 flex items-center justify-center flex-col gap-4">
              <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-2xl sm:text-4xl font-bold">
                $625B
              </h1>
              <p className="capitalize">Assets under management.</p>
            </div>
            <div className="w-full sm:w-1/3 flex items-center justify-center flex-col gap-4">
              <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-2xl sm:text-4xl font-bold">
                $1.5T
              </h1>
              <p className="capitalize">Assets under advice.</p>
            </div>
            <div className="w-full sm:w-1/3 flex items-center justify-center flex-col gap-4">
              <h1 className="border-b-4 py-2 mb-2 border-blue-900 text-2xl sm:text-4xl font-bold">
                995
              </h1>
              <p className="capitalize">Employees.</p>
            </div>
          </div>
        </div>

        {/* Active Management Section */}
        <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-8 py-6 sm:py-11 px-4 sm:px-10">
          <div className="w-full sm:w-1/2">
            <h1 className="font-bold text-3xl sm:text-4xl my-6">
              ACTIVE MANAGEMENT ACROSS ASSET CLASSES
            </h1>
            <p className="text-lg">
              Convex Integrated offers regional and global high-active-share
              equities, fixed income across the yield curve, liquidity solutions
              backed by four decades of experience as a core capability and, in
              private markets, real estate, infrastructure, private equity and
              private debt. Beyond investment management, Convex Integrated
              provides engagement in equity and bond markets, proxy voting and
              policy advocacy.
            </p>
          </div>
          <div className="w-full sm:w-1/2">
            <img
              alt="work"
              src="../img/work.png"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* Commitment Section */}
        <div className="bg-gray-200 text-lg px-4 sm:px-11 py-6 sm:py-11">
          <h1 className="font-bold text-3xl text-center my-6">
            COMMITMENT TO RESPONSIBLE INVESTING
          </h1>
          <p className="text-lg text-center">
            As responsible investors, our goals are to help individuals invest
            and retire better, to help clients achieve better risk-adjusted
            returns, and to contribute to positive outcomes in the wider world.
          </p>
        </div>

        {/* Matrix Ace Pledge Section */}
        <div className="bg-gray-200 text-lg px-4 sm:px-11 py-6 sm:py-11">
          <h1 className="font-bold text-3xl uppercase text-center my-6">
            THE Convex Integrated PLEDGE
          </h1>
          <p className="text-lg text-center">
            The Convex Integrated Pledge underpins our firm-wide commitment to
            always put clients first, and to act responsibly and transparently.
          </p>
          <div className="flex flex-wrap justify-around gap-4 sm:gap-8 items-start mt-8">
            <div className="w-full sm:w-1/2">
              <div className="border border-slate-950 p-4 flex items-start gap-4">
                <i className="fas fa-apple-alt text-xl"></i>
                <div>
                  <p className="text-lg">
                    I will act ethically, responsibly and with integrity.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="border border-slate-950 p-4 flex items-start gap-4">
                <i className="fas fa-apple-alt text-xl"></i>
                <div>
                  <p className="text-lg">
                    I will put the interests of our clients first, consistent
                    with our fiduciary responsibilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="border border-slate-950 p-4 flex items-start gap-4">
                <i className="fas fa-apple-alt text-xl"></i>
                <div>
                  <p className="text-lg">
                    I will encourage responsible behavior in the firms in which
                    we invest and on which we engage.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="border border-slate-950 p-4 flex items-start gap-4">
                <i className="fas fa-apple-alt text-xl"></i>
                <div>
                  <p className="text-lg">
                    I will act with consideration for our community and the
                    environment both now and in the future. I will encourage
                    others to do the same.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="border border-slate-950 p-4 flex items-start gap-4">
                <i className="fas fa-apple-alt text-xl"></i>
                <div>
                  <p className="text-lg">
                    I will work with industry colleagues and other key
                    stakeholders to develop and improve our industry’s
                    contribution to society.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="border border-slate-950 p-4 flex items-start gap-4">
                <i className="fas fa-apple-alt text-xl"></i>
                <div>
                  <p className="text-lg">
                    I will treat my clients and all other stakeholders with
                    dignity and respect and as I would wish to be treated.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <hr />
      <Footer />
    </div>
  );
}
