import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/user.context';
import ConfirmationModal from '../component/confirmation';

const SeeInvestments = () => {
  const {
    fetchInvestments,
    investments,
    isLoading,
    reinvestFunds,
    addToBalance,
    invNotification,
    setInvNotification,
  } = useAuth();

  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchInvestments();
  }, []);

  useEffect(() => {
    if (invNotification?.message) {
      const timer = setTimeout(() => {
        setInvNotification({ type: '', message: '' });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [invNotification, setInvNotification]);
  const handleReinvest = async () => {
    if (selectedInvestment) {
      try {
        await reinvestFunds(
          selectedInvestment._id,
          selectedInvestment.amountInvested +
            selectedInvestment.accumulatedInterest,
          selectedInvestment.type,
          selectedInvestment.duration,
          selectedInvestment.interest
        );
      } catch (error) {
      } finally {
        setIsModalOpen(false);
        fetchInvestments();
      }
    }
  };

  const handleAddToBalance = async () => {
    if (selectedInvestment) {
      try {
        await addToBalance(
          selectedInvestment._id,
          selectedInvestment.amountInvested +
            selectedInvestment.accumulatedInterest
        );
        setSelectedInvestment((prev) => ({
          ...prev,
          reinvested: true,
        }));
        // setNotification({
        //   type: 'success',
        //   message: 'Amount added to balance successfully!',
        // });
      } catch (error) {
        // setNotification({
        //   type: 'error',
        //   message: 'Failed to add amount to balance. Please try again.',
        // });
      } finally {
        setIsModalOpen(false);
        fetchInvestments();
      }
    }
  };

  const handleInvestmentClick = (investment) => {
    if (investment.status === 'completed' && !investment.reinvested) {
      setSelectedInvestment(investment);
      setIsModalOpen(true);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500';
      case 'ongoing':
        return 'text-blue-500';
      case 'completed':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const completedInvestments = investments.filter(
    (investment) => investment.status === 'completed'
  );
  const ongoingInvestments = investments.filter(
    (investment) => investment.status === 'ongoing'
  );

  return (
    <div className="mt-8 bg-gray-900 text-white rounded p-6">
      <h2 className="text-lg font-semibold mb-4">My Investments</h2>
      {isLoading ? (
        <p className="text-gray-300">Loading investments...</p>
      ) : investments.length === 0 ? (
        <p className="text-gray-300">No investments found.</p>
      ) : (
        <>
          <div className="mb-6">
            <h3 className="text-lg font-semibold text-gray-400 mb-2">
              Ongoing Investments
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {ongoingInvestments.map((investment, index) => (
                <div
                  key={index}
                  className="p-4 bg-gray-800 rounded cursor-default">
                  <h3 className="text-lg font-semibold text-blue-500 mb-2">
                    Investment ID: {investment.id}
                  </h3>
                  <p className="text-gray-300">Type: {investment.type}</p>
                  <p className="text-gray-300">
                    Amount: ${investment.amountInvested}
                  </p>
                  <p className="text-gray-300">
                    Duration: {investment.duration}
                  </p>
                  <p className="text-gray-300">
                    Interest Rate: {investment.interest}%
                  </p>
                  <p
                    className={`text-gray-300 ${getStatusStyle(
                      investment.status
                    )}`}>
                    Status: {investment.status}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-400 mb-2">
              Completed Investments
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {completedInvestments.map((investment, index) => (
                <div
                  key={index}
                  className={`p-4 bg-gray-800 rounded cursor-pointer hover:bg-gray-700 transition duration-300 ease-in-out ${
                    investment.reinvested ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={() => handleInvestmentClick(investment)}>
                  <h3 className="text-lg font-semibold text-blue-500 mb-2">
                    Investment ID: {investment.id}
                  </h3>
                  <p className="text-gray-300">Type: {investment.type}</p>
                  <p className="text-gray-300">
                    Amount: ${investment.amountInvested}
                  </p>
                  <p className="text-gray-300">
                    Duration: {investment.duration}
                  </p>
                  <p className="text-gray-300">
                    Interest Rate: {investment.interest}%
                  </p>
                  <p
                    className={`text-gray-300 ${getStatusStyle(
                      investment.status
                    )}`}>
                    Status: {investment.status}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {invNotification?.message && (
        <div
          className={`fixed bottom-4 right-50 p-4 rounded z-50 ${
            invNotification.type === 'success' ? 'bg-green-600' : 'bg-red-600'
          } text-white`}>
          {invNotification?.message}
        </div>
      )}

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleReinvest}
        onReject={handleAddToBalance}
        investment={selectedInvestment}
      />
    </div>
  );
};

export default SeeInvestments;
