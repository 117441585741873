import React from 'react';

const VideoPlayer = ({ src, poster, title }) => {
  return (
    <div className="max-w-lg mx-auto my-6 p-4 bg-white shadow-lg rounded-lg">
      <video
        className="w-full h-auto rounded-lg"
        controls
        poster={poster}>
        <source
          src={src}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      {title && (
        <div className="mt-2 text-center">
          <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
