import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASEURLDEV } from '../constants/api';
import Notify from './toastify.comp';
import { handleServerError } from '../utils/error.handler';
import notifySuccessMsg from '../utils/notify.succes';

const InvestmentComponent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [duration, setDuration] = useState('7 days');
  const [amountInvested, setAmountInvested] = useState('');
  const [interest, setInterest] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const investmentPlans = [
    {
      name: 'Cooperate Plan',
      interestRate: 2.5,
      minAmount: 100,
      maxAmount: 5000,
    },
    {
      name: 'Ultimate Plan',
      interestRate: 3.5,
      minAmount: 5000,
      maxAmount: 10000,
    },
    {
      name: 'Premium Plan',
      interestRate: 4,
      minAmount: 15000,
      maxAmount: 20000,
    },
    {
      name: 'Master Plan',
      interestRate: 5,
      minAmount: 20000,
      maxAmount: 25000,
    },
    {
      name: 'Standard Plan',
      interestRate: 6,
      minAmount: 30000,
      maxAmount: Infinity,
    },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setInterest(plan.interestRate);
    setAmountInvested(plan.minAmount);
    setCurrentStep(2);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (Number(amountInvested) < selectedPlan.minAmount) {
        setError(
          `Amount invested cannot be less than ${selectedPlan.minAmount}`
        );
        return;
      }

      const token = Cookies.get('token');
      const requestBody = {
        duration: 7,
        type: selectedPlan.name,
        amountInvested: Number(amountInvested),
        interest,
      };

      const response = await axios.post(
        `${BASEURLDEV}/investment/newInvestment`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('API Response:', response.data);
      notifySuccessMsg(response.data.message);
    } catch (error) {
      console.error('Error making API call:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmountInvested(value);
    if (Number(value) < selectedPlan.minAmount) {
      setError(`Amount invested cannot be less than ${selectedPlan.minAmount}`);
    } else {
      setError('');
    }
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

  return (
    <div className="mt-8 bg-gray-900 text-white rounded p-6">
      <h2 className="text-lg font-semibold mb-4">Investment Plans</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {investmentPlans.map((plan, index) => (
          <div
            key={index}
            onClick={() => handlePlanSelect(plan)}
            className={`p-4 ${
              selectedPlan === plan ? 'bg-blue-700' : 'bg-gray-800'
            } rounded cursor-pointer hover:bg-gray-700 transition duration-300 ease-in-out md:w-full lg:w-full`}>
            <h3 className="text-lg font-semibold text-blue-500 mb-2">
              {plan.name}
            </h3>
            <p className="text-gray-300">Interest Rate: {plan.interestRate}%</p>
            <p className="text-gray-300">Minimum: ${plan.minAmount}</p>
            <p className="text-gray-300">
              Maximum:{' '}
              {plan.maxAmount === Infinity ? 'Unlimited' : `$${plan.maxAmount}`}
            </p>
          </div>
        ))}
      </div>
      {currentStep === 2 && (
        <form
          onSubmit={handleSubmit}
          className="mt-6">
          <h3 className="text-lg font-semibold mb-4">
            Confirm Investment Details
          </h3>
          <p className="text-gray-300 mb-2">
            Selected Plan: {selectedPlan.name}
          </p>
          <div className="mb-2">
            <label
              htmlFor="duration"
              className="block font-medium text-gray-300">
              Duration
            </label>
            <input
              type="text"
              id="duration"
              value={duration}
              readOnly
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="amountInvested"
              className="block font-medium text-gray-300">
              Amount Invested ($)
            </label>
            <input
              type="number"
              id="amountInvested"
              value={amountInvested}
              onChange={handleAmountChange}
              min={selectedPlan.minAmount}
              className={`w-full px-3 py-2 bg-gray-700 text-white rounded ${
                error ? 'border-red-500' : ''
              }`}
              required
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          </div>
          <div className="mb-4">
            <label
              htmlFor="interest"
              className="block font-medium text-gray-300">
              Interest Rate (%)
            </label>
            <input
              type="number"
              id="interest"
              value={interest}
              readOnly
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            />
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={handleBack}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Back
            </button>
            <button
              type="submit"
              className={`bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}>
              {loading ? 'Submitting...' : 'Submit Investment'}
            </button>
          </div>
        </form>
      )}
      <Notify />
    </div>
  );
};

export default InvestmentComponent;
