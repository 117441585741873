import UserAuthComp from '../component/usercomp.auth';
import AtSymbolIcon from '../icons/at.icon';
import { handleServerError } from '../utils/error.handler';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import LoginIcon from '../icons/login.icon';
import Spinner from '../component/spinner';
import { useState } from 'react';
import Notify from '../component/toastify.comp';
import { useNavigate } from 'react-router-dom';
import notifySuccessMsg from '../utils/notify.succes';

export default function ResetTokenPage() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function sendResetToken(email) {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURLDEV}/user/userForgetPassword`,
        { email }
      );
      if (response.status === 200) {
        notifySuccessMsg(response.data.message);
        setIsLoading(false);
        return navigate('/reset-password');
      } else {
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleServerError(error.response.status, error.response.data.message);
      console.log(error.response);
    }
  }

  async function handleSendToken(e) {
    e.preventDefault();
    await sendResetToken(email);
  }

  return (
    <div>
      <UserAuthComp
        handler={handleSendToken}
        type="Reset Password Token">
        <div className="flex flex-col gap-4">
          <span className="flex items-center gap-2 p-2 mb-8 rounded-md bg-transparent border border-gray-300">
            <AtSymbolIcon />
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="youremail@gmail.com"
              className="bg-transparent bg-none text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>
        </div>

        <span className="px-4 mt-4 py-2 flex sm:w-60 items-center rounded-md gap-2 border border-gray-300 bg-slate-800 hover:bg-slate-700 transition-colors">
          <LoginIcon />
          {!isLoading && (
            <button className="outline-none text-slate-50">Send Token</button>
          )}
          {isLoading && <Spinner />}
        </span>
      </UserAuthComp>
      <Notify />
    </div>
  );
}
