import RecentTab from './overview.comp';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';

export default function RecentTxComp() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const token = Cookies.get('token');
  const headers = [
    '#',
    'Date',
    'Type',
    'Amount',
    'Method',
    'Balance',
    'Remark',
    'TxId',
  ];
  useEffect(() => {
    fetchRecentTransactions();
  }, []);
  const fetchRecentTransactions = async () => {
    try {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BASEURLDEV}/account/getTx`, {
        headers,
      });

      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

      const recentTransactions = response.data.doc.transactions.filter(
        (transaction) =>
          transaction.type === 'debit' &&
          transaction.status === 'successful' &&
          new Date(transaction.createdAt) >= twentyFourHoursAgo
      );
      console.log(response, 'rTx');
      setData(recentTransactions);
    } catch (error) {
      console.error('Error fetching recent transactions:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RecentTab
      title="Recent Transactions"
      headers={headers}
      data={data || []}
      link={'/dashboard/my-tx'}
    />
  );
}
