import React from 'react';

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  onReject,
  investment,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white text-gray-900 rounded p-6 max-w-sm mx-auto relative">
        <h3 className="text-lg font-semibold mb-4">Investment Matured</h3>
        <p className="mb-4">
          Your investment (ID: {investment.id}) has matured. Do you want to
          reinvest the funds?
        </p>
        <div className="flex justify-between">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={onConfirm}>
            Yes, Reinvest
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            onClick={onReject}>
            No, Add to Balance
          </button>
        </div>
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
