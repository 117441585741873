// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faMapMarkerAlt,
//   faDollarSign,
//   faCreditCard,
// } from '@fortawesome/free-solid-svg-icons';
// import 'tailwindcss/tailwind.css';

// export default function RetirePlan() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'SERVICES'}
//         h1={'RETIREMENT'}
//         h1Blue={'PLANNING'}
//         backgroundImage={'../img/retire-bg.jpg'}
//       />
//       <main>
//         {/* <div className="bg-green-100 mb-6 flex px-10 py-11">
//           <div className="w-1/2 flex items-center justify-center">
//             <i
//               className="fas fa-utensils text-green-900"
//               style={{ fontSize: '15vw' }}></i>
//           </div>
//           <div className="w-1/2">
//             <div className="flex items-center gap-4 mb-6">
//               <h1 className="font-bold uppercase text-2xl">
//                 A PLAN THAT PUTS THE BEST FINANCIAL CHOICES IN YOUR HANDS
//               </h1>
//             </div>
//             <div className="flex flex-col gap-6">
//               <span className="flex gap-6 items-center">
//                 <p>
//                   These days, it's more important than ever to have a plan. Our
//                   version of financial planning not only gives you the
//                   confidence to know you're ready for anything, but is also
//                   designed to help you reach all your goals in the days ahead.
//                   Our advisors will design a personalized financial plan with
//                   the right
//                 </p>
//                 <p>
//                   insurance and investment strategies, so you can take care of
//                   the people and things that matter most. And with the highest
//                   financial strength ratings of any US life insurer, you can
//                   count on Coin Brokage to be here whenever you need us. After
//                   all, we've been going strong for over 5 years.
//                 </p>
//               </span>
//               <span className="border capitalize text-lg flex items-center w-72 bg-green-200">
//                 <a
//                   className="bg-green-900 text-center w-1/2 hover:bg-green-200 hover:text-slate-900 text-slate-50 px-4 py-2"
//                   href="/signUp">
//                   get started
//                 </a>
//                 <a
//                   className="hover:bg-green-900 text-center w-1/2 hover:text-slate-50 text-slate-950 px-4 py-2"
//                   href="/contact">
//                   contact us
//                 </a>
//               </span>
//             </div>
//           </div>
//         </div> */}

//         <div className="px-8">
//           {/* Centered red heart icon */}
//           {/* <div className="flex flex-col items-center py-2">
//             <i className="fas fa-heart text-red-500 text-3xl mb-2"></i>
//             <div className="flex items-center w-full">
//               <hr className="w-1/2" />
//               <h1 className="text-center text-3xl font-semibold mx-4">
//                 WHAT OUR CLIENTS HAVE TO SAY
//               </h1>
//               <hr className="w-1/2" />
//             </div>
//           </div> */}

//           {/* <div className="flex gap-4 items-center py-11">
//             <span className="border p-4 flex items-start space-x-2">

//               <i className="fas fa-watch text-xl"></i>
//               <div className="py-8">
//                 <p className="text-lg">
//                   We met with our advisor to discuss our current and future
//                   investment needs as well as life insurance. He not only helped
//                   set us up with exactly what we needed, but he thoroughly
//                   explained everything we were doing so we knew we were making
//                   the right decisions. He made us feel comfortable from our
//                   first meeting with him.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <p className="text-lg">
//                   My advisor at Coin Brokage has been my go-to-guy for personal
//                   and business financial planning. He’s not pushy and won’t sell
//                   you products just for the commission. He sells on principle,
//                   which is why they’ll be my financial advisor for years to
//                   come.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">

//               <i className="fas fa-dumbbell text-xl"></i>
//               <div className="py-8">
//                 <p className="text-lg">
//                   Our advisor has given me peace of mind for the last 20 years.
//                   His financial knowledge and ability to communicate simply make
//                   me feel like a true partner in the finance planning process.
//                   He always has time to speak to you and allay any concerns
//                   immediately. My retirement is a solid one due to his guiding
//                   me all these years.
//                 </p>
//               </div>
//             </span>
//             <hr className="py-10" />
//           </div> */}

//           {/* <div className="px-10 py-11">
//             <h1 className="font-bold text-3xl uppercase text-center mb-4">
//               OUR VERSION OF FINANCIAL PLANNING
//             </h1>
//             <p className="text-center text-lg  ">
//               Some companies only focus on insurance, Others on investments. Our
//               advisors look at your financial big picture and recommend the
//               right insurance and investment strategies in one integrated
//               financial plan, designed to help you live the life you've always
//               wanted.
//             </p>
//             <p className="text-center py-6 text-lg  ">
//               For those with significant means, our Coin Brokage Private Client
//               Group offers an exclusive comprehensive approach to private wealth
//               management combining the strength of Coin Brokage with the
//               specialized expertise of a boutique firm.
//             </p>
//           </div>
//           <div>
//             <hr />
//           </div> */}
//           <div className="flex items-center mt-11 justify-between px-4 py-10 gap-8">
//             <div className="text-gray-600   flex flex-col gap-4 w-4/5 text-lg">
//               {/* <ul className="list-disc ml-6 ">
//                 <h2 className="mb-2">
//                   To get you where you want to go, we'll look at where you are
//                   today.
//                 </h2>
//                 <li>Your priorities, values, and goals</li>
//                 <li>Your income</li>
//                 <li>Your assets</li>
//                 <li>Your liabilities</li>
//               </ul>
//               <ul className="list-disc mt-4 ml-6">
//                 <h2 className="mb-2">
//                   Life is full of ups and downs. We'll help make sure you have
//                   everything you need for both.
//                 </h2>
//                 <li>Life insurance</li>
//                 <li>Disability insurance</li>
//                 <li>Emergency fund</li>
//                 <li>Long-term care planning</li>
//               </ul>
//               <ul className="list-disc mt-4 ml-6">
//                 <h2 className="mb-2">
//                   We'll help you balance paying down debt while you make
//                   progress on other goals.
//                 </h2>

//                 <li>Credit card debt</li>
//                 <li>Student loans</li>
//                 <li>Mortgage and other loans</li>
//               </ul> */}
//               <p>
//                 The things you see yourself doing when 9–5 no longer belongs to
//                 someone else. The places you picture yourself exploring. The
//                 people you intend to spend it all with. If you can imagine the
//                 way you want to retire, you can plan for it.
//               </p>

//               <p>
//                 With employer pensions on the decline and uncertainty
//                 surrounding Social Security, retirement planning is more
//                 important than ever. At Matrix Ace , we can help you understand
//                 exactly how much you'll need to retire the way you want, and
//                 develop an income strategy to get you there. We'll look at your
//                 expenses, priorities, and goals and help you put your money to
//                 work, so you can take some well-deserved time off.
//               </p>
//               <a
//                 href="/signUp"
//                 alt="certificate"
//                 className="bg-blue-600 mt-8 mb-8 block text-center uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
//                 Start Planning
//               </a>
//             </div>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 alt="retired"
//                 src="../img/retire-1.jpg"
//               />
//             </div>
//           </div>
//           <hr />

//           <div className="flex items-center justify-between px-4 py-10 gap-8">
//             <div className="flex space-x-4 overflow-hidden py-10 justify-center">
//               <div className="text-gray-600 w-1/2 text-lg">
//                 <ul className="list-disc ml-6 ">
//                   <h2 className="mb-2 font-bold">
//                     WHERE CAN RETIREMENT INCOME COME FROM?
//                   </h2>
//                   <p className="mt-2 mb-2">
//                     Beyond traditional investments, here are some more places
//                     where you can find money for retirement:
//                   </p>
//                   <li>
//                     Put money away now, for a guaranteed income later with
//                     annuities.
//                   </li>
//                   <li>
//                     Aside from keeping your loved ones' financial future bright,
//                     you can use living benefits from your whole life insurance
//                     policy after you stop working.
//                     <a
//                       href="#"
//                       className="text-blue-600 hover:underline ml-1">
//                       More about Life Insurance
//                     </a>
//                   </li>
//                   <li>
//                     IRAs and your employer's 401(k) plan are good ways to save
//                     for retirement today.
//                     <a
//                       href="#"
//                       className="text-blue-600 hover:underline ml-1">
//                       Learn more about IRAs and 401(k)s.
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//               <div className="text-gray-600 w-1/2 text-lg">
//                 <ul className="list-disc ml-6 ">
//                   <h2 className="mb-2 font-bold">RETIREMENT</h2>
//                   <h1 className="mt-2 font-sans font-bold text-slate-900 py-2 text-3xl w-4/5 border-b-4 border-blue-700 mb-2">
//                     RETIREMENT WHEN SHOULD I START SAVING FOR RETIREMENT
//                   </h1>
//                   <p>
//                     While having a 401(k) or IRA is a great start, a solid
//                     financial plan takes a closer look at the income you have
//                     coming in, and the amount you'll need to supplement. The
//                     earlier you start planning, the longer you have to build up
//                     your savings and put your plan into action. But even if you
//                     plan on retiring in the next year or two, there's time to
//                     get prepared.
//                   </p>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//       <hr />
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';
import 'tailwindcss/tailwind.css';

export default function RetirePlan() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'RETIREMENT'}
        h1Blue={'PLANNING'}
        backgroundImage={'../img/retire-bg.jpg'} // Replace with correct image path
      />
      <main className="">
        <div className=" flex px-6 flex-col sm:flex-row items-center mt-11 justify-between gap-8">
          <div className="text-gray-600 flex flex-col gap-4 w-full sm:w-4/5 text-lg">
            <p>
              The things you see yourself doing when 9–5 no longer belongs to
              someone else. The places you picture yourself exploring. The
              people you intend to spend it all with. If you can imagine the way
              you want to retire, you can plan for it.
            </p>

            <p>
              With employer pensions on the decline and uncertainty surrounding
              Social Security, retirement planning is more important than ever.
              At Convex Integrated, we can help you understand exactly how much
              you'll need to retire the way you want, and develop an income
              strategy to get you there. We'll look at your expenses,
              priorities, and goals and help you put your money to work, so you
              can take some well-deserved time off.
            </p>
            <a
              href="/signUp"
              alt="certificate"
              className="bg-blue-600 mt-8 mb-8 block text-center uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
              Start Planning
            </a>
          </div>
          <div className="sm:w-1/2 w-full h-auto">
            <img
              className="w-full"
              alt="retired"
              src="../img/retire-1.jpg" // Replace with correct image path
            />
          </div>
        </div>
        <hr className="my-8" />

        <div className="flex items-center justify-between gap-8">
          <div className=" flex-col gap-6 flex px-4 sm:flex-row space-x-4 sm:items-start overflow-hidden py-10 justify-center w-full">
            <div className="text-gray-600 w-full sm:w-1/2 text-lg">
              <ul className="list-disc ml-6">
                <h2 className="font-bold mb-2">
                  WHERE CAN RETIREMENT INCOME COME FROM?
                </h2>
                <p className="mt-2 mb-2">
                  Beyond traditional investments, here are some more places
                  where you can find money for retirement:
                </p>
                <li>
                  Put money away now, for a guaranteed income later with
                  annuities.
                </li>
                <li>
                  Aside from keeping your loved ones' financial future bright,
                  you can use living benefits from your whole life insurance
                  policy after you stop working.
                  <a
                    href="#"
                    className="text-blue-600 hover:underline ml-1">
                    More about Life Insurance
                  </a>
                </li>
                <li>
                  IRAs and your employer's 401(k) plan are good ways to save for
                  retirement today.
                  <a
                    href="#"
                    className="text-blue-600 hover:underline ml-1">
                    Learn more about IRAs and 401(k)s.
                  </a>
                </li>
              </ul>
            </div>
            <div className="text-gray-600 w-full sm:w-1/2 mt-6 text-lg">
              <ul className="list-disc ml-6">
                <h2 className="font-bold mb-2">RETIREMENT</h2>
                <h1 className="mt-2 font-sans font-bold text-slate-900 py-2 text-3xl w-full sm:w-4/5 border-b-4 border-blue-700 mb-2">
                  WHEN SHOULD I START SAVING FOR RETIREMENT?
                </h1>
                <p>
                  While having a 401(k) or IRA is a great start, a solid
                  financial plan takes a closer look at the income you have
                  coming in, and the amount you'll need to supplement. The
                  earlier you start planning, the longer you have to build up
                  your savings and put your plan into action. But even if you
                  plan on retiring in the next year or two, there's time to get
                  prepared.
                </p>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="relative bg-cover bg-center h-60 sm:h-96 w-full"
          style={{ backgroundImage: `url(${'../img/yoga.webp'})` }}>
          <div className="absolute right-0 top-0 flex flex-col gap-4 text-slate-50 p-4 bg-opacity-40 sm:p-6 md:p-8 w-full sm:w-1/2 lg:w-1/3 h-full justify-center">
            <h1 className="uppercase text-slate-50 text-lg sm:text-xl md:text-2xl font-bold border-b-4 border-gray-300 pb-2 sm:pb-4">
              SOCIAL SECURITY ADMINISTRATION, 2017
            </h1>
            <p className="text-sm sm:text-base md:text-lg">
              "31% of American workers have no savings set aside specifically
              for retirement. Beyond traditional investments, here are some more
              places where you can find money for retirement."
            </p>
          </div>
        </div>

        <div className="flex flex-col-reverse mt-9 sm:flex-row sm:items-center gap-6 p-4 sm:p-8">
          <div className="flex-shrink-0">
            <img
              src="../img/bar.png"
              alt="bar"
              className="w-full h-auto max-w-xs sm:max-w-sm"
            />
          </div>
          <div className="text-lg sm:text-xl flex flex-col gap-4">
            <h1 className="relative text-2xl sm:text-3xl font-bold text-gray-800 custom-border">
              PUT TIME ON YOUR SIDE
            </h1>

            <p className="text-base sm:text-lg text-gray-600">
              We often hear about the importance of starting early to save for
              retirement, but it's hard to visualize the difference that
              starting early can make. Regardless of the rate of return (as long
              as it's greater than zero) a person who only saves from ages
              25–35, will always have considerably more money by age 60, than
              the person who saved from ages 35–60. Thanks to compounding
              interest, you can end up with more money, after saving for fewer
              years, as long as you start earlier.
            </p>
          </div>
        </div>
      </main>
      <hr className="my-8" />
      <Footer />
    </div>
  );
}
