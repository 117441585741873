import React from 'react';
import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';
const Alt = () => {
  return (
    <>
      <NavBar />
      <BackgroundImageWithText
        p={'ABOUT US'}
        h1={'WE ARE A GLOBAL SYSTEMATIC'}
        h1Blue={'INVESTMENT MANAGER'}
        backgroundImage={'../img/og-bg.jpg'}
      />
      <div className="max-w-4xl  px-11 py-8">
        <h1 className="text-3xl font-bold mb-4">About Convex Integrated</h1>
        <p className="text-lg leading-relaxed mb-6">
          Convex Integrated is a leading firm in integrated financial services,
          providing comprehensive solutions across various sectors including
          real estate, stocks, infrastructure, forex trading, crypto assets,
          fixed income, multi-assets, renewable power, agriculture, and more.
        </p>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Our Services</h2>
          <ul className="list-disc pl-6">
            <li>Financial Planning</li>
            <li>Retirement Planning</li>
            <li>Private Wealth Management</li>
            <li>Estate Planning</li>
            <li>Long-Term Care</li>
            <li>Business Services</li>
            <li>Oil & Gas</li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Sectors</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {/* Sector cards */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">Alternatives</h3>
              <p className="text-base">
                As a pioneer in alternative investing, Convex Integrated manages
                complex strategies including absolute return and total return
                strategies.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">Real Assets</h3>
              <p className="text-base">
                Investing in tangible assets like real estate, infrastructure,
                natural resources, and commodities.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">Crypto Asset</h3>
              <p className="text-base">
                Offering a variety of cryptocurrencies for trading and
                investment.
              </p>
            </div>
            {/* Additional sectors */}
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Investment Strategies</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {/* Strategy cards */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">Private Equity</h3>
              <p className="text-base">
                Investing in equity capital of private companies for potentially
                above-market returns.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">Hedge Strategies</h3>
              <p className="text-base">
                Long-short equity, global macro, relative value, and credit
                strategies to minimize market beta and seek alpha.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-2">Managed Futures</h3>
              <p className="text-base">
                Diversified strategy capturing price trends in global asset
                classes.
              </p>
            </div>
            {/* Additional strategies */}
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Institutional Management</h2>
          <p className="text-lg leading-relaxed">
            Convex Integrated offers institutional asset management with a focus
            on long-term stability and performance.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
          <div>
            <p className="text-lg leading-relaxed mb-2">
              For inquiries and more information, contact us:
            </p>
            <ul className="list-disc pl-6">
              <li>Phone:VIP Only</li>
              <li>
                Email:{' '}
                <a href="mailto:support@convexintegrated.com">
                  support@convexintegrated.com
                </a>
              </li>
              <span className="mt-4 flex flex-col gap-4">
                <p className="font-bold">Locations:</p>
                <ul className="flex flex-col gap-2">
                  <li>
                    6th Floor, Point House, 6 Front Street, Hamilton, HM 11,
                    Bermuda.
                  </li>
                  <li>Glategny Esplanade, Guernsey, GY1 1WR, Guernsey.</li>
                  <li>
                    37 Boulevard Joseph II, 2ème étage, Luxembourg City, L-1840,
                    Grand-Duchy of Luxembourg.
                  </li>
                  <li>Lime Street, London, EC3M 7AF, UK.</li>
                </ul>
              </span>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Alt;
