import CardGrid from './card.grid';
import CardDisplay from './cards';
import LatestTransactionTable from './lates.transaction';
import PlanCardsContainer from './plancontainer';
import CurrencyStrengthChart from './tradingview';
import AssetManagementSection from './assesment';

import TradingChart from './tradingview';
import MapComponent from '../map';

export default function MainSection() {
  return (
    <main className="py-10 overflow-hidden  mb-11">
      <aside className="flex items-center px-6 gap-4 justify-between w-70 text-2xl sm:text-4xl  py-4">
        <span className="font-bold text-gray-600">*********</span>
        <div className="border-b-8 border-slate-700 flex-1 ml-4"></div>
      </aside>
      <h2 className="capitalize px-6 mt-4 font-bold text-lg  sm:text-3xl leading-7 sm:leading-10 w-90">
        OUR ADVISORS CONNECT YOUR FINANCES TO WHAT YOU WANT OUT OF LIFE AND
        CREATE A PLAN DESIGNED TO MAKE IT HAPPEN, REVEALING POSSIBILITIES WHILE
        PROTECTING YOU FROM THE UNEXPECTED—TODAY AND EVERY DAY AFTER.
      </h2>
      <div className="px-6">
        <ul className="list-disc  mb-8 mt-6 px-6 text-base sm:text-lg text-gray-600">
          <li>
            A personalized plan that brings all aspects of your financial life
            together
          </li>
          <li>
            A strategic mix of insurance and investments working together for
            your goals
          </li>
          <li>
            Your go-to financial expert who helps keep your big picture, and
            dreams, in focus
          </li>
        </ul>
        <a
          href="/verify"
          alt="certificate"
          className="bg-blue-600 mt-8 mb-8 outline-none uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
          view Certification of Incoporation
        </a>
      </div>
      <CardDisplay />

      <div className="flex sm:flex-rowflex-col py-10 mt-16 px-6 gap-4 overflow-hidden">
        <div className="flex-shrink-0 w-full lg:w-1/2 min-w-[320px]">
          <span className="flex flex-col gap-4 justify-center">
            <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
              <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
                <i className="fas fa-check"></i>
              </span>
              <h1 className="uppercase w-full lg:w-60 leading-7 lg:leading-9 text-lg sm:text-2xl font-bold border-b-4 border-blue-800">
                INVESTOR RELATIONS
              </h1>
            </div>
            <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
              Convex integrated provides advanced investment strategies and
              wealth management solutions to forward-thinking investors around
              the world. Through its distinct investment brands Convex
              Integrated Management, we offers a diversity of investment
              approaches, encompassing bottom-up fundamental active management,
              Responsible Investing, systematic investing and customized
              implementation of client-specified portfolio exposures. Exemplary
              service, timely innovation and attractive returns across market
              cycles have been hallmarks of Convex Integrated since the origin.
            </p>
          </span>
          <hr className="my-4 border-t-2" />
          <span className="flex flex-col py-6 gap-4 justify-center">
            <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
              <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
                <i className="fas fa-check"></i>
              </span>
              <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-lg sm:text-2xl font-bold border-b-4 border-blue-800">
                OUR DIVERSITY & INCLUSION STRATEGY
              </h1>
            </div>
            <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
              At Convex Integrated, we want every person to have the opportunity
              to succeed based on merit, regardless of race, color, religion,
              creed, ancestry, national origin, sex, age, disability, marital
              status, citizenship status, sexual orientation, gender identity
              expression, military or veteran status, or any other criterion.
              Why is this so important? To us, diverse and inclusive teams
              enriched with people of distinctive backgrounds make us better.
              They help us generate better ideas, reach more balanced decisions,
              engage our communities and help our clients achieve better
              outcomes.
            </p>
          </span>
        </div>
        <div className="flex-shrink-0 w-full lg:w-1/2 min-w-[320px]">
          <img
            alt="pic1"
            src="../img/pic1.jpg"
            className="w-full h-auto"
          />
        </div>
      </div>

      <hr className="border-t-4 border-gray-300" />

      <div className="flex sm:flex-row flex-col-reverse px-6 lg:flex-row items-center gap-4 py-10">
        <div className="w-full lg:w-1/2 flex justify-center">
          <span className="w-80 h-40 block bg-black"></span>
        </div>

        <div className="w-full lg:w-1/2 flex flex-col gap-4 items-start">
          <div className="flex items-center mb-4 mt-6 gap-4">
            <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
              <i className="fas fa-check"></i>
            </span>
            <h1 className="uppercase w-full lg:w-80 text-lg sm:text-2xl font-bold border-b-4 border-blue-800">
              FINANCIAL PLANNING
            </h1>
          </div>
          <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 lg:leading-8">
            These days, it's more important than ever to have a plan. Our
            version of financial planning not only gives you the confidence to
            know you're ready for anything, but is also designed to help you
            reach all your goals in the days ahead.
          </p>
          <a
            href="/financial-planning"
            alt="certificate"
            className="bg-blue-600 mt-8 mb-8 outline-none uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
            Learn More
          </a>
        </div>
      </div>

      <hr />
      <CardGrid />
      <hr />
      <TradingChart />
      {/* <CurrencyStrengthChart /> */}
      <div className="mt-11 pt-11 mb-10">
        <hr />
      </div>
      <LatestTransactionTable />
      <div className="mt-11 pt-11 mb-10">
        <hr />
      </div>

      {/* <div className=" flex flex-wrap py-2 sm:py-10 mt-16 px-6 gap-4 overflow-hidden">
        <div className="flex-shrink-0 w-full flex-col gap-4 lg:w-1/2 min-w-[320px]">
          <span className="flex flex-col gap-4 justify-center">
            <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
              <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
                <i className="fas fa-check"></i>
              </span>

              <span className="flex flex-col gap-3">
                <p className="uppercase text-gray-500 text-sm sm:text-base">
                  OUR ASSET MANAGEMENT SOLUTIONS
                </p>
                <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-lg sm:text-2xl font-bold border-b-4 border-blue-800">
                  PROFESSIONALLY MANAGED INVESTMENT PORTFOLIOS
                </h1>
              </span>
            </div>
            <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
              Time is a precious commodity. Researching investments in
              ever-changing markets and handling investment transactions are
              more than most people have time for. Convex Integrated's Asset
              Management Solutions program allows you to delegate the daily
              management of your assets and invest with confidence, knowing that
              your portfolio is in the hands of experienced professionals.
            </p>
          </span>
          <hr className="my-4 border-t-2" />
          <span className="flex flex-col py-6 gap-4 justify-center">
            <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
              <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
                <i className="fas fa-check"></i>
              </span>
              <span className="flex flex-col gap-3">
                <p className="uppercase text-gray-500 text-sm sm:text-base">
                  OUR APPROACH TO ASSET MANAGEMENT
                </p>
                <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-lg sm:text-2xl font-bold border-b-4 border-blue-800">
                  DIFFERENT GOALS REQUIRE DIFFERENT APPROACHES.
                </h1>
              </span>
            </div>
            <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
              At Convex Integrated we recognize that each investor is unique.
              That’s why we take a personalized approach to developing an asset
              management strategy by selecting investment portfolios that
              closely match your goals, tolerance for risk, and expectation for
              returns.
            </p>
          </span>
        </div>
        <div className="flex-shrink-0 w-full lg:w-1/2 min-w-[320px]">
          <img
            alt="pic1"
            src="../img/calculator.jpg"
            className="w-full h-auto"
          />
        </div>
      </div> */}
      <AssetManagementSection />

      <div className="flex-col sm:flex-row flex px-6 items-center gap-1 sm:gap-4">
        <span className="flex flex-col py-6 gap-4 w-full lg:w-1/2 justify-center">
          <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
            <span className="flex flex-col gap-3">
              <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-sm sm:text-xl font-bold border-b-4 border-blue-800">
                TO PROVIDE YOU ADDITIONAL VALUE, WE STRIVE TO:
              </h1>
            </span>
          </div>

          <ul className="text-xs sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
            <li>Create opportunities for rewards while managing risk.</li>
            <li>Minimize management and administrative costs.</li>
            <li>
              Provide ongoing services that adapt to changes in your goals.
            </li>
          </ul>
        </span>

        <span className="flex flex-col py-6 gap-4 w-full lg:w-1/2 justify-center">
          <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
            <span className="flex flex-col gap-3">
              <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-sm sm:text-xl font-bold border-b-4 border-blue-800">
                WEALTH PLANNING
              </h1>
            </span>
          </div>

          <p className="text-xs sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
            A solid Wealth Plan ensures you have a financial strategy that
            supports your aspirations. Once we understand your lifestyle goals,
            we look at the current path of your finances to ensure that you are
            on track to meet them through retirement and beyond.
          </p>
        </span>
      </div>

      <hr />
      <PlanCardsContainer />

      {/* <div className="bg-gray-300 py-8 px-6 sm:px-10">
        <h1 className="mx-auto my-6 text-center text-lg sm:text-xl font-bold border-b-4 border-blue-700">
          OUR VALUES
        </h1>
        <div className="flex flex-col sm:flex-row gap-6 items-start justify-center">
          <div className="flex flex-col items-start gap-4 sm:w-1/4">
            <h2 className="text-center text-xs py-2 sm:text-xl font-bold border-b-4 border-blue-700">
              Highly Ambitious
            </h2>
            <p className="text-gray-500 text-center">
              We strive to build world-class businesses to generate superior
              returns for our partners.
            </p>
          </div>
          <div className="flex flex-col items-start gap-4 sm:w-1/4">
            <h2 className="text-center text-xs py-2 sm:text-xl font-bold border-b-4 border-blue-700">
              Winning
            </h2>
            <p className="text-gray-500 text-center">
              We are here to win. We are constantly improving, and are committed
              to out-thinking and out-executing our competitors. We take on what
              others dismiss as impossible, and solve the hard problems that
              others walk away from. This is why we hire the best.
            </p>
          </div>
          <div className="flex flex-col items-start gap-4 sm:w-1/4">
            <h2 className="text-center text-xs py-2 sm:text-xl font-bold border-b-4 border-blue-700">
              Integrity
            </h2>
            <p className="text-gray-500 text-center">
              We do things the right way, without compromise, the first time –
              every time. We are direct, decisive and, above all, accountable.
              We practice sound judgment and common sense in our actions that
              conforms to the letter and spirit of the law at all times. We win
              on the merits, with integrity.
            </p>
          </div>
          <div className="flex flex-col items-start gap-4 sm:w-1/4">
            <h2 className="text-center text-xs py-2 sm:text-xl font-bold border-b-4 border-blue-700">
              Learning
            </h2>
            <p className="text-gray-500 text-center">
              We are driven by a thirst for knowledge. We are constantly
              learning – from each other and from inspired thinkers around the
              world. We passionately pursue new ideas, new innovations and new
              strategies that will strengthen our competitive advantage.
            </p>
          </div>
        </div>
      </div> */}

      <div className="bg-gray-200 py-12 px-8 sm:px-12">
        <h1 className="text-center text-2xl sm:text-3xl font-bold text-gray-800 mb-8 border-b-4 border-blue-700 inline-block pb-2">
          OUR VALUES
        </h1>
        <div className="flex flex-col sm:flex-row gap-8 items-start justify-center">
          <div className="flex flex-col items-start gap-4 sm:w-1/4 text-center">
            <h2 className="text-lg sm:text-xl font-semibold text-blue-700 border-b-4 border-blue-700 pb-2">
              Highly Ambitious
            </h2>
            <p className="text-gray-600 text-base">
              We strive to build world-class businesses to generate superior
              returns for our partners.
            </p>
          </div>
          <div className="flex flex-col items-start gap-4 sm:w-1/4 text-center">
            <h2 className="text-lg sm:text-xl font-semibold text-blue-700 border-b-4 border-blue-700 pb-2">
              Winning
            </h2>
            <p className="text-gray-600 text-base">
              We are here to win. We are constantly improving, and are committed
              to out-thinking and out-executing our competitors. We take on what
              others dismiss as impossible, and solve the hard problems that
              others walk away from. This is why we hire the best.
            </p>
          </div>
          <div className="flex flex-col items-start gap-4 sm:w-1/4 text-center">
            <h2 className="text-lg sm:text-xl font-semibold text-blue-700 border-b-4 border-blue-700 pb-2">
              Integrity
            </h2>
            <p className="text-gray-600 text-base">
              We do things the right way, without compromise, the first time –
              every time. We are direct, decisive and, above all, accountable.
              We practice sound judgment and common sense in our actions that
              conforms to the letter and spirit of the law at all times. We win
              on the merits, with integrity.
            </p>
          </div>
          <div className="flex flex-col items-start gap-4 sm:w-1/4 text-center">
            <h2 className="text-lg sm:text-xl font-semibold text-blue-700 border-b-4 border-blue-700 pb-2">
              Learning
            </h2>
            <p className="text-gray-600 text-base">
              We are driven by a thirst for knowledge. We are constantly
              learning – from each other and from inspired thinkers around the
              world. We passionately pursue new ideas, new innovations and new
              strategies that will strengthen our competitive advantage.
            </p>
          </div>
        </div>
      </div>

      <MapComponent />
      {/* <div className="bg-gray-300 px-6 py-6 flex-wrap flex gap-6 items-center mt-10 h-auto w-full text-xs sm:text-sm">
        <div className="w-1/3">
          <img
            alt="a man"
            src="../img/man.jpg"
          />
        </div>
        <span className="w-1/2 flex flex-col gap-2 items-start justify-start">
          <h1 className=" md:text-6xl font-bold w-90 mx-auto text-4xl">
            ACTIVE MANAGEMENT ACROSS ASSET CLASSES
          </h1>
          <div className="flex items-center text-xs sm:text-xl gap-6">
            <p>
              <b className="text-4xl font-thin">C</b>onvex Integrated offers
              regional and global high-active-share equities, fixed income
              across the yield curve, liquidity solutions backed by four decades
              of experience as a core capability and, in private markets, real
            </p>
            <p>
              estate, infrastructure, private equity and private debt. Beyond
              investment management, Convex Integrated provides engagement in equity
              and bond markets, proxy voting and policy advocacy.
            </p>
          </div>
        </span>
      </div> */}
      <div className="bg-gray-300 px-6 py-6 flex sm:flex-row flex-col gap-6 items-center mt-10 text-xs sm:text-sm">
        {/* Image Section */}
        <div className="w-full sm:w-1/4">
          <img
            alt="a man"
            src="../img/man.jpg"
            className="w-full h-auto"
          />
        </div>

        {/* Text Section */}
        <span className="w-full sm:w-2/3 flex flex-col gap-2 items-start justify-start">
          <h1 className="text-2xl sm:text-3xl font-bold">
            ACTIVE MANAGEMENT ACROSS ASSET CLASSES
          </h1>
          <div className="flex flex-col gap-6 text-xs sm:text-base">
            <p>
              <b className="text-2xl sm:text-4xl font-thin">C</b>onvex
              Integrated offers regional and global high-active-share equities,
              fixed income across the yield curve, liquidity solutions backed by
              four decades of experience as a core capability and, in private
              markets, real estate, infrastructure, private equity and private
              debt. Beyond investment management, Convex Integrated provides
              engagement in equity and bond markets, proxy voting and policy
              advocacy.
            </p>
          </div>
        </span>
      </div>
    </main>
  );
}
