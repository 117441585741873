import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home.pages';
import LoginPage from './pages/Login.pages';
import SignUpPage from './pages/signup.pages';
import ResetTokenPage from './pages/passwordToken';
import ResetPasswordPage from './pages/resetpassword';
import DashboardPage from './pages/dashobord';
import DashboardComp from './component/dashcomponent.comp';
import FundAccount from './component/fund account';
import DepositHistory from './component/seeallDeposit';
import AddWallet from './component/add-wallet';
import ConnectedWallets from './component/connected-wallet';
import InvestmentComponent from './component/new-investment';
import SeeInvestments from './component/my investment';
import AllTransactions from './component/getallTx';
import WithdrawalRequest from './component/requestwithdrawal';
import AllWithdrawals from './component/view-withdrawal';
import SupportTicket from './component/support ticket';
import ViewTickets from './component/view-ticket';
import UserUpdatePage from './component/update-user';
import UserProfilePage from './component/viewprofile';
import ProtectedRoute from './component/protected.route';
import AdminLogin from './pages/adminLogin';
import ProtectedAdminRoute from './pages/protectedadminRoutes';
import AdminDashboardPage from './pages/adminDashboard';
import SeeAllWithdrawals from './component/admin/approve-withdrawal';
import SeeAllDeposits from './component/admin/approve-deposit';
import PendingTransactions from './component/admin/pendingtx';
import ResolveTickets from './component/admin/pendingTicket';
import SeeAllUsers from './component/admin/seeAllUser';
import AccountList from './component/admin/acclist';

import RealEstate from './component/home/real-estate';
import Stock from './component/home/stock';
import Infrastructure from './component/home/infra';
import Forex from './component/home/forex';
import Crypto from './component/home/crypto';
import FixedIncome from './component/home/fixed';
import MultiAsset from './component/home/multi';
import RenewableEnergy from './component/home/renewable';
import Agric from './component/home/agric';
import Nfp from './component/home/nfp';
import FinPlan from './component/home/fin-planing';
import RetirePlan from './component/home/retire';
import PrivateWealth from './component/home/private';
import EstatePlan from './component/home/estate-plan';
import LongTermCare from './component/home/long-term';
import BusinessPlan from './component/home/bussiness';
import OilGas from './component/home/oil-gas';
import About from './component/home/about';
import Responsibility from './component/home/resp';
import Insight from './component/home/insight';
import ContactPage from './component/home/contact';
import Alt from './component/home/alternative';
import Asset from './component/home/asset';
import FaqPage from './pages/faq.pages';
import Voc from './pages/voc';
import NotFoundPage from './pages/404';
import VideoPlayer from './component/videocomp';
import DownloadPDF from './component/pdf';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*################ HOME ROUTES ###############*/}
        <Route
          path="/"
          element={<HomePage />}
        />

        <Route
          path="/real-estate"
          element={<RealEstate />}
        />
        <Route
          path="/stocks"
          element={<Stock />}
        />
        <Route
          path="/infrastructure"
          element={<Infrastructure />}
        />
        <Route
          path="/forex"
          element={<Forex />}
        />
        <Route
          path="/crypto"
          element={<Crypto />}
        />
        <Route
          path="/fixed-income"
          element={<FixedIncome />}
        />
        <Route
          path="/multi-asset"
          element={<MultiAsset />}
        />
        <Route
          path="/renewable-energy"
          element={<RenewableEnergy />}
        />
        <Route
          path="/agriculture"
          element={<Agric />}
        />
        <Route
          path="/nfp"
          element={<Nfp />}
        />
        <Route
          path="/financial-planning"
          element={<FinPlan />}
        />
        <Route
          path="/retirement-planning"
          element={<RetirePlan />}
        />
        <Route
          path="/private-wealth-management"
          element={<PrivateWealth />}
        />
        <Route
          path="/estate-planning"
          element={<EstatePlan />}
        />
        <Route
          path="/long-term-care"
          element={<LongTermCare />}
        />
        <Route
          path="/business-plan"
          element={<BusinessPlan />}
        />
        <Route
          path="/oil-&-gas"
          element={<OilGas />}
        />
        <Route
          path="/about-us"
          element={<About />}
        />
        <Route
          path="/faq"
          element={<FaqPage />}
        />
        <Route
          path="/responsibility"
          element={<Responsibility />}
        />
        <Route
          path="/blog"
          element={<Insight />}
        />
        <Route
          path="/contact"
          element={<ContactPage />}
        />
        <Route
          path="/alternative"
          element={<Alt />}
        />
        <Route
          path="/asset"
          element={<Asset />}
        />
        <Route
          path="/verify"
          element={<Voc />}
        />
        {/*################### USER ROUTES ##############*/}
        <Route
          path="login"
          element={<LoginPage />}
        />
        <Route
          path="forget-password"
          element={<ResetTokenPage />}
        />
        <Route
          path="reset-password"
          element={<ResetPasswordPage />}
        />
        <Route
          path="signUp"
          element={<SignUpPage />}
        />

        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }>
          <Route
            index
            element={<DashboardComp />}
          />
          <Route
            path="learn"
            element={
              <VideoPlayer
                src={'../img/cvx-vid.mp4'}
                title={'Stay up to speed with the basic'}
                poster={'../img/convex-log.jpg'}
              />
            }
          />
          <Route
            path="pdf"
            element={
              <DownloadPDF
                fileUrl="https://heyzine.com/flip-book/d8a35ec34d.html"
                fileName="Convex Integrated"
              />
            }
          />
          <Route
            path="deposit"
            element={<FundAccount />}
          />
          <Route
            path="deposit-history"
            element={<DepositHistory />}
          />
          <Route
            path="add"
            element={<AddWallet />}
          />
          <Route
            path="all-wallet"
            element={<ConnectedWallets />}
          />
          <Route
            path="new-investment"
            element={<InvestmentComponent />}
          />
          <Route
            path="my-investment"
            element={<SeeInvestments />}
          />
          <Route
            path="my-tx"
            element={<AllTransactions />}
          />
          <Route
            path="request-withdrawal"
            element={<WithdrawalRequest />}
          />
          <Route
            path="see-withdrawal"
            element={<AllWithdrawals />}
          />
          <Route
            path="support"
            element={<SupportTicket />}
          />
          <Route
            path="allTickets"
            element={<ViewTickets />}
          />
          <Route
            path="updateMe"
            element={<UserUpdatePage />}
          />
          <Route
            path="viewMe"
            element={<UserProfilePage />}
          />
        </Route>

        {/* ###################ADMIN ROUTES########################### */}
        <Route
          path="/admin/login"
          element={<AdminLogin />}
        />

        <Route
          path="/admin-dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }>
          <Route
            path="all-withdrawal"
            element={<SeeAllWithdrawals />}
          />

          <Route
            path="all-deposits"
            element={<SeeAllDeposits />}
          />

          <Route
            index
            element={<PendingTransactions />}
          />
          <Route
            path="pending-tickets"
            element={<ResolveTickets />}
          />
          <Route
            path="see-users"
            element={<SeeAllUsers />}
          />
          <Route
            path="allAccounts"
            element={<AccountList />}
          />
        </Route>

        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
