import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { getCoordinates } from '../utils/geocode';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const addresses = [
  '6th Floor Point House 6 Front Street Hamilton, HM 11, Bermuda',
  '52 Lime Street, London, EC3M 7AF, UK',
  'Bucktrout House, Glategny Esplanade, Guernsey GY1 1WR, Guernsey',
  '37 Boulevard Joseph II, 2ème étage, L-1840 Luxembourg, Grand-Duchy of Luxembourg.',
];

const MapComponent = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      const coords = await Promise.all(
        addresses.map(async (address) => {
          try {
            const coord = await getCoordinates(address);
            return { address, ...coord };
          } catch (error) {
            console.error(`Error fetching coordinates for ${address}:`, error);
            return null;
          }
        })
      );
      setLocations(coords.filter((coord) => coord !== null));
    };

    fetchCoordinates();
  }, []);

  return (
    <div
      className="mt-8"
      style={{ height: '500px', width: '100%' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={3}
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location, idx) => (
          <Marker
            key={idx}
            position={[location.lat, location.lng]}>
            <Popup>{location.address}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
