import { TailSpin } from 'react-loader-spinner';
export default function Spinner() {
  return (
    <TailSpin
      visible={true}
      height="20"
      width="20"
      color="#EEEDEB"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
}
