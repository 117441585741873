import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function EstatePlan() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'ESTATE'}
        h1Blue={'PLANNING'}
        backgroundImage={'../img/estate-bg-2.jpg'}
      />
      <main>
        <div className="bg-gray-200 text-sm sm:text-lg px-11 py-11">
          <h1 className="text-gray-500 mb-4 font-bold text-3xl">
            What is estate planning?
          </h1>

          <p className="pb-11">
            Estate planning isn't just for people with a lot of land or wealth.
            It's all about making sure that if something happens to you, your
            family won't be burdened by speculating on what you may have wanted.
            It gives them the freedom to grieve and the comfort of knowing
            they're honoring your wishes. Having a plan for your estate — your
            home, your money, and your possessions — means you can continue to
            help your family reach goals you set together, even after you're
            gone. Your advisor will help you look at your investments, insurance
            policies, savings, and overall goals so you can create a plan
            designed to set your family up for financial success in the event of
            your passing.
          </p>
        </div>

        {/* <div className="py-11 my-11">
          <h1 className="text-center font-bold uppercase text-3xl my-4">
            OUR SERVICES, TAILORED TO YOU
          </h1>
          <hr className="w-1/2 mx-auto" />
          <div className="flex gap-6 items-center justify-center">
            <div class="text-gray-900 text-lg my-4">
              <ul class="list-disc ml-6">
                <li>Financial Planning</li>
                <li>Investment Planning & Strategies</li>
                <li>Advanced Portfolio Management</li>
                <li>Retirement Planning & Income Strategies</li>
                <li>Estate Planning & Trust Services</li>
                <li>Risk Management</li>
              </ul>
            </div>
            <div class="text-gray-900 text-lg">
              <ul class="list-disc ml-6">
                <li>Tax Efficient Strategies</li>
                <li>Advisor Coordination</li>
                <li>Business Planning & Succession</li>
                <li>Multigenerational Planning</li>
                <li>Charitable Planning</li>
                <li>Education Funding</li>
              </ul>
            </div>
          </div>
        </div> */}
      </main>
      <hr />
      <Footer />
    </div>
  );
}
