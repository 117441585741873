import { Link, useNavigate } from 'react-router-dom';
import UserAuthComp from '../component/usercomp.auth';
import AtSymbolIcon from '../icons/at.icon';
import PasswordIcon from '../icons/password.icon';
import LoginIcon from '../icons/login.icon';
import Spinner from '../component/spinner';
import { useAuth } from '../context/user.context';
import { useState } from 'react';
import Notify from '../component/toastify.comp';

export default function LoginPage() {
  const { isLoading, login } = useAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();
  async function handleLogin(e) {
    e.preventDefault();
    const isLoggedIn = await login(email, password);
    if (isLoggedIn) navigate('/dashboard');
  }
  return (
    <div>
      <UserAuthComp
        handler={handleLogin}
        type={'Login'}>
        <div className="flex flex-col gap-4">
          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <AtSymbolIcon />
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="youremail@gmail.com"
              className="bg-transparent bg-none text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <PasswordIcon />
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="*********"
              className="bg-transparent bg-none text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>
        </div>

        <div className=" flex-col sm:flex-row flex items-center text-slate-300 justify-between  mt-8">
          <span className="flex items-center mt-8 md:mt-0 gap-2">
            <p className="mr-2">Don't have an account?</p>
            <Link
              to="/signUp"
              className=" underline">
              Register
            </Link>
          </span>
          <Link
            to="/forget-password"
            className=" underline">
            Forget Password ?
          </Link>
        </div>
        <span className="px-4 mt-4 py-2 flex sm:w-60 items-center rounded-md gap-2 border border-gray-300 bg-slate-800 hover:bg-slate-700 transition-colors">
          <LoginIcon />
          {!isLoading && (
            <button className="outline-none text-slate-50">Login </button>
          )}
          {isLoading && <Spinner />}
        </span>
      </UserAuthComp>
      <Notify />
    </div>
  );
}
