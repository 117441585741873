import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BASEURLDEV } from '../constants/api';
import Notify from './toastify.comp';

function UserProfilePage() {
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    country: '',
    state: '',
    address: '',
    phone: '',
  });

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user profile data when the component mounts
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const token = Cookies.get('token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`${BASEURLDEV}/user/myProfile`, {
        headers,
      });
      setUserData(response.data.data.user);
    } catch (error) {
      console.error('Error fetching user profile:', error.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-start justify-center bg-darkBlue-900 py-12">
      <div className="bg-darkBlue-900 text-white rounded-lg shadow-lg w-full max-w-md mx-auto p-6">
        <div className="flex justify-between items-center border-b pb-3">
          <h3 className="text-lg font-semibold">My Profile</h3>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="mt-4">
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                First Name
              </label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.firstname}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                Last Name
              </label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.lastname}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Email</label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.email}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Country</label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.country}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">State</label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.state}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Address</label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.address}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Phone</label>
              <p className="w-full px-3 py-2 bg-gray-800 text-white border border-gray-700 rounded">
                {userData.phone}
              </p>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => navigate('/dashboard')}
                className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded">
                Back to Dashboard
              </button>
            </div>
          </div>
        )}
      </div>
      <Notify />
    </div>
  );
}

export default UserProfilePage;
