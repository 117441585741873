import axios from 'axios';

const API_KEY = 'a99fd175674d47b5aa9a3b08b74cfcfc';

export const getCoordinates = async (address) => {
  const response = await axios.get(
    `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      address
    )}&key=${API_KEY}`
  );
  if (response.data.results.length > 0) {
    const { lat, lng } = response.data.results[0].geometry;
    return { lat, lng };
  }
  throw new Error('No coordinates found for the address');
};
