import React from 'react';

const BlogPost = () => {
  const articles = [
    {
      image: '../img/wall-st.jpg',
      title:
        'Miami-Area Condo Board President Warned of Need for Repairs in April Letter',
      source: 'The Wall Street Journal',
      author: 'Deborah Acosta',
      content:
        '‘The concrete deterioration is accelerating. The roof situation got much worse,’ condo board leader told residents.',
      url: 'https://www.wsj.com/articles/miami-area-condo-board-president-warned-of-need-for-repairs-in-april-letter-11624783610',
    },
    {
      image: '../img/chart.jpg',
      title: "These charts show the dramatic increase in China's debt",
      source: 'CNBC',
      author: 'Yen Nee Lee',
      content:
        "China's debt pile is one of the biggest economic challenges confronting the ruling Chinese Communist Party that turns 100 this week.",
      url: 'https://www.cnbc.com/2021/06/28/china-debt-pile-dramatic-increase-in-charts.html',
    },
    {
      image: '../img/abc.jpg',

      title: 'Record shattering heat records across the country',
      source: 'ABC News',
      author: '',
      content: '',
      url: 'https://abcnews.go.com/US/record-shattering-heat-records-country/story?id=78519664',
    },
    {
      image: '../img/resturant.jpg',

      title:
        "Restaurants struggle as overworked freezers, coolers can't keep up in the heat",
      source: 'KATU',
      author: 'Megan Allison and KATU Staff',
      content:
        'Businesses around Portland struggled Monday to keep up with demand for supplies and employees safe. RELATED:Portland, once again, shatters all-time heat record on MondayAt least one Portland grocery store’s beverage section was cleared out of sports dri…',
      url: 'https://katu.com/news/local/restaurants-struggle-as-overworked-freezers-coolers-cant-keep-up-in-the-heat',
    },
    {
      image: '../img/bank.jpg',

      title: "Big banks hike dividends after passing Fed's stress test",
      source: 'Fox Business',
      author: 'FOXBusiness',
      content:
        'Several major American banks announced dividend boosts on Monday, days after the Federal Reserve said all big lenders passed their stress test and lifted the heightened restrictions imposed due to COVID-19.',
      url: 'https://www.foxbusiness.com/markets/big-banks-hike-dividends-after-passing-feds-stress-test',
    },
    {
      image: '../img/blackout.jpg',

      title:
        'Avista starts unplanned rolling blackouts due to strain on electrical system caused by heat wave',
      source: 'KHQ Right Now',
      author: '',
      content: 'UPDATE: JUNE 28 AT 4:50 PM',
      url: 'https://www.khq.com/news/avista-starts-unplanned-rolling-blackouts-due-to-strain-on-electrical-system-caused-by-heat-wave/article_4b8b2c52-d788-11eb-80f4-2b7d9de6c52a.html',
    },
    {
      image: '../img/blackout.jpg',

      title: 'Benton PUD announces rolling blackouts possible',
      source: 'KEPR 19',
      author: 'Thomas Yazwinski',
      content:
        'The combination of the extreme weather conditions and record-breaking peak loads in the Tri-Cities has put a strain on the area’s electric grid. Bonneville Power Administration (BPA) owns and operates a big portion of the Northwest’…',
      url: 'https://keprtv.com/news/local/benton-pud-announces-rolling-blackouts-possible',
    },
    {
      image: '../img/jap.jpg',

      title:
        "Japan's Nikkei 225 falls 1% as Asia-Pacific markets slip despite record high close for S&P 500",
      source: 'CNBC',
      author: 'Eustance Huang',
      content:
        'The S&P 500 and Nasdaq Composite both closed at record highs overnight on Wall Street.',
      url: 'https://www.cnbc.com/2021/06/29/asia-pacific-markets-nikkei-225-falls-despite-sp-500-record-close.html',
    },
    {
      image: '../img/retire-bg.jpg',

      title: "The UAW Wants To Believe It's Different This Time",
      source: 'Jalopnik',
      author: 'Erik Shilling',
      content:
        'Ray Curry will succeed Rory Gamble as UAW president, the union said Monday.',
      url: 'https://jalopnik.com/the-uaw-wants-to-believe-its-different-this-time-1847165121',
    },
    {
      image: '../img/plane.jpg',

      title: 'Passengers sit stuck on hot plane at Sea-Tac Airport',
      source: 'KING 5',
      author: '',
      content: '',
      url: 'https://www.king5.com/article/news/local/passengers-sit-stuck-on-hot-plane-at-sea-tac-airport/281-97d61c44-c730-448f-a7f4-4e3b4d04a526',
    },
    {
      image: '../img/covid.jpg',

      title:
        'Washington U study shows monthslong immunity after COVID-19 vaccines',
      source: 'STLtoday.com',
      author: 'Annika Merrilees',
      content:
        "This is evidence of a really robust immune response,' said Dr. Rachel Presti, co-senior author and associate professor of medicine, in a statement.",
      url: 'https://www.stltoday.com/news/local/metro/washington-u-study-shows-monthslong-immunity-after-covid-19-vaccines/article_47030b9f-32da-5c72-9f6a-4aab4742e865.html',
    },
    {
      image: '../img/bank.jpg',

      title:
        "Jim Cramer on Didi IPO: 'I would try to get as many shares as you can'",
      source: 'CNBC Television',
      author: '',
      content: '',
      url: 'https://www.cnbc.com/video/2021/06/29/jim-cramer-on-didi-ipo-i-would-try-to-get-as-many-shares-as-you-can.html',
    },
    {
      image: '../img/drug.jpg',

      title:
        "Controversial Alzheimer's drug could cost US $334B—nearly half of DoD budget",
      source: 'Ars Technica',
      author: 'Beth Mole',
      content:
        'Despite unproven efficacy, Biogen set the drug’s list price at $56,000 per year.',
      url: 'https://arstechnica.com/science/2021/06/controversial-alzheimers-drug-could-cost-us-334b-nearly-half-of-dod-budget/',
    },
    {
      image: '../img/stock.jpg',

      title:
        'Etsy CEO bets on e-commerce growth in Brazil with $217 million Elo7 deal',
      source: 'CNBC',
      author: 'Tyler Clifford',
      content:
        "The opportunity for Brazil's e-commerce sector to grow faster than the U.S. ... is very meaningful over time,' Etsy CEO Josh Silverman said of the $217M deal.",
      url: 'https://www.cnbc.com/2021/06/28/etsy-ceo-bets-on-e-commerce-growth-in-brazil-with-217-million-elo7-deal.html',
    },
    {
      image: '../img/bitcoin.jpg',

      title: 'Duolingo filed to go public',
      source: 'TechCrunch',
      author: 'Natasha Mascarenhas, Alex Wilhelm',
      content:
        'Duolingo, a Pittsburgh-based language learning business last valued at $2.4 billion, has officially filed to go public. The 400-person company, which we explored in great detail in our EC-1, was co-founded by Luis von Ahn, the inventor of CAPTCHA and reCAPTCH…',
      url: 'https://techcrunch.com/2021/06/28/duolingo-files-to-go-public/',
    },
    {
      image: '../img/chart.jpg',

      title: 'Tech stock rally sends S&P 500 and Nasdaq to record highs',
      source: 'Reuters',
      author: 'Medha Singh, Krystal Hu',
      content:
        'The Nasdaq and S&P 500 hit all-time highs on Monday, fueled by tech stocks as investors expect a robust earnings season while interest rates remain low.',
      url: 'https://www.reuters.com/markets/us/tech-stock-rally-sends-sp-500-nasdaq-record-highs-2021-06-28/',
    },
    {
      image: '../img/elon.jpg',

      title:
        'Bronze Elon Musk statue in downtown Manhattan unveiled to a wave of mockery',
      source: 'The Independent',
      author: 'Nathan Place',
      content:
        'Thanks for posting the location! I will be defacing this soon,” one Twitter user commented.',
      url: 'https://www.independent.co.uk/life-style/bronze-elon-musk-statue-downtown-manhattan-b1874432.html',
    },
    {
      image: '../img/lumber.jpg',

      title:
        'How the lumber industry misread Covid and ended up with a global shortage and sky-high prices',
      source: 'NBC News',
      author: 'Ben Popken',
      content:
        'A pandemic surge in home buying and renovation sent lumber prices soaring. Now they’re settling down to a new, but elevated level, according to analysts.',
      url: 'https://www.nbcnews.com/business/consumer/how-lumber-industry-misread-covid-ended-global-shortage-sky-high-n1272213',
    },
    {
      image: '../img/fb.jpg',

      title:
        'Facebook hits $1 trillion market cap after federal court dismisses FTC antitrust complaint',
      source: 'CNN',
      author: 'Brian Fung',
      content:
        "A federal court has dismissed the Federal Trade Commission's antitrust complaint against Facebook, saying the agency had failed to provide evidence for its claim that the social media giant holds a monopoly in social networking.",
      url: 'https://www.cnn.com/2021/06/28/tech/facebook-ftc-antitrust-dismissal/index.html',
    },
    {
      image: '../img/ford.jpg',

      title:
        "The 2021 Ford Bronco Solves One of the Jeep Wrangler's Biggest Problems",
      source: 'The Drive',
      author: 'Kristen Lee',
      content:
        "If you've ever taken the doors off a Wrangler, you'll know the wing mirrors go with them. This is not the case in the new Bronco.",
      url: 'https://www.thedrive.com/news/41319/the-2021-ford-bronco-solves-one-of-the-jeep-wranglers-biggest-problems',
    },
  ];

  return (
    // <div className="container mx-auto p-4">
    //   {articles.map((article, index) => (
    //     <div
    //       key={index}
    //       className="border-b py-4">
    //       <h2 className="text-xl font-bold">
    //         <a
    //           href={article.url}
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="text-blue-500 hover:underline">
    //           {article.title}
    //         </a>
    //       </h2>
    //       <p className="text-sm text-gray-600">{article.source}</p>
    //       <p className="text-sm text-gray-600">
    //         {article.author && (
    //           <a
    //             href={article.url}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             className="text-blue-500 hover:underline">
    //             {article.author}
    //           </a>
    //         )}
    //       </p>
    //       <p className="mt-2">{article.content}</p>
    //     </div>
    //   ))}
    // </div>
    <div className="container mx-auto p-4">
      {articles.map((article, index) => (
        <div
          key={index}
          className="border-b py-4 flex">
          <img
            src={article.image}
            alt={article.title}
            className="w-1/4 max-h-20 mr-4"
          />
          <div>
            <h2 className="text-sm font-semibold">
              <a
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline">
                {article.title}
              </a>
            </h2>
            <p className="text-xs text-gray-600">{article.source}</p>
            <p className="text-xs text-gray-600">
              {article.author && (
                <a
                  href={article.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline">
                  {article.author}
                </a>
              )}
            </p>
            <p className="mt-2 text-xs">{article.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPost;
