// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BASEURLDEV } from '../../constants/api';
// import Cookies from 'js-cookie';

// const ResolveTickets = () => {
//   const [tickets, setTickets] = useState([]);
//   const [message, setMessage] = useState('');

//   useEffect(() => {
//     const fetchTickets = async () => {
//       try {
//         const token = Cookies.get('adminToken');
//         const response = await axios.get(
//           `${BASEURLDEV}/admin/pending-tickets`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         console.log(response);
//         setTickets(response.data.data);
//       } catch (error) {
//         setMessage(error.response?.data?.message || 'An error occurred');
//       }
//     };

//     fetchTickets();
//   }, []);

//   const handleResolve = async (ticketId) => {
//     try {
//       const token = Cookies.get('adminToken');
//       const response = await axios.post(
//         `${BASEURLDEV}/admin/resolve-ticket`,
//         { ticketId },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setMessage(response.data.message);
//       setTickets(tickets.filter((ticket) => ticket._id !== ticketId));
//     } catch (error) {
//       setMessage(error.response?.data?.message || 'An error occurred');
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 p-4">
//       <h1 className="text-2xl font-bold text-gray-800 mb-4">Resolve Tickets</h1>
//       {message && <p className="text-center text-red-500 mb-4">{message}</p>}
//       <ul className="space-y-4">
//         {tickets.map((ticket) => (
//           <li
//             key={ticket._id}
//             className="border p-4 rounded-lg bg-white shadow-md">
//             <h2 className="text-xl font-semibold text-gray-700">
//               {ticket.title}
//             </h2>
//             <p className="text-gray-600">{ticket.description}</p>
//             <p className="text-gray-500 mt-2">User Email: {ticket.userEmail}</p>
//             <button
//               onClick={() => handleResolve(ticket._id)}
//               className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300">
//               Resolve Ticket
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ResolveTickets;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../../constants/api';
import Cookies from 'js-cookie';
import { handleAdminServerError } from './handleAdminErr';

const ResolveTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const token = Cookies.get('adminToken');
        const response = await axios.get(
          `${BASEURLDEV}/admin/pending-tickets`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        setTickets(response.data.data);
      } catch (error) {
        setMessage(error.response?.data?.message || 'An error occurred');
        handleAdminServerError(
          error.response?.status,
          error.response?.data.message
        );
      }
    };

    fetchTickets();
  }, []);

  const handleResolve = async (ticketId) => {
    try {
      const token = Cookies.get('adminToken');
      const response = await axios.post(
        `${BASEURLDEV}/admin/resolve-ticket`,
        { ticketId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setTickets(tickets.filter((ticket) => ticket._id !== ticketId));
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Resolve Tickets</h1>
      {message && <p className="text-center text-red-500 mb-4">{message}</p>}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="py-2 px-4">Title</th>
              <th className="py-2 px-4">Description</th>
              <th className="py-2 px-4">User Email</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr
                key={ticket._id}
                className="border-t">
                <td className="py-2 px-4">{ticket.title}</td>
                <td className="py-2 px-4">{ticket.description}</td>
                <td className="py-2 px-4">{ticket.userEmail}</td>
                <td className="py-2 px-4">
                  <button
                    onClick={() => handleResolve(ticket._id)}
                    className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300">
                    Resolve Ticket
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResolveTickets;
