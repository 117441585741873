import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import { BASEURLDEV } from '../../constants/api';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { handleAdminServerError } from './handleAdminErr';

const SeeAllUsers = () => {
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = Cookies.get('adminToken');

    try {
      const response = await axios.get(`${BASEURLDEV}/admin/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error('error', error.response);
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    }
  };

  const loginAsUser = async (userId) => {
    const token = Cookies.get('adminToken');

    try {
      const response = await axios.post(
        `${BASEURLDEV}/admin/loginUsers`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      const { exp: expiresIn } = jwtDecode(response.data.token);

      localStorage.setItem('user', JSON.stringify(response.data.data));

      const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
      Cookies.set('token', response.data.token, {
        expires: expirationDate,
        secure: true,
        sameSite: 'strict',
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in as user', error.response);
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    }
  };

  //   const openModal = (user) => {
  //     console.log(user);
  //     setSelectedUser((prev) => setSelectedUser({ ...prev, user }));

  //     setIsOpen(true);
  //     console.log(selectedUser);
  //   };
  const openModal = (user) => {
    console.log('Opening modal with user:', user);
    setSelectedUser(user);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    console.log('Selected User updated:', selectedUser);
  }, [selectedUser]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-4">All Users</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="w-full bg-gray-100 border-b">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Phone
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Country
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                State
              </th>
              <th className="px-6 py-3 text-left text-xs  font-medium text-gray-500 uppercase tracking-wider">
                Wallet
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                key={user._id}
                className="border-b">
                <td className="px-6 py-4 text-sm font-medium text-gray-900 break-words">
                  {user.firstname} {user.lastname}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 break-words">
                  {user.email}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 break-words">
                  {user.phone}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 break-words">
                  {user.country}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 break-words">
                  {user.state}
                </td>
                <td className="px-6 py-4 text-sm text-clip text-wrap text-gray-500 break-words">
                  {user.wallet.join(', ')}
                </td>
                {/* <td className="px-6 py-4 text-sm text-clip text-gray-500 break-words truncate">
                  {user.wallet.join(', ')}
                </td> */}

                <td className="px-6 py-4 text-sm  text-gray-500">
                  <button
                    className="px-4 py-4 bg-blue-500 w-40 text-white rounded hover:bg-blue-700"
                    onClick={() => openModal(user)}>
                    Login as User
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Transition
        appear
        show={isOpen}
        as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900">
                    Confirm Login
                  </Dialog.Title>
                  <div className="mt-2">
                    {selectedUser && (
                      <>
                        <p className="text-sm text-gray-500">
                          Name: {selectedUser.firstname} {selectedUser.lastname}
                        </p>
                        <p className="text-sm text-gray-500">
                          Email: {selectedUser.email}
                        </p>
                        <p className="text-sm text-gray-500">
                          Phone: {selectedUser.phone}
                        </p>
                        <p className="text-sm text-gray-500">
                          Country: {selectedUser.country}
                        </p>
                        <p className="text-sm text-gray-500">
                          State: {selectedUser.state}
                        </p>
                        <p className="text-sm text-gray-500">
                          Wallet: {selectedUser.wallet.join(', ')}
                        </p>
                      </>
                    )}
                  </div>

                  <div className="mt-4 flex space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => loginAsUser(selectedUser?._id)}>
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                      onClick={closeModal}>
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SeeAllUsers;
