// import React, { useEffect, useState, Fragment } from 'react';
// import axios from 'axios';
// import { Dialog, Transition } from '@headlessui/react';
// import { BASEURLDEV } from '../../constants/api';
// import Cookies from 'js-cookie';
// import { useNavigate } from 'react-router-dom';

// const PendingTransactions = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [selectedTransaction, setSelectedTransaction] = useState(null);
//   const [isOpen, setIsOpen] = useState(false);
//   const [loadingTransactionId, setLoadingTransactionId] = useState(null); // Add state for loading
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchTransactions();
//   }, []);

//   const fetchTransactions = async () => {
//     const token = Cookies.get('adminToken');

//     try {
//       const response = await axios.get(`${BASEURLDEV}/admin/pendingtx`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log(response);
//       setTransactions(response.data.data);
//     } catch (error) {
//       console.error('Error fetching transactions', error.response);
//     }
//   };

//   const approveTransaction = async (transactionId) => {
//     const token = Cookies.get('adminToken');
//     setLoadingTransactionId(transactionId); // Set loading state

//     try {
//       await axios.post(
//         `${BASEURLDEV}/admin/approve-transaction`,
//         { transactionId },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setTransactions(
//         transactions.filter((t) => t.transactionId !== transactionId)
//       );
//       closeModal();
//     } catch (error) {
//       console.error('Error approving transaction', error.response);
//     } finally {
//       setLoadingTransactionId(null); // Reset loading state
//     }
//   };

//   const openModal = (transaction) => {
//     setSelectedTransaction(transaction);
//     setIsOpen(true);
//   };

//   const closeModal = () => {
//     setIsOpen(false);
//     setSelectedTransaction(null);
//   };

//   const getTypeColor = (type) => {
//     switch (type) {
//       case 'credit':
//         return 'text-green-600';
//       case 'debit':
//         return 'text-red-600';
//       default:
//         return 'text-gray-600';
//     }
//   };

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'pending':
//         return 'text-yellow-600';
//       case 'successful':
//         return 'text-green-600';
//       case 'failed':
//         return 'text-red-600';
//       default:
//         return 'text-gray-600';
//     }
//   };

//   const formatTypeText = (type) => {
//     switch (type) {
//       case 'credit':
//         return 'Deposit';
//       case 'debit':
//         return 'Withdrawal';
//       default:
//         return type;
//     }
//   };

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <h1 className="text-3xl font-bold mb-4">Pending Transactions</h1>
//       <div className="overflow-x-auto">
//         <table className="min-w-full bg-white">
//           <thead>
//             <tr>
//               <th className="py-2 px-4 border-b border-gray-200">Type</th>
//               <th className="py-2 px-4 border-b border-gray-200">Amount</th>
//               <th className="py-2 px-4 border-b border-gray-200">Status</th>
//               <th className="py-2 px-4 border-b border-gray-200">User ID</th>
//               <th className="py-2 px-4 border-b border-gray-200">
//                 Transaction ID
//               </th>
//               <th className="py-2 px-4 border-b border-gray-200">Date</th>
//               <th className="py-2 px-4 border-b border-gray-200">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {transactions.map((transaction) => (
//               <tr key={transaction.transactionId}>
//                 <td
//                   className={`py-2 px-4 border-b border-gray-200 ${getTypeColor(
//                     transaction.type
//                   )}`}>
//                   {formatTypeText(transaction.type).toUpperCase()}
//                 </td>
//                 <td className="py-2 px-4 border-b border-gray-200">
//                   ${transaction.amount}
//                 </td>
//                 <td
//                   className={`py-2 px-4 border-b border-gray-200 ${getStatusColor(
//                     transaction.status
//                   )}`}>
//                   {transaction.status}
//                 </td>
//                 <td className="py-2 px-4 border-b border-gray-200">
//                   {transaction.userId}
//                 </td>
//                 <td className="py-2 px-4 border-b border-gray-200">
//                   {transaction.transactionId}
//                 </td>
//                 <td className="py-2 px-4 border-b border-gray-200">
//                   {new Date(transaction.createdAt).toLocaleString()}
//                 </td>
//                 <td className="py-2 px-4 border-b border-gray-200">
//                   <button
//                     className={`px-4 py-2 rounded ${
//                       loadingTransactionId === transaction.transactionId
//                         ? 'bg-blue-300 text-white cursor-not-allowed'
//                         : 'bg-blue-500 text-white hover:bg-blue-700'
//                     }`}
//                     onClick={() => openModal(transaction)}
//                     disabled={
//                       loadingTransactionId === transaction.transactionId
//                     }>
//                     {loadingTransactionId === transaction.transactionId
//                       ? 'Processing...'
//                       : 'View Details'}
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <Transition
//         appear
//         show={isOpen}
//         as={Fragment}>
//         <Dialog
//           as="div"
//           className="relative z-10"
//           onClose={closeModal}>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0">
//             <div className="fixed inset-0 bg-black bg-opacity-25" />
//           </Transition.Child>

//           <div className="fixed inset-0 overflow-y-auto">
//             <div className="flex items-center justify-center min-h-full p-4 text-center">
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-out duration-300"
//                 enterFrom="opacity-0 scale-95"
//                 enterTo="opacity-100 scale-100"
//                 leave="ease-in duration-200"
//                 leaveFrom="opacity-100 scale-100"
//                 leaveTo="opacity-0 scale-95">
//                 <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
//                   <Dialog.Title
//                     as="h3"
//                     className="text-lg font-medium leading-6 text-gray-900">
//                     Transaction Details
//                   </Dialog.Title>
//                   <div className="mt-2">
//                     {selectedTransaction && (
//                       <>
//                         <p className="text-sm text-gray-500">
//                           Type:{' '}
//                           {formatTypeText(
//                             selectedTransaction.type
//                           ).toUpperCase()}
//                         </p>
//                         <p className="text-sm text-gray-500">
//                           Amount: ${selectedTransaction.amount}
//                         </p>
//                         <p className="text-sm text-gray-500">
//                           Status: {selectedTransaction.status}
//                         </p>
//                         <p className="text-sm text-gray-500">
//                           User ID: {selectedTransaction.userId}
//                         </p>
//                         <p className="text-sm text-gray-500">
//                           Transaction ID: {selectedTransaction.transactionId}
//                         </p>
//                         <p className="text-sm text-gray-500">
//                           Date:{' '}
//                           {new Date(
//                             selectedTransaction.createdAt
//                           ).toLocaleString()}
//                         </p>
//                       </>
//                     )}
//                   </div>

//                   <div className="mt-4 flex space-x-2">
//                     <button
//                       type="button"
//                       className={`inline-flex justify-center px-4 py-2 text-sm font-medium rounded-md ${
//                         loadingTransactionId ===
//                         selectedTransaction?.transactionId
//                           ? 'bg-blue-300 text-white cursor-not-allowed'
//                           : 'bg-blue-500 text-white hover:bg-blue-700'
//                       }`}
//                       onClick={() =>
//                         approveTransaction(selectedTransaction?.transactionId)
//                       }
//                       disabled={
//                         loadingTransactionId ===
//                         selectedTransaction?.transactionId
//                       }>
//                       {loadingTransactionId ===
//                       selectedTransaction?.transactionId
//                         ? 'Processing...'
//                         : 'Approve Transaction'}
//                     </button>
//                     <button
//                       type="button"
//                       className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//                       onClick={closeModal}>
//                       Close
//                     </button>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog>
//       </Transition>
//     </div>
//   );
// };

// export default PendingTransactions;

import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import { BASEURLDEV } from '../../constants/api';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { handleAdminServerError } from './handleAdminErr';

const PendingTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingTransactionId, setLoadingTransactionId] = useState(null); // State for loading
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    const token = Cookies.get('adminToken');

    try {
      const response = await axios.get(`${BASEURLDEV}/admin/pendingtx`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setTransactions(response.data.data);
    } catch (error) {
      console.error('Error fetching transactions', error.response);
    }
  };

  const approveTransaction = async (transactionId) => {
    const token = Cookies.get('adminToken');
    setLoadingTransactionId(transactionId); // Set loading state

    try {
      await axios.post(
        `${BASEURLDEV}/admin/approve-transaction`,
        { transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactions(
        transactions.filter((t) => t.transactionId !== transactionId)
      );
      closeModal();
    } catch (error) {
      console.error('Error approving transaction', error.response);
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    } finally {
      setLoadingTransactionId(null); // Reset loading state
    }
  };

  const deleteTransaction = async (transactionId, amount) => {
    const token = Cookies.get('adminToken');
    setLoadingTransactionId(transactionId); // Set loading state

    try {
      await axios.delete(`${BASEURLDEV}/admin/transactions/${transactionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { transactionId, amount },
      });
      setTransactions(
        transactions.filter((t) => t.transactionId !== transactionId)
      );
      closeModal();
    } catch (error) {
      console.error('Error deleting transaction', error.response);
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    } finally {
      setLoadingTransactionId(null); // Reset loading state
    }
  };

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedTransaction(null);
  };

  const getTypeColor = (type) => {
    switch (type) {
      case 'credit':
        return 'text-green-600';
      case 'debit':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-600';
      case 'successful':
        return 'text-green-600';
      case 'failed':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  const formatTypeText = (type) => {
    switch (type) {
      case 'credit':
        return 'Deposit';
      case 'debit':
        return 'Withdrawal';
      default:
        return type;
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-4">Pending Transactions</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200">Type</th>
              <th className="py-2 px-4 border-b border-gray-200">Amount</th>
              <th className="py-2 px-4 border-b border-gray-200">Status</th>
              <th className="py-2 px-4 border-b border-gray-200">User ID</th>
              <th className="py-2 px-4 border-b border-gray-200">Wallet</th>

              <th className="py-2 px-4 border-b border-gray-200">
                Transaction ID
              </th>
              <th className="py-2 px-4 border-b border-gray-200">Date</th>
              <th className="py-2 px-4 border-b border-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.transactionId}>
                <td
                  className={`py-2 px-4 border-b border-gray-200 ${getTypeColor(
                    transaction.type
                  )}`}>
                  {formatTypeText(transaction.type).toUpperCase()}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  ${transaction.amount}
                </td>
                <td
                  className={`py-2 px-4 border-b border-gray-200 ${getStatusColor(
                    transaction.status
                  )}`}>
                  {transaction.status}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {transaction.userId}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {transaction.wallet[0]}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {transaction.transactionId}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {new Date(transaction.createdAt).toLocaleString()}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  <button
                    className={`px-4 py-2 rounded ${
                      loadingTransactionId === transaction.transactionId
                        ? 'bg-blue-300 text-white cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-700'
                    }`}
                    onClick={() => openModal(transaction)}
                    disabled={
                      loadingTransactionId === transaction.transactionId
                    }>
                    {loadingTransactionId === transaction.transactionId
                      ? 'Processing...'
                      : 'View Details'}
                  </button>
                  {transaction.type === 'debit' && (
                    <button
                      className={`px-4 py-2 rounded ml-2 ${
                        loadingTransactionId === transaction.transactionId
                          ? 'bg-red-300 text-white cursor-not-allowed'
                          : 'bg-red-500 text-white hover:bg-red-700'
                      }`}
                      onClick={() => openModal(transaction)}
                      disabled={
                        loadingTransactionId === transaction.transactionId
                      }>
                      {loadingTransactionId === transaction.transactionId
                        ? 'Processing...'
                        : 'Delete'}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Transition
        appear
        show={isOpen}
        as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900">
                    Transaction Details
                  </Dialog.Title>
                  <div className="mt-2">
                    {selectedTransaction && (
                      <>
                        <p className="text-sm text-gray-500">
                          Type:{' '}
                          {formatTypeText(
                            selectedTransaction.type
                          ).toUpperCase()}
                        </p>
                        <p className="text-sm text-gray-500">
                          Amount: ${selectedTransaction.amount}
                        </p>
                        <p className="text-sm text-gray-500">
                          Status: {selectedTransaction.status}
                        </p>
                        <p className="text-sm text-gray-500">
                          User ID: {selectedTransaction.userId}
                        </p>
                        <p className="text-sm text-gray-500">
                          Wallet: {selectedTransaction.wallet[0]}
                        </p>
                        <p className="text-sm text-gray-500">
                          Transaction ID: {selectedTransaction.transactionId}
                        </p>
                        <p className="text-sm text-gray-500">
                          Date:{' '}
                          {new Date(
                            selectedTransaction.createdAt
                          ).toLocaleString()}
                        </p>
                      </>
                    )}
                  </div>

                  <div className="mt-4 flex space-x-2">
                    <button
                      type="button"
                      className={`inline-flex justify-center px-4 py-2 text-sm font-medium rounded-md ${
                        loadingTransactionId ===
                        selectedTransaction?.transactionId
                          ? 'bg-blue-300 text-white cursor-not-allowed'
                          : 'bg-blue-500 text-white hover:bg-blue-700'
                      }`}
                      onClick={() =>
                        approveTransaction(selectedTransaction?.transactionId)
                      }
                      disabled={
                        loadingTransactionId ===
                        selectedTransaction?.transactionId
                      }>
                      {loadingTransactionId ===
                      selectedTransaction?.transactionId
                        ? 'Processing...'
                        : 'Approve Transaction'}
                    </button>
                    <button
                      type="button"
                      className={`inline-flex justify-center px-4 py-2 text-sm font-medium rounded-md ${
                        loadingTransactionId ===
                        selectedTransaction?.transactionId
                          ? 'bg-red-300 text-white cursor-not-allowed'
                          : 'bg-red-500 text-white hover:bg-red-700'
                      }`}
                      onClick={() =>
                        deleteTransaction(
                          selectedTransaction?.transactionId,
                          Number(selectedTransaction?.amount)
                        )
                      }
                      disabled={
                        loadingTransactionId ===
                        selectedTransaction?.transactionId
                      }>
                      {loadingTransactionId ===
                      selectedTransaction?.transactionId
                        ? 'Processing...'
                        : 'Delete Transaction'}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default PendingTransactions;
