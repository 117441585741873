import React, { useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AddWallet = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get('token');
    try {
      const response = await axios.post(
        `${BASEURLDEV}/user/connectWallet`,
        {
          wallet: walletAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      if (response.statusText === 'OK') {
        setMessage('Wallet address added successfully!');
        setWalletAddress('');
        navigate('/dashboard');
      } else {
        setMessage('Failed to add wallet address.');
      }
    } catch (error) {
      console.error('Error adding wallet address:', error.response);
      if (
        error.response.status === 401 ||
        error.response.data.message.includes('jwt')
      ) {
        navigate === null
          ? (window.location.href = '/login')
          : navigate('/login');
      }
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-4">Add Wallet</h2>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-900 p-4 rounded shadow-md">
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="walletAddress">
            Wallet Address
          </label>
          <input
            type="text"
            id="walletAddress"
            name="walletAddress"
            value={walletAddress}
            onChange={handleChange}
            placeholder="Enter wallet address"
            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-800 text-white leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Add Wallet
          </button>
        </div>
        {message && <p className="text-white mt-4">{message}</p>}
      </form>
    </div>
  );
};

export default AddWallet;
