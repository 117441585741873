// import { Link } from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import WithdrawalIcon from '../icons/withdrawicon';
// import InvestMentIcon from '../icons/investment.icon';
// import ChartIcon from '../icons/chart.icon';
// import Navigation from './reusable.comp';
// import Notify from '../component/toastify.comp';

// import {
//   FaDollarSign,
//   FaWallet,
//   FaPiggyBank,
//   FaChartLine,
//   FaHandshake,
//   FaTicketAlt,
// } from 'react-icons/fa';
// import { IoIosStats } from 'react-icons/io';
// import RecentTxComp from './recent.transaction';
// import axios from 'axios';
// import { BASEURLDEV } from '../constants/api';
// import Cookies from 'js-cookie';
// import { useAuth } from '../context/user.context';
// import RecentInvComp from './recent.inv';
// import RecentWithdrawComp from './recent.withdrawa';
// import { handleServerError } from '../utils/error.handler';
// import notifySuccessMsg from '../utils/notify.succes';

// const getUser = () => {
//   const userStr = localStorage.getItem('user');
//   return JSON.parse(userStr);
// };
// const token = Cookies.get('token');
// export default function DashboardComp() {
//   const [user, setUser] = useState(getUser());
//   const [balance, setBalance] = useState(0);
//   const [commision, setCommission] = useState(0);
//   const [ROI, setRoiData] = useState();
//   // const { ROI } = useAuth();

//   useEffect(() => {
//     const userStr = localStorage.getItem('user');
//     const userParsed = JSON.parse(userStr);

//     setUser(userParsed);
//   }, []);

//   useEffect(() => {
//     setUser(getUser());
//     fetchBalance();
//     fetchCommission();
//   }, [balance, commision, user]);

//   useEffect(() => {
//     const fetchROI = async () => {
//       try {
//         const response = await axios.post(
//           `${BASEURLDEV}/investment/update-daily-roi`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         console.log(response);
//         setRoiData(response.data);
//       } catch (error) {
//         console.error('Error fetching ROI data:', error);
//       }
//     };

//     fetchROI();
//   }, [ROI]);

//   const fetchBalance = async () => {
//     try {
//       const response = await axios.get(`${BASEURLDEV}/account/getBalance`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log(response);
//       setBalance(response.data.doc.balance);
//     } catch (error) {
//       console.error('Error fetching balance:', error);
//     }
//   };
//   async function fetchCommission() {
//     try {
//       const response = await axios.get(`${BASEURLDEV}/user/myCommisionBal`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log(response);
//       setCommission(response.data.doc.commission);
//     } catch (error) {
//       console.error('Error fetching balance:', error);
//     }
//   }

//   // async function withdrawCommission() {
//   //   const token = Cookies.get('token');
//   //   try {
//   //     const response = await axios.patch(
//   //       `${BASEURLDEV}/user/withdrawCommisionBal`,
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${token}`,
//   //         },
//   //       }
//   //     );
//   //     console.log(response);

//   //     notifySuccessMsg(response.data.message);
//   //   } catch (error) {
//   //     console.error('Error withdrawing commision:', error.response);
//   //     // handleServerError(error.response?.status, error.response?.data?.message);
//   //   }
//   // }

//   async function withdrawCommission() {
//     try {
//       const response = await axios.patch(
//         `${BASEURLDEV}/user/withdrawCommisionBal`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log(response);

//       notifySuccessMsg(response.data.message);
//     } catch (error) {
//       console.error('Error withdrawing commission:', error.response);
//       handleServerError(error.response?.status, error.response?.data?.message);
//     }
//   }

//   async function handleCommision(e) {
//     e.preventDefault();
//     await withdrawCommission();
//   }
//   return (
//     <div className="p-4 w-full">
//       <div className="bg-slate-900 p-6 w-full max-w-4xl mx-auto rounded-md">
//         <div className="flex flex-col sm:flex-row items-center gap-10">
//           <span className="py-6 px-4 flex flex-col gap-4 justify-center items-center rounded-lg bg-slate-800 text-white">
//             <p>Hi {user.firstname}</p>
//             <Link
//               className="bg-sky-700 rounded-md px-4 py-2 hover:bg-sky-600 transition"
//               to="/dashboard/new-investment">
//               Start Investing
//             </Link>
//           </span>
//           <nav className="flex flex-wrap justify-center sm:justify-start bg-slate-800 py-6 px-4 items-center gap-4 rounded-lg text-white w-full sm:w-auto">
//             <Link
//               className="flex items-center gap-2 bg-green-600 px-4 py-2 rounded-md hover:bg-green-500 transition"
//               to="/dashboard/deposit">
//               <InvestMentIcon className="text-xl" />
//               <p className="font-bold">Deposits</p>
//             </Link>
//             <Link
//               className="flex items-center gap-2 bg-blue-600 px-4 py-2 rounded-md hover:bg-blue-500 transition"
//               to="/dashboard/my-investment">
//               <ChartIcon className="text-xl" />
//               <p className="font-bold">Investment</p>
//             </Link>
//             <Link
//               className="flex items-center gap-2 bg-red-600 px-4 py-2 rounded-md hover:bg-red-500 transition"
//               to="/dashboard/see-withdrawal">
//               <WithdrawalIcon className="text-xl" />
//               <p className="font-bold">Withdrawal</p>
//             </Link>
//             <button
//               onClick={(e) => handleCommision(e)}
//               className="flex items-center gap-2 bg-teal-600 px-4 py-2 rounded-md hover:bg-red-500 transition">
//               <FaPiggyBank className="text-xl" />
//               <p className="font-bold">Withdraw commision </p>
//               <p className="font-bold">${commision}</p>
//             </button>
//           </nav>
//         </div>

//         <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-4 w-full">
//           <Navigation
//             icon={FaWallet}
//             name="Account Balance"
//             amount={balance}
//             linkTxt="View"
//             linkPath="#"
//             bgColor="bg-purple-600"
//             hoverColor="hover:bg-purple-500"
//           />
//           <Navigation
//             icon={FaDollarSign}
//             name="Deposits"
//             // amount={null}
//             linkTxt="Top up"
//             linkPath="/dashboard/deposit"
//             bgColor="bg-green-600"
//             hoverColor="hover:bg-green-500"
//           />
//           <Navigation
//             icon={FaDollarSign}
//             name="Withdrawal"
//             // amount="2000"
//             linkTxt="Withdraw"
//             linkPath="/dashboard/withdrawal"
//             bgColor="bg-red-600"
//             hoverColor="hover:bg-red-500"
//           />
//           <Navigation
//             icon={IoIosStats}
//             name="ROI"
//             amount={ROI}
//             // linkTxt="View"
//             linkPath="/roi"
//             bgColor="bg-yellow-600"
//             hoverColor="hover:bg-yellow-500"
//           />
//           <Navigation
//             icon={FaChartLine}
//             name="Investment"
//             // amount="5000"
//             linkTxt="Invest"
//             linkPath="/dashboard/new-investment"
//             bgColor="bg-blue-600"
//             hoverColor="hover:bg-blue-500"
//           />
//           <Navigation
//             icon={FaHandshake}
//             name="Referral"
//             // amount="750"
//             // linkTxt="Copy"
//             // linkPath="/referral"
//             bgColor="bg-teal-600"
//             referralLink={user.referralLink}
//             isRef={true}
//             hoverColor="hover:bg-teal-500"
//           />
//           <Navigation
//             icon={FaTicketAlt}
//             name="Tickets"
//             // amount="5"
//             linkTxt="View"
//             linkPath="/dashboard/allTickets"
//             bgColor="bg-orange-600"
//             hoverColor="hover:bg-orange-500"
//           />
//         </div>
//         <div className="mt-6">
//           <RecentTxComp />
//         </div>
//         <div className="mt-6">
//           <RecentInvComp />
//         </div>
//         <div className="mt-6">
//           <RecentWithdrawComp />
//         </div>
//         <Notify />
//       </div>
//     </div>
//   );
// }

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import WithdrawalIcon from '../icons/withdrawicon';
import InvestMentIcon from '../icons/investment.icon';
import ChartIcon from '../icons/chart.icon';
import Navigation from './reusable.comp';
import Notify from '../component/toastify.comp';

import {
  FaDollarSign,
  FaWallet,
  FaPiggyBank,
  FaChartLine,
  FaHandshake,
  FaTicketAlt,
} from 'react-icons/fa';
import { IoIosStats } from 'react-icons/io';
import RecentTxComp from './recent.transaction';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import Cookies from 'js-cookie';
import { handleServerError } from '../utils/error.handler';
import notifySuccessMsg from '../utils/notify.succes';
import RecentInvComp from './recent.inv';
import RecentWithdrawComp from './recent.withdrawa';

const getUser = () => {
  const userStr = localStorage.getItem('user');
  return JSON.parse(userStr);
};

const token = Cookies.get('token');

export default function DashboardComp() {
  const [user, setUser] = useState(getUser());
  const [balance, setBalance] = useState(0);
  const [commision, setCommission] = useState(0);
  const [ROI, setRoiData] = useState();

  console.log(user.referralLink);

  useEffect(() => {
    // Fetch user, balance, and commission when the component mounts
    const fetchData = async () => {
      try {
        // Fetch user
        setUser(getUser());
        // Fetch balance
        const balanceResponse = await axios.get(
          `${BASEURLDEV}/account/getBalance`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setBalance(balanceResponse.data.doc.balance);
        // Fetch commission
        const commissionResponse = await axios.get(
          `${BASEURLDEV}/user/myCommisionBal`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCommission(commissionResponse.data.doc.commission);
        // Fetch ROI
        const roiResponse = await axios.post(
          `${BASEURLDEV}/investment/update-daily-roi`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        console.log(roiResponse, 'ROI');
        setRoiData(roiResponse.data.ROI?.toFixed(2));
      } catch (error) {
        console.error('Error fetching data:', error);
        handleServerError(
          error.response?.status,
          error.response?.data?.message
        );
      }
    };

    fetchData();
  }, []);

  const withdrawCommission = async () => {
    try {
      const response = await axios.patch(
        `${BASEURLDEV}/user/withdrawCommisionBal`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      notifySuccessMsg(response.data.message);
    } catch (error) {
      console.error('Error withdrawing commission:', error.response);
      handleServerError(error.response?.status, error.response?.data?.message);
    }
  };

  const handleCommision = async (e) => {
    e.preventDefault();
    await withdrawCommission();
  };

  return (
    <div className="p-4 w-full">
      <div className="bg-slate-900 p-6 w-full max-w-4xl mx-auto rounded-md">
        <div className="flex flex-col sm:flex-row items-center gap-10">
          <span className="py-6 px-4 flex flex-col gap-4 justify-center items-center rounded-lg bg-slate-800 text-white">
            <p>Hi {user.firstname}</p>
            <Link
              className="bg-sky-700 rounded-md px-4 py-2 hover:bg-sky-600 transition"
              to="/dashboard/new-investment">
              Start Investing
            </Link>
          </span>
          <nav className="flex flex-wrap justify-center sm:justify-start bg-slate-800 py-6 px-4 items-center gap-4 rounded-lg text-white w-full sm:w-auto">
            <Link
              className="flex items-center gap-2 bg-green-600 px-4 py-2 rounded-md hover:bg-green-500 transition"
              to="/dashboard/deposit">
              <InvestMentIcon className="text-xl" />
              <p className="font-bold">Deposits</p>
            </Link>
            <Link
              className="flex items-center gap-2 bg-blue-600 px-4 py-2 rounded-md hover:bg-blue-500 transition"
              to="/dashboard/my-investment">
              <ChartIcon className="text-xl" />
              <p className="font-bold">Investment</p>
            </Link>
            <Link
              className="flex items-center gap-2 bg-red-600 px-4 py-2 rounded-md hover:bg-red-500 transition"
              to="/dashboard/see-withdrawal">
              <WithdrawalIcon className="text-xl" />
              <p className="font-bold">Withdrawal</p>
            </Link>
            <button
              onClick={(e) => handleCommision(e)}
              className="flex items-center gap-2 bg-teal-600 px-4 py-2 rounded-md hover:bg-teal-500 transition">
              <FaPiggyBank className="text-xl" />
              <p className="font-bold">Withdraw commission </p>
              <p className="font-bold">${commision}</p>
            </button>
          </nav>
        </div>

        <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-4 w-full">
          <Navigation
            icon={FaWallet}
            name="Account Balance"
            amount={Number(balance).toFixed(2)}
            linkTxt="View"
            linkPath="#"
            bgColor="bg-purple-600"
            hoverColor="hover:bg-purple-500"
          />
          <Navigation
            icon={FaDollarSign}
            name="Deposits"
            linkTxt="Top up"
            linkPath="/dashboard/deposit"
            bgColor="bg-green-600"
            hoverColor="hover:bg-green-500"
          />
          <Navigation
            icon={FaDollarSign}
            name="Withdrawal"
            linkTxt="Withdraw"
            linkPath="/dashboard/request-withdrawal"
            bgColor="bg-red-600"
            hoverColor="hover:bg-red-500"
          />
          <Navigation
            icon={IoIosStats}
            name="ROI"
            amount={ROI}
            linkPath="/roi"
            bgColor="bg-yellow-600"
            hoverColor="hover:bg-yellow-500"
          />
          <Navigation
            icon={FaChartLine}
            name="Investment"
            linkTxt="Invest"
            linkPath="/dashboard/new-investment"
            bgColor="bg-blue-600"
            hoverColor="hover:bg-blue-500"
          />
          <Navigation
            icon={FaHandshake}
            name="Referral"
            referralLink={user.referralLink}
            isRef={true}
            bgColor="bg-teal-600"
            hoverColor="hover:bg-teal-500"
          />
          <Navigation
            icon={FaTicketAlt}
            name="Tickets"
            linkTxt="View"
            linkPath="/dashboard/allTickets"
            bgColor="bg-orange-600"
            hoverColor="hover:bg-orange-500"
          />
        </div>
        <div className="mt-6">
          <RecentTxComp />
        </div>
        <div className="mt-6">
          <RecentInvComp />
        </div>
        <div className="mt-6">
          <RecentWithdrawComp />
        </div>
        <Notify />
      </div>
    </div>
  );
}
