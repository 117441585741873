// import { useState } from 'react';
// import { Link } from 'react-router-dom';

// export default function Navigation({
//   icon: Icon,
//   name,
//   linkTxt,
//   linkPath,
//   bgColor,
//   hoverColor,
//   amount = null,
//   referralLink = null,
//   isRef = false,
// }) {
//   const [copySuccess, setCopySuccess] = useState(false);

//   // Function to copy referral link to clipboard
//   const copyToClipboard = () => {
//     if (!referralLink) return;

//     navigator.clipboard
//       .writeText(referralLink)
//       .then(() => {
//         setCopySuccess(true);
//         setTimeout(() => setCopySuccess(false), 3000); // Reset copy success message after 3 seconds
//       })
//       .catch((err) => console.error('Failed to copy:', err));
//   };

//   return (
//     <div
//       className={`py-6 px-4 flex flex-col sm:gap-4 justify-start sm:justify-center sm:items-center gap-4 rounded-lg ${bgColor} text-white w-auto`}>
//       <span className="sm:flex gap-2 flex-col items-start justify-start sm:justify-between sm:items-center w-full">
//         <span className="flex gap-2 items-center">
//           <Icon className="text-2xl" />
//           <p className="font-bold">{name}</p>
//         </span>
//         <Link
//           className={`rounded-md px-4 py-2 ${hoverColor} transition`}
//           to={linkPath || referralLink}>
//           {linkTxt}
//         </Link>
//         {referralLink ||
//           (isRef && (
//             <button
//               className={`rounded-md px-4 py-2 ${hoverColor} transition`}
//               onClick={copyToClipboard}>
//               Copy Referral Link
//             </button>
//           ))}
//         {copySuccess && <span className="text-green-500">Copied!</span>}
//       </span>
//       <span className="font-bold">{`${
//         amount === null ? '' : `$ ${amount}`
//       }`}</span>
//     </div>
//   );
// }

import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navigation({
  icon: Icon,
  name,
  linkTxt,
  linkPath,
  bgColor,
  hoverColor,
  amount = null,
  referralLink = null,
  isRef = false,
}) {
  const [copySuccess, setCopySuccess] = useState(false);

  // Function to copy referral link to clipboard
  const copyToClipboard = () => {
    if (!referralLink) return;

    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000); // Reset copy success message after 3 seconds
      })
      .catch((err) => console.error('Failed to copy:', err));
  };

  return (
    <div
      className={`py-6 px-4 flex flex-col sm:gap-4 justify-start sm:justify-center sm:items-center gap-4 rounded-lg ${bgColor} text-white w-auto`}>
      <span className="sm:flex gap-2 flex-col items-start justify-start sm:justify-between sm:items-center w-full">
        <span className="flex gap-2 items-center">
          <Icon className="text-2xl" />
          <p className="font-bold">{name}</p>
        </span>
        {referralLink && isRef ? (
          <button
            className={`rounded-md px-4 py-2 ${hoverColor} transition`}
            onClick={copyToClipboard}>
            Copy Referral Link
          </button>
        ) : (
          <Link
            className={`rounded-md px-4 py-2 ${hoverColor} transition`}
            to={linkPath || referralLink}>
            {linkTxt}
          </Link>
        )}
        {copySuccess && <span className="text-green-500">Copied!</span>}
      </span>
      <span className="font-bold">{`${
        amount === null ? '' : `$ ${amount}`
      }`}</span>
    </div>
  );
}
