import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

function List({ subtitle, text }) {
  return (
    <li className="text-gray-500">
      <span>
        <b>{subtitle}: </b>
        {text}
      </span>
    </li>
  );
}

export default function Responsibility() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'LONG-TERM ASSETS'}
        h1={'RESPONSIBILITY'}
        backgroundImage={'../img/res-bg.jpg'}
      />
      <main className="bg-gray-100 text-sm px-11 py-11">
        <p className="pb-11">
          We operate long-term assets and businesses across the globe. This
          approach dictates both our investment strategy and our commitment to
          environmental, social and governance (ESG) practices. We believe that
          value creation and sustainable development are complementary goals.
          Throughout our operations, we are committed to practices that have a
          positive impact on the communities in which we operate.
        </p>
        <p className="pb-11 text-left font-semibold text-gray-600 sm:font-bold sm:text-center text-sm sm:text-3xl">
          OUR ESG PRINCIPLES ARE EMBEDDED THROUGHOUT OUR OPERATIONS AND HELP US
          ENSURE THAT OUR BUSINESS MODEL WILL BE SUSTAINABLE WELL INTO THE
          FUTURE.
        </p>
        <hr />
        <div className="flex flex-col md:flex-row py-11 gap-11">
          <div className="md:w-1/2 flex-col flex px-10 justify-center items-center gap-11">
            <ul className="list-disc flex-col gap-6">
              <h1 className="font-bold text-sm sm:text-xl">
                Mitigate the impact of our operations on the environment
              </h1>
              <List
                text={`Strive to minimize the environmental impact of our operations and improve our efficient use of resources over time.`}
                subtitle={`Environmental stewardship`}
              />
              <List
                text={`Support the goal of net zero greenhouse gas (GHG) emissions by 2050 or sooner.`}
                subtitle={`Net Zero Commitment`}
              />
            </ul>
            <ul className="list-disc flex-col gap-6">
              <h1 className="font-bold text-sm sm:text-xl">
                Ensure the well-being and safety of employees
              </h1>
              <List
                text={`Meet or exceed all applicable labor laws and standards in jurisdictions where we operate, which includes respecting human rights, offering competitive wages and implementing nondiscriminatory hiring practices.`}
                subtitle={`Employee well-being`}
              />
              <List
                text={`Aim to have zero serious safety incidents within our businesses by working toward implementing consistent health and safety principles across the organization.`}
                subtitle={`Health & safety`}
              />
            </ul>
          </div>
          <div className="md:w-1/2 flex-col flex gap-11">
            <ul className="list-disc flex-col gap-6">
              <h1 className="font-bold text-sm sm:text-xl">
                Be good stewards in the communities in which we operate
              </h1>
              <List
                text={`Engage with community groups that might be affected by our actions to ensure that their interests, safety and well-being are appropriately integrated into our decision-making.`}
                subtitle={`Community engagement`}
              />
              <List
                text={`Encourage our employees to participate in the communities in which we operate.`}
                subtitle={`Philanthropy`}
              />
            </ul>
            <ul className="list-disc flex-col gap-6">
              <h1 className="font-semibold text-sm sm:text-xl">
                Conduct business according to the highest ethical and
                legal/regulatory standards
              </h1>
              <List
                text={`Operate with high ethical standards by conducting business activities in compliance with applicable legal and regulatory requirements, and with our Code of Business Conduct and Ethics.`}
                subtitle={`Governance, ethics, and fairness`}
              />
              <List
                text={`Be accessible to our investors and stakeholders by being responsive to requests for information and timely in our communication.`}
                subtitle={`Transparency`}
              />
            </ul>
          </div>
        </div>

        <div className="py-11 flex-col gap-4 justify-center items-center text-left sm:text-center text-gray-600 text-sm px-2 sm:px-11">
          <h1 className="mx-auto text-left font-semibold mb-2 text-lg sm:text-center text-slate-950 sm:font-bold  sm:text-3xl">
            CONVEX INTEGRATED NET ZERO COMMITMENT
          </h1>
          <p>
            We support the goal of net zero greenhouse gas (‘GHG’) emissions by
            2050.
          </p>
          <p>
            Over the past 25 years, Convex Integrated has built one of the
            largest private renewable power businesses in the world. With
            installed renewable generating capacity of 20 GW, we now produce
            more than enough green energy to power London and will more than
            double that amount once our development portfolio is brought online.
          </p>
          <p>
            We recognize that further renewable power capacity must be rapidly
            scaled to replace fossil fuel generation and meet expanding global
            electricity demand so that the world can eliminate the over 70% of
            global emissions that come from final energy consumption as quickly
            as possible.
          </p>
          <p>
            Convex Integrated intends to build on this leading position in
            renewable power and do much more to contribute to the transition to
            net zero.
          </p>
        </div>
        <hr />
        <div className="flex flex-col md:flex-row gap-8 items-start px-2 py-11 sm:px-11">
          <div className="md:w-1/2 flex-col flex px-10 justify-start sm:justify-center text-left sm:items-center gap-11">
            <ul className="list-disc text-lg text-gray-600 flex-col gap-6">
              <h1 className="font-bold text-lg sm:text-xl">
                We will go further
              </h1>
              <p className="sm:text-lg text-sm">
                In addition to continuing to make major investments in renewable
                energy globally, we will manage our investments to be consistent
                with the transition to a net zero economy. As a recent signatory
                to the Net Zero Asset Managers initiative, we have made a
                commitment to investing aligned with net zero emissions by 2050
                and implementation of science-based approaches and standardized
                methodologies through which to deliver these commitments.
              </p>
            </ul>
            <ul className="list-disc text-sm sm:text-lg text-gray-600 flex-col gap-6">
              <h1 className="font-bold text-sm mb-2 sm:text-xl">
                We will help accelerate the transition to Net Zero
              </h1>
              <p>
                We will catalyze companies onto Paris-aligned net zero pathways
                through our new Global Transition investment strategy, focusing
                specifically on investments that will accelerate the transition
                to a net zero carbon economy.
              </p>
            </ul>
            <ul className="list-disc text-sm sm:text-lg text-gray-600 flex-col gap-6">
              <h1 className="font-bold text-lg sm:text-xl">
                We will collaborate
              </h1>
              <p>
                We will work with leading private sector initiatives to advance
                the role of finance in supporting the economy-wide transition,
                to accelerate capital flows consistent with the Paris Agreement,
                and to promote widespread adoption of decision-useful
                methodologies to support credible transition planning, analysis,
                and investing.
              </p>
            </ul>
          </div>
          <div className="md:w-1/2 flex-col flex gap-11">
            <ul className="list-disc text-sm sm:text-lg text-gray-600 flex-col gap-6">
              <h1 className="font-bold text-lg sm:text-xl">
                We are committed to transparency
              </h1>
              <li className="text-gray-500">
                We will track and report GHG emissions consistent with GHG
                Protocol and PCAF standards.
              </li>
              <li className="text-gray-500">
                We will publish decarbonization plans every five years
                consistent with the Paris Agreement.
              </li>
              <li className="text-gray-500">
                We continue to align our business with the TCFD recommendations
                and are targeting to incorporate TCFD disclosures for the 2022
                fiscal year.
              </li>
            </ul>
            <ul className="list-disc text-lg text-gray-600 flex-col gap-6">
              <h1 className="font-bold text-xl">
                We will continue to pursue industry-leading returns
              </h1>
              <p>
                We will continue to pursue industry-leading returns for our
                investors, consistent with our long track record of building the
                backbone of a more sustainable global economy.
              </p>
            </ul>
          </div>
        </div>

        <div className="py-11 flex-col gap-4 justify-center text-left items-center sm:text-center text-gray-600 text-sm sm:text-lg px-2 sm:px-11">
          <h1 className="mx-auto text-left sm:text-center text-slate-950 font-bold text-lg sm:text-xl ">
            ESG FRAMEWORKS AND MEMBERSHIPS
          </h1>
          <p>Net Zero Asset Managers Initiative</p>
          <p>
            Convex Integrated is a signatory to the Net Zero Asset Managers
            initiative, underscoring our long-standing commitment to driving the
            complementary goals of sustainability and value creation across our
            investments and operations. The Net Zero Asset Managers initiative
            is a group of international asset managers committed to supporting
            the goal of net zero greenhouse gas emissions by 2050 or sooner, in
            line with global efforts to limit warming to 1.5 degrees Celsius;
            and to supporting investing aligned with net zero emissions by 2050
            or sooner.
          </p>
        </div>

        <div className="py-11 px-2 text-lg  sm:px-11">
          <h1 className="mx-auto my-4 text-center text-slate-950 font-bold sm:text-3xl">
            INTEGRATING ESG CONSIDERATIONS INTO OUR INVESTMENT PROCESS
          </h1>
          <div className="sm:flex-row flex flex-col gap-4  my-8 sm:gap-8 items-start text-gray-600 sm:text-lg text-sm">
            <p>
              Our commitments include incorporating ESG factors into our
              investment decisions, starting with the due diligence of potential
              investments through to the exit process. We tailor ESG due
              diligence to each investment, and we create post-investment
              remediation plans for material ESG considerations. For all
              potential investments, we use internal experts and a variety of
              ESG frameworks to identify material ESG factors and utilize
              external consultants where appropriate. This analysis includes
              everything from ensuring environmental, legal and regulatory
              compliance to the identification of opportunities to add value or
              mitigate risk in our portfolio. Our investment teams use an ESG
              due diligence guideline to ensure consideration of material ESG
              risks and opportunities. These teams then provide a detailed
              memorandum to the Investment Committee outlining the merits of the
              transaction and disclosures relating to risks, including material
              ESG issues, and potential mitigation strategies. All investments
              made by Covex Integrated must be approved by the Investment
              Committee and must incorporate ESG matters into their evaluation,
              including anti-bribery and corruption, health and safety, and
              other ESG considerations.
            </p>
            <p>
              Upon company acquisition, we create a tailored integration plan to
              ensure that all material matters, including ESG risks and
              opportunities are prioritized. ESG risks and opportunities are
              actively managed by the portfolio companies with guidance from our
              in-house investment teams, primarily through representation on
              company boards and equivalent oversight bodies where all
              financial, operational, and strategic elements of the business are
              reported, considered, and where appropriate, approved. This allows
              us to draw on local expertise, which provides valuable insight
              given the wide range of asset types and locations in which we
              invest. Certain key performance indicators, such as serious safety
              incidents, are reported regularly to the applicable board or other
              oversight body.
            </p>
          </div>
        </div>

        <div className="flex-col flex gap-4 sm:justify-center items-center text-left sm:text-center text-gray-600 text-lg px-2 sm:px-11">
          <h1 className="sm:mx-auto text-left sm:text-center text-slate-950 font-bold text-lg sm:text-xl">
            COMMITTING TO OUR FUTURE
          </h1>
          <p className="sm:text-lg text-sm">
            In 2020, we announced a commitment to reach net zero emissions by
            2050, making it one of the most ambitious of any major corporation
            in the industry. We will continue to invest in new products and
            technologies that will help us reduce our carbon footprint and
            create a more sustainable future for all.
          </p>
        </div>
        <div className=" flex-col-reverse sm:flex-row flex gap-11 items-start py-11 px-11">
          <div className="sm:w-1/2 w-full text-gray-500 text-sm sm:text-lg flex flex-col gap-6 sm:gap-4">
            <span className="flex gap-2 items-center">
              <span className="bg-blue-500 text-slate-50 rounded-full py-2 px-3">
                1
              </span>
              <h1 className="uppercase font-bold text-xl">
                A STRONG GOVERNANCE FRAMEWORK
              </h1>
            </span>
            <p>
              We are always working to maintain sound governance practices to
              ensure ongoing investor confidence. This involves a continual
              review of how evolving legislation, guidelines and best practices
              should be reflected in our approach. For example, we have a
              zero-tolerance approach to bribery, including facilitation
              payments, and all Convex Integrated employees are mandated to
              complete an in-depth anti-bribery and corruption (ABC) training
              seminar annually. Convex Integrated maintains an ethics hotline to
              report suspected unethical, illegal or unsafe behavior. Our
              reporting hotline is managed by an independent third party and is
              available 24 hours a day, 7 days a week. We also require all
              portfolio companies in which we have a controlling interest to
              adopt an ABC policy that is equally stringent to Convex
              Integrated’s, which entails that portfolio companies install an
              ethics hotline within six months of acquisition.
            </p>
          </div>
          <div className="sm:w-1/2 mt-4 w-full ">
            <img
              src="../img/res-1.jpg "
              alt="res-1"
            />
          </div>
        </div>

        <div className="flex-col sm:flex-row flex gap-11 items-start py-11 px-11">
          <div className="sm:w-1/2 mb-4 w-full">
            <img
              src="../img/safety.jpg "
              alt="res-1"
            />
          </div>
          <div className="sm:w-1/2 w-full text-gray-500 text-sm sm:text-lg flex flex-col gap-6 sm:gap-4">
            <span className="flex gap-2 items-center">
              <span className="bg-blue-500 text-slate-50 rounded-full py-2 px-3">
                2
              </span>
              <h1 className="uppercase font-bold text-xl">
                HEALTH AND SAFETY IN OUR PORTFOLIO COMPANIES
              </h1>
            </span>
            <p>
              Employee health and safety is a top priority at Convex Integrated.
              We view health and safety as an integral part of the management of
              our business and therefore consider it a line responsibility best
              managed by portfolio companies. We have established a safety
              steering committee, which includes the CEOs and COOs of each
              business group, to promote common values and a strong health and
              safety culture, share best practices and monitor serious safety
              incidents. In the event that a serious incident does occur, Convex
              Integrated conducts an in-depth investigation to determine root
              causes and formulate remediation actions.
            </p>
          </div>
        </div>

        <div className="flex-col-reverse sm:flex-row flex gap-11 items-start py-11 px-11">
          <div className="sm:w-1/2 w-full text-gray-500 text-sm sm:text-lg flex flex-col gap-6 sm:gap-4">
            <span className="flex gap-2 items-center">
              <span className="bg-blue-500 text-slate-50 rounded-full py-2 px-3">
                3
              </span>
              <h1 className="uppercase font-bold text-xl">
                FOSTERING DIVERSITY & INCLUSION
              </h1>
            </span>
            <p>
              Embedded in our culture is a commitment to advancing diversity and
              inclusion across our organization. This begins at recruitment,
              continues in leadership training programs and is woven into our
              policies and procedures. As a global firm, we know that the best
              ideas come from having people from different backgrounds,
              perspectives, experiences and skills across all businesses, levels
              of seniority and offices.
            </p>
            <p>
              Training programs set clear expectations for our leaders in terms
              of their role in helping all team members achieve their potential.
              The training emphasizes building trust with their teams, becoming
              aware of unconscious biases and provides guidance on how to add
              rigor to decision-making especially in recruiting, performance
              feedback and promotion, with the goal of creating a more diverse
              and inclusive environment.
            </p>
            <p>
              Our internal groups and networks, including a global Convex
              Integrated Women’s Advisory Committee and a Diversity Advisory
              Committee, actively advise management on how to make our Firm more
              welcoming to people from diverse backgrounds.
            </p>
          </div>
          <div className="sm:w-1/2 mt-4 w-full">
            <img
              src="../img/diversity.jpg "
              alt="res-1"
            />
          </div>
        </div>

        <div className="bg-gray-200 text-lg px-11 py-11">
          <h1 className="font-bold text-lg sm:text-3xl text-center mx-auto w-1/2 my-6">
            WOMEN MAKE UP
          </h1>

          <div className="flex gap-4 justify-center flex-wrap items-center py-11 ">
            <span className="border  border-slate-950 p-4 flex items-start space-x-2">
              <i className="fas fa-apple-alt text-xl"></i>
              <div className="py-8">
                <h2 className="text-xl font-semibold">46%</h2>
                <p className="text-lg">OF TOTAL EMPLOYEES.</p>
              </div>
            </span>

            <span className="border  border-slate-950 p-4 flex items-start space-x-2">
              <i className="fas fa-apple-alt text-xl"></i>
              <div className="py-8">
                <h2 className="text-xl font-semibold"> 55%</h2>
                <p className="text-lg">OF INDEPENDENT BOARD DIRECTORS</p>
              </div>
            </span>

            <span className="border border-slate-950 p-4 flex items-start space-x-2">
              <i className="fas fa-apple-alt text-xl"></i>
              <div className="py-8">
                <h2 className="text-xl font-semibold">30% </h2>
                <p className="text-lg">OF VICE PRESIDENTS AND ABOVE</p>
              </div>
            </span>
          </div>
        </div>
        <div className="my-11 px-10 py-8">
          <div className="flex sm:flex-row flex-col items-center py-2">
            <hr className="sm:w-1/2 w-4/5" />
            <h1 className="text-center text-lg sm:text-3xl font-semibold my-4">
              A COMMITMENT TO GREEN BUILDINGS
            </h1>
            <hr className="sm:w-1/2 w-4/5" />
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center py-11">
            <span className="border w-full sm:w-1/2 h-80 border-slate-950 p-4 flex items-center justify-center overflow-hidden">
              <div className="text-center overflow-y-auto max-h-full">
                <p className="text-lg px-4">
                  Developing properties that take environmental considerations
                  into account is not only a passion, it provides returns on our
                  investments. That’s why 100% of our new developments are being
                  built according to LEED® Gold standards, to proactively
                  address greenhouse gas emissions, energy efficiency, water
                  use, indoor air quality, and other impacts.
                </p>
              </div>
            </span>

            <span className="border w-full h-80 sm:w-1/2 border-slate-950 p-4 flex items-center justify-center">
              <img
                src="../img/led.png"
                alt="led"
                className="max-w-full max-h-full"
              />
            </span>
          </div>
        </div>
      </main>
      <Footer
        firstAddress={`South Street`}
        secondAddress={`8/F, Block 1`}
        thirdAddress={`South place road`}
      />
    </div>
  );
}
