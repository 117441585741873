import { Link, useNavigate } from 'react-router-dom';
import UserAuthComp from '../component/usercomp.auth';
import AtSymbolIcon from '../icons/at.icon';
import PasswordIcon from '../icons/password.icon';
import LoginIcon from '../icons/login.icon';
import Spinner from '../component/spinner';
import { useAuth } from '../context/user.context';
import { useState } from 'react';
import Notify from '../component/toastify.comp';
import { BASEURLDEV } from '../constants/api';
import axios from 'axios';
import { handleServerError } from '../utils/error.handler';

export default function ResetPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState();
  const [token, setToken] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  async function setNewPasswordFn(token, password, confirmPassword) {
    try {
      setIsLoading(true);
      const response = await axios.patch(`${BASEURLDEV}/user/userNewPassword`, {
        token,
        password,
        confirmPassword,
      });

      if (response.status === 200) {
        setIsLoading(false);
        return navigate('/login');
      }
    } catch (error) {
      setIsLoading(false);
      handleServerError(error.response.status, error.response.data.message);
      console.log(error.response);
    }
  }
  async function handleSetNewPassword(e) {
    e.preventDefault();
    await setNewPasswordFn(token, password, confirmPassword);
  }
  return (
    <div>
      <UserAuthComp
        handler={handleSetNewPassword}
        type={'Set New Password'}>
        <div className="flex flex-col gap-4">
          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <AtSymbolIcon />
            <input
              type="text"
              onChange={(e) => setToken(e.target.value)}
              placeholder="Token"
              className="bg-transparent bg-none text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <PasswordIcon />
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="New password"
              className="bg-transparent  text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>
          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <PasswordIcon />
            <input
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="confirm New password"
              className="bg-transparent  text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>
        </div>

        <span className="px-4 mt-4 py-2 flex sm:w-60 items-center rounded-md gap-2 border border-gray-300 bg-slate-800 hover:bg-slate-700 transition-colors">
          <LoginIcon />
          {!isLoading && (
            <button className="outline-none text-slate-50">Submit </button>
          )}
          {isLoading && <Spinner />}
        </span>
      </UserAuthComp>
      <Notify />
    </div>
  );
}
