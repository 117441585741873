import React from 'react';

const DownloadPDF = ({ fileUrl, fileName }) => {
  return (
    <div className="max-w-md mx-auto my-6 p-4 bg-white shadow-lg rounded-lg text-center">
      <a
        href={fileUrl}
        download={fileName}
        className="inline-flex items-center px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-200">
        Download PDF
        <svg
          className="ml-2 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M14 2H7a2 2 0 00-2 2v14a2 2 0 002 2h7a2 2 0 002-2V8l-5-6z"></path>
        </svg>
      </a>
    </div>
  );
};

export default DownloadPDF;
