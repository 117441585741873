// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function FixedIncome() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'PRODUCT'}
//         h1={'FIXED INCOME'}
//         h1Blue={''}
//         backgroundImage={'../img/fixed-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">FIXED INCOME</h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               We aim to generate consistent outperformance by systematically
//               applying investment themes across securities. We believe that a
//               multi-factor
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               investment approach, harnessing underlying drivers of performance,
//               will generate excess returns that are uncorrelated to other asset
//               classes as well as traditional fixed income managers.
//             </p>
//           </span>
//         </div>

//         <div className="flex mx-auto w-1/2 py-10  flex-col gap-4 items-center justify-items-center">
//           <h1 className="font-bold pt-10 text-3xl">WHY TRADE STOCKS?</h1>
//           <p className="text-gray-500 text-lg pb-10">
//             Stocks let you own a piece of a company’s future. They’re available
//             for a wide variety of industries—so you can tap into your knowledge
//             of specific businesses, or help you to diversify your portfolio.
//           </p>
//         </div>

//         <div className="flex gap-11 py-11 px-6">
//           <span className="w-1/2 flex flex-col gap-4 text-lg">
//             <span>
//               {' '}
//               <h1 className="font-bold text-xl mb-4">
//                 EMERGING MARKETS HARD CURRENCY
//               </h1>
//               <p>
//                 Seeks excess returns through country, maturity, and currency
//                 selection across hard currency and local currency markets. While
//                 the strategy includes local currency investments, it takes no
//                 beta to local currency debt, engages in no overall duration or
//                 spread timing, and targets a beta of one to its hard currency
//                 benchmark.
//               </p>
//             </span>

//             <span>
//               {' '}
//               <h1 className="font-bold text-xl mb-4">Global Aggregate</h1>
//               <p>
//                 Seeks excess returns through country, maturity, credit, and
//                 currency selection. The strategy targets the credit and duration
//                 profile of the benchmark and so does not seek to engage in
//                 duration timing or sector selection.
//               </p>
//             </span>
//           </span>

//           <span className="w-1/2 flex flex-col gap-8 text-lg">
//             <span>
//               <h1 className="font-bold text-xl mb-4">Global Government</h1>
//               <p>
//                 This strategy seeks excess returns through country, maturity,
//                 and currency selection. It engages in minimal duration timing.
//               </p>
//             </span>
//             <span>
//               <h1 className="font-bold text-xl mb-4">High Yield Corporates</h1>
//               <p>
//                 Investment themes in this strategy are primarily expressed by
//                 within-industry security selection. It does not seek to engage
//                 in duration or credit timing.
//               </p>
//             </span>
//           </span>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />

//         <div className="px-8">
//           {/* <div className="flex  items-center py-2">
//             <hr className="w-1/2" />
//             <h1 className="text-center text-3xl font-semibold my-4">
//               TOP FIVE DIVIDEND YIELDING STOCKS
//             </h1>
//             <hr className="w-1/2" />
//           </div>
//           <p className="text-gray-500 text-lg mx-auto text-center pb-4">
//             Learn more about stocks
//           </p> */}

//           <div className="flex gap-4 items-center py-11 ">
//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">
//                   INVESTMENT GRADE CORPORATES
//                 </h2>
//                 <p className="text-lg">
//                   Seeks to outperform a core or long duration corporate
//                   benchmark. Our investment themes are primarily expressed by
//                   within-industry security selection. The strategy does not seek
//                   to engage in duration or credit timing
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">UNCONSTRAINED BONDS</h2>
//                 <p className="text-lg">
//                   A cash-benchmarked bond strategy that seeks to deliver
//                   positive absolute returns with low correlation to traditional
//                   market betas. The strategy primarily utilizes a broad suite of
//                   relative value fixed income sub-strategies spanning interest
//                   rates, credit, and foreign exchange markets. It also includes
//                   a small, dynamic, and diversified allocation to fixed income
//                   market risks such as duration, credit, and securitized
//                   exposures.
//                 </p>
//               </div>
//             </span>

//             <span className="border p-4 flex items-start space-x-2">
//               <i className="fas fa-apple-alt text-xl"></i>
//               <div className="py-8">
//                 <h2 className="text-lg font-semibold">CORE PLUS</h2>
//                 <p className="text-lg">
//                   Strategy that seeks excess returns through country, maturity,
//                   credit, and currency selection. Out-of-benchmark sectors are
//                   strictly used to increase security selection breadth, while
//                   still targeting the credit and duration profile of the
//                   benchmark, and so does not seek to engage in duration timing
//                   or sector selection.
//                 </p>
//               </div>
//             </span>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function FixedIncome() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'PRODUCT'}
        h1={'FIXED INCOME'}
        h1Blue={''}
        backgroundImage={'../img/fixed-bg.jpg'}
      />
      <main className=" text-sm">
        <div className="bg-gray-200 py-10 px-6">
          <h1 className="font-bold sMtext-3xl text-lg py-4 mb-4">
            FIXED INCOME
          </h1>
          <div className="flex flex-col sm:flex-row gap-6">
            <p className="w-full sm:w-1/2 leading-7 text-sm sm:text-lg">
              We aim to generate consistent outperformance by systematically
              applying investment themes across securities. We believe that a
              multi-factor investment approach, harnessing underlying drivers of
              performance, will generate excess returns that are uncorrelated to
              other asset classes as well as traditional fixed income managers.
            </p>
            <p className="w-full sm:w-1/2 leading-7 text-sm sm:text-lg">
              Investment themes in this strategy are primarily expressed by
              within-industry security selection. It does not seek to engage in
              duration or credit timing.
            </p>
          </div>
        </div>

        <div className="flex px-6 mx-auto w-full sm:w-1/2 py-10 flex-col gap-4 items-center justify-items-center">
          <h1 className="font-bold pt-10 text-lg sm:text-3xl">
            WHY TRADE STOCKS?
          </h1>
          <p className="text-gray-500 sm:text-lg pb-10">
            Stocks let you own a piece of a company’s future. They’re available
            for a wide variety of industries—so you can tap into your knowledge
            of specific businesses, or help you to diversify your portfolio.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row text-sm gap-11 py-2 sm:py-11 px-6">
          <div className="w-full sm:w-1/2 flex flex-col gap-4 text-lg">
            <div className="text-sm">
              <h1 className="font-bold text-lg sm:text-xl mb-4">
                EMERGING MARKETS HARD CURRENCY
              </h1>
              <p>
                Seeks excess returns through country, maturity, and currency
                selection across hard currency and local currency markets. While
                the strategy includes local currency investments, it takes no
                beta to local currency debt, engages in no overall duration or
                spread timing, and targets a beta of one to its hard currency
                benchmark.
              </p>
            </div>

            <div className="text-sm">
              <h1 className="font-bold sm:text-xl text-lg mb-4">
                Global Aggregate
              </h1>
              <p>
                Seeks excess returns through country, maturity, credit, and
                currency selection. The strategy targets the credit and duration
                profile of the benchmark and so does not seek to engage in
                duration timing or sector selection.
              </p>
            </div>
          </div>

          <div className="w-full text-sm sm:w-1/2 flex flex-col gap-8 ">
            <div>
              <h1 className="font-bold text-lg sm:text-xl mb-4">
                Global Government
              </h1>
              <p>
                This strategy seeks excess returns through country, maturity,
                and currency selection. It engages in minimal duration timing.
              </p>
            </div>
            <div>
              <h1 className="font-bold text-xl mb-4">High Yield Corporates</h1>
              <p>
                Investment themes in this strategy are primarily expressed by
                within-industry security selection. It does not seek to engage
                in duration or credit timing.
              </p>
            </div>
          </div>
        </div>

        <hr className="mx-auto w-4/5 mt-10 pb-4" />

        {/* <div className="px-8">
          <div className="flex items-center py-2">
            <hr className="w-1/2" />
            <h1 className="text-center text-3xl font-semibold my-4">
              TOP FIVE DIVIDEND YIELDING STOCKS
            </h1>
            <hr className="w-1/2" />
          </div>
          <p className="text-gray-500 text-lg mx-auto text-center pb-4">
            Learn more about stocks
          </p>

          <div className="flex gap-4 items-center py-11">
            <div className="border p-4 flex items-start space-x-2">
              <i className="fas fa-apple-alt text-xl"></i>
              <div>
                <h2 className="text-lg font-semibold">
                  INVESTMENT GRADE CORPORATES
                </h2>
                <p className="text-lg">
                  Seeks to outperform a core or long duration corporate
                  benchmark. Our investment themes are primarily expressed by
                  within-industry security selection. The strategy does not seek
                  to engage in duration or credit timing.
                </p>
              </div>
            </div>

            <div className="border p-4 flex items-start space-x-2">
              <i className="fas fa-apple-alt text-xl"></i>
              <div>
                <h2 className="text-lg font-semibold">UNCONSTRAINED BONDS</h2>
                <p className="text-lg">
                  A cash-benchmarked bond strategy that seeks to deliver
                  positive absolute returns with low correlation to traditional
                  market betas. The strategy primarily utilizes a broad suite of
                  relative value fixed income sub-strategies spanning interest
                  rates, credit, and foreign exchange markets. It also includes
                  a small, dynamic, and diversified allocation to fixed income
                  market risks such as duration, credit, and securitized
                  exposures.
                </p>
              </div>
            </div>

            <div className="border p-4 flex items-start space-x-2">
              <i className="fas fa-apple-alt text-xl"></i>
              <div>
                <h2 className="text-lg font-semibold">CORE PLUS</h2>
                <p className="text-lg">
                  Strategy that seeks excess returns through country, maturity,
                  credit, and currency selection. Out-of-benchmark sectors are
                  strictly used to increase security selection breadth, while
                  still targeting the credit and duration profile of the
                  benchmark, and so does not seek to engage in duration timing
                  or sector selection.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="px-8 text-sm">
          <div className="flex items-center py-2">
            <hr className="w-1/2" />
            <h1 className="text-center text-lg sm:text-3xl font-semibold my-4">
              TOP FIVE DIVIDEND YIELDING STOCKS
            </h1>
            <hr className="w-1/2" />
          </div>
          <p className="text-gray-500 text-lg mx-auto text-center pb-4">
            Learn more about stocks
          </p>

          <div className="flex text-sm flex-col sm:flex-row gap-4 items-center py-11">
            <div className="border p-4 flex items-start space-x-2 w-full sm:w-1/3">
              <i className="fas fa-apple-alt text-xl"></i>
              <div>
                <h2 className="text-lg font-semibold">
                  INVESTMENT GRADE CORPORATES
                </h2>
                <p className="sm:text-lg">
                  Seeks to outperform a core or long duration corporate
                  benchmark. Our investment themes are primarily expressed by
                  within-industry security selection. The strategy does not seek
                  to engage in duration or credit timing.
                </p>
              </div>
            </div>

            <div className="border text-sm p-4 flex items-start space-x-2 w-full sm:w-1/3">
              <i className="fas fa-apple-alt text-xl"></i>
              <div>
                <h2 className="text-lg font-semibold">UNCONSTRAINED BONDS</h2>
                <p className="sm:text-lg">
                  A cash-benchmarked bond strategy that seeks to deliver
                  positive absolute returns with low correlation to traditional
                  market betas. The strategy primarily utilizes a broad suite of
                  relative value fixed income sub-strategies spanning interest
                  rates, credit, and foreign exchange markets. It also includes
                  a small, dynamic, and diversified allocation to fixed income
                  market risks such as duration, credit, and securitized
                  exposures.
                </p>
              </div>
            </div>

            <div className="border text-sm p-4 flex items-start space-x-2 w-full sm:w-1/3">
              <i className="fas fa-apple-alt text-xl"></i>
              <div>
                <h2 className="text-lg font-semibold">CORE PLUS</h2>
                <p className="sm:text-lg">
                  Strategy that seeks excess returns through country, maturity,
                  credit, and currency selection. Out-of-benchmark sectors are
                  strictly used to increase security selection breadth, while
                  still targeting the credit and duration profile of the
                  benchmark, and so does not seek to engage in duration timing
                  or sector selection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
