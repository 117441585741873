import React from 'react';

export default function AssetManagementSection() {
  return (
    <div className="flex sm:flex-row flex-col py-2 sm:py-10 mt-16 px-6 gap-4 overflow-hidden">
      {/* Text and Image Section */}
      <div className="flex-shrink-0 w-full flex-col gap-4 lg:w-1/2 min-w-[320px]">
        {/* First Section */}
        <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
          <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
            <i className="fas fa-check"></i>
          </span>
          <span className="flex flex-col gap-3">
            <p className="uppercase text-gray-500 text-sm sm:text-base">
              OUR ASSET MANAGEMENT SOLUTIONS
            </p>
            <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-sm sm:text-xl font-semibold border-b-4 border-blue-800">
              PROFESSIONALLY MANAGED INVESTMENT PORTFOLIOS
            </h1>
          </span>
        </div>
        <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
          Time is a precious commodity. Researching investments in ever-changing
          markets and handling investment transactions are more than most people
          have time for. Convex integrated's Asset Management Solutions program
          allows you to delegate the daily management of your assets and invest
          with confidence, knowing that your portfolio is in the hands of
          experienced professionals.
        </p>

        <hr className="my-4 border-t-2" />

        {/* Second Section */}
        <div className="flex items-center mb-4 mt-6 gap-4 space-x-2">
          <span className="bg-blue-800 text-white rounded-full p-2 flex items-center justify-center">
            <i className="fas fa-check"></i>
          </span>
          <span className="flex flex-col gap-3">
            <p className="uppercase text-gray-500 text-sm sm:text-lg">
              OUR APPROACH TO ASSET MANAGEMENT
            </p>
            <h1 className="uppercase w-full lg:w-80 leading-7 lg:leading-9 text-sm sm:text-xl font-bold border-b-4 border-blue-800">
              DIFFERENT GOALS REQUIRE DIFFERENT APPROACHES.
            </h1>
          </span>
        </div>
        <p className="text-sm sm:text-base lg:text-xl text-gray-600 leading-6 sm:leading-8 lg:leading-10">
          At Convex Integrated we recognize that each investor is unique. That’s
          why we take a personalized approach to developing an asset management
          strategy by selecting investment portfolios that closely match your
          goals, tolerance for risk, and expectation for returns.
        </p>
      </div>

      {/* Image Section */}
      <div className="flex-shrink-0 w-full lg:w-1/2 min-w-[320px]">
        <img
          alt="pic1"
          src="../img/calculator.jpg"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
}
