import { Link } from 'react-router-dom';
import Logo from '../logocomp';
import { useState } from 'react';
import { FaChevronDown, FaBars, FaTimes } from 'react-icons/fa';

export default function NavBar() {
  const [isInvestmentOpen, setIsInvestmentOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="bg-black w-full px-2 py-2 text-white z-50">
      <div
        className="container flex items-center justify-between p-4"
        style={{ maxWidth: '1024px' }}>
        <div className="flex-shrink-0 w-40">
          <Logo />
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-white focus:outline-none">
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
        <div className="hidden md:flex md:items-center md:space-x-4">
          <Link
            to="/about-us"
            className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition max-w-xs text-sm sm:text-base lg:text-lg">
            About
          </Link>
          <Link
            to="/responsibility"
            className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition max-w-xs text-sm sm:text-base lg:text-lg">
            Responsibility
          </Link>
          <div className="relative">
            <button
              onMouseEnter={() => setIsInvestmentOpen(true)}
              onMouseLeave={() => setIsInvestmentOpen(false)}
              className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md flex items-center transition focus:outline-none">
              Investment Products
              <FaChevronDown className="ml-1" />
            </button>
            {isInvestmentOpen && (
              <div
                onMouseEnter={() => setIsInvestmentOpen(true)}
                onMouseLeave={() => setIsInvestmentOpen(false)}
                className="absolute z-50 left-0 mt-2 w-56 bg-white text-black rounded-md shadow-lg">
                {[
                  'Real Estate',
                  'Stocks',
                  'Infrastructure',
                  'Forex',
                  'Crypto',
                  'Fixed Income',
                  'Multi Asset',
                  'Renewable Energy',
                  'Agriculture',
                  'NFP',
                ].map((item, index) => (
                  <Link
                    key={index}
                    to={`/${item.toLowerCase().replace(/ /g, '-')}`}
                    className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap">
                    {item}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <div className="relative">
            <button
              onMouseEnter={() => setIsProductOpen(true)}
              onMouseLeave={() => setIsProductOpen(false)}
              className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md flex items-center transition focus:outline-none">
              Product Services
              <FaChevronDown className="ml-1" />
            </button>
            {isProductOpen && (
              <div
                onMouseEnter={() => setIsProductOpen(true)}
                onMouseLeave={() => setIsProductOpen(false)}
                className="absolute left-0 z-50 mt-2 w-56 bg-white text-black rounded-md shadow-lg">
                {[
                  'Financial Planning',
                  'Retirement Planning',
                  'Private Wealth Management',
                  'Estate Planning',
                  'Long Term Care',
                  'Business Plan',
                  'Oil & Gas',
                ].map((item, index) => (
                  <Link
                    key={index}
                    to={`/${item.toLowerCase().replace(/ /g, '-')}`}
                    className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap">
                    {item}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <Link
            to="/blog"
            className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition max-w-xs text-sm sm:text-base lg:text-lg">
            Insight
          </Link>
          <Link
            to="/login"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-400 transition max-w-xs text-sm sm:text-base lg:text-lg">
            Sign In
          </Link>
        </div>
      </div>

      {/* Side Menu for Mobile */}
      <div
        className={`fixed inset-y-0 left-0 bg-black text-white w-64 transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-50 md:hidden`}>
        <div className="flex items-center justify-between p-4">
          <Logo />
          <button
            onClick={() => setIsMenuOpen(false)}
            className="text-white focus:outline-none">
            <FaTimes size={24} />
          </button>
        </div>
        <div className="flex flex-col space-y-4 p-4">
          <Link
            to="/about-us"
            className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition text-sm sm:text-base lg:text-lg">
            About
          </Link>
          <Link
            to="/responsibility"
            className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition text-sm sm:text-base lg:text-lg">
            Responsibility
          </Link>
          <div className="relative">
            <button
              onClick={() => setIsInvestmentOpen(!isInvestmentOpen)}
              className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md flex items-center transition focus:outline-none">
              Investment Products
              <FaChevronDown className="ml-1" />
            </button>
            {isInvestmentOpen && (
              <div className="mt-2 bg-black text-white rounded-md shadow-lg">
                {[
                  'Real Estate',
                  'Stocks',
                  'Infrastructure',
                  'Forex',
                  'Crypto',
                  'Fixed Income',
                  'Multi Asset',
                  'Renewable Energy',
                  'Agriculture',
                  'NFP',
                ].map((item, index) => (
                  <Link
                    key={index}
                    to={`/${item.toLowerCase().replace(/ /g, '-')}`}
                    className="block px-4 py-2 hover:bg-gray-800 whitespace-nowrap">
                    {item}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <div className="relative">
            <button
              onClick={() => setIsProductOpen(!isProductOpen)}
              className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md flex items-center transition focus:outline-none">
              Product Services
              <FaChevronDown className="ml-1" />
            </button>
            {isProductOpen && (
              <div className="mt-2 bg-black text-white rounded-md shadow-lg">
                {[
                  'Financial Planning',
                  'Retirement Planning',
                  'Private Wealth Management',
                  'Estate Planning',
                  'Long Term Care',
                  'Business Plan',
                  'Oil & Gas',
                ].map((item, index) => (
                  <Link
                    key={index}
                    to={`/${item.toLowerCase().replace(/ /g, '-')}`}
                    className="block px-4 py-2 hover:bg-gray-800 whitespace-nowrap">
                    {item}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <Link
            to="/blog"
            className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition text-sm sm:text-base lg:text-lg">
            Insight
          </Link>
          <Link
            to="/login"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-400 transition text-sm sm:text-base lg:text-lg">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

// import { Link } from 'react-router-dom';
// import Logo from '../logocomp';
// import { useState } from 'react';
// import { FaChevronDown } from 'react-icons/fa';

// export default function NavBar() {
//   const [isInvestmentOpen, setIsInvestmentOpen] = useState(false);
//   const [isProductOpen, setIsProductOpen] = useState(false);

//   return (
//     <div className="bg-black w-full px-2 py-2 text-white z-50">
//       <div
//         className="container flex items-center justify-between p-4"
//         style={{ maxWidth: '1024px' }}>
//         <div className="flex-shrink-0 w-40">
//           <Logo />
//         </div>
//         <div className="flex items-center space-x-4">
//           <Link
//             to="/about-us"
//             className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition text-sm">
//             About
//           </Link>
//           <Link
//             to="/responsibility"
//             className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition text-sm">
//             Responsibility
//           </Link>
//           <div className="relative">
//             <button
//               onMouseEnter={() => setIsInvestmentOpen(true)}
//               onMouseLeave={() => setIsInvestmentOpen(false)}
//               className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md flex items-center transition text-sm focus:outline-none">
//               Investment Products
//               <FaChevronDown className="ml-1" />
//             </button>
//             {isInvestmentOpen && (
//               <div
//                 onMouseEnter={() => setIsInvestmentOpen(true)}
//                 onMouseLeave={() => setIsInvestmentOpen(false)}
//                 className="absolute z-50 left-0 mt-2 w-56 bg-white text-black rounded-md shadow-lg">
//                 {[
//                   'Real Estate',
//                   'Stocks',
//                   'Infrastructure',
//                   'Forex',
//                   'Crypto',
//                   'Fixed Income',
//                   'Multi Asset',
//                   'Renewable Energy',
//                   'Agriculture',
//                   'NFP',
//                 ].map((item, index) => (
//                   <Link
//                     key={index}
//                     to={`/${item.toLowerCase().replace(/ /g, '-')}`}
//                     className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap text-sm">
//                     {item}
//                   </Link>
//                 ))}
//               </div>
//             )}
//           </div>
//           <div className="relative">
//             <button
//               onMouseEnter={() => setIsProductOpen(true)}
//               onMouseLeave={() => setIsProductOpen(false)}
//               className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md flex items-center transition text-sm focus:outline-none">
//               Product Services
//               <FaChevronDown className="ml-1" />
//             </button>
//             {isProductOpen && (
//               <div
//                 onMouseEnter={() => setIsProductOpen(true)}
//                 onMouseLeave={() => setIsProductOpen(false)}
//                 className="absolute left-0 z-50 mt-2 w-56 bg-white text-black rounded-md shadow-lg">
//                 {[
//                   'Financial Planning',
//                   'Retirement Planning',
//                   'Private Wealth Management',
//                   'Estate Planning',
//                   'Long Term Care',
//                   'Business Plan',
//                   'Oil & Gas',
//                 ].map((item, index) => (
//                   <Link
//                     key={index}
//                     to={`/${item.toLowerCase().replace(/ /g, '-')}`}
//                     className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap text-sm">
//                     {item}
//                   </Link>
//                 ))}
//               </div>
//             )}
//           </div>
//           <Link
//             to="/blog"
//             className="hover:text-gray-400 hover:bg-gray-800 p-2 rounded-md transition text-sm">
//             Insight
//           </Link>
//           <Link
//             to="/login"
//             className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-400 transition text-sm">
//             Sign In
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// }
