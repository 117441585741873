// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function Nfp() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'INVESTMENT PRODUCTS'}
//         h1={'NFP'}
//         h1Blue={''}
//         backgroundImage={'../img/nfp-bg.webp'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">
//             NON FARM PAYROLL
//           </h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               The Non-farm payrolls report is one of the most anticipated
//               economic news reports in the forex markets. It is published the
//               first Friday of the month at 8:30AM Eastern time by the U.S.
//               Bureau of labor statistics. The data release actually includes a
//               number of statistics, and not just the NFP(which is the change in
//               the number of employees in the country, not including farm,
//               government, private and non- profit employees.). Another
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               metric included in the data release is the unemployment rate. As
//               one of the most anticipated economic news events of the months,
//               Integrated Binary Options Services focus mainly on currency
//               pairs(especially those involving the U.S. Dollar) and typically
//               see big price movements in the minutes and hours after the data is
//               released. This makes it a great opportunity for our traders with a
//               sound strategy to take advantage of the volatility.
//             </p>
//           </span>
//         </div>

//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//         <h1 className="font-bold px-10 text-gray-500 text-xl mb-4">
//           BELOW IS OUR STEP BY STEP STRATEGY IN TRADING NFP REPORT
//         </h1>
//         <div className="flex items-start gap-8 text-lg  px-10 py-8">
//           <div className="w-1/2 flex flex-col gap-8 ">
//             <p>
//               Most times , we trade the EUR/USD afterthought NFP report. The
//               EUR/USD is the most heavily traded currency pairs in the world.
//               And therefore, it typically provides the smallest spread and ample
//               price movement for making trades. There is little reason, to day
//               trade another pair during the NFP report.
//             </p>
//             <span className="flex-col gap-4 flex ">
//               <p>
//                 We close all prior day trading positions at least ten
//                 minutes,prior to 8:30AM ET when the data is scheduled to be
//                 released. For this strategy, we do not take positions before the
//                 announcement, rather, we do nothing until the NFP number has
//                 been released. When that occurs, the price will see a big rise
//                 or decline which typically lasts for few minutes.(sometimes
//                 more). During that initial move, we do nothing. We just wait.
//                 For this strategy, we use a 1-minute EUR/USD chart.
//               </p>
//               <p>
//                 Our initial move establishes first trade direction, just after
//                 8:30AM ET, the price will rise or fall rapidly, typically at
//                 least 30pips or more within a couple minutes. The bigger this
//                 initial move, the better for day trading purposes. The initial
//                 move gives us the trade direction,(long or short) for our first
//                 trade. If the price moves more than 30pips higher, we will want
//                 to go long, but only if and when we get a valid trade setup.
//                 Which is discussed shortly.
//               </p>

//               <p>
//                 If the price drops more than 30pips, in the few minutes after
//                 the 8:30AM ET release, then we will be looking to go short for
//                 our first trade. When and if a trade setup occurs.
//               </p>
//               <p>
//                 Again, the initial rise or fall in the moments after 8:30AM lets
//                 us know in which direction we will be trading. The next setup is
//                 to wait for a trade setup. A trade setup is a sequence of events
//                 that must unfold, in other for us to get into a trade. Since
//                 there is often a lot of volatility surrounding the news, we will
//                 look at a few variation of the setup, as no two days are ever
//                 exactly alike.
//               </p>
//             </span>
//           </div>
//           <div className="w-1/2 h-1/2">
//             <img
//               src="../img/nfp-1.webp"
//               alt="agric"
//             />
//           </div>
//         </div>
//         <div className="flex flex-col gap-4 px-10 text-gray-500 py-11">
//           <h1 className="font-bold px-10 text-gray-600 text-xl mb-4">
//             HERE IS WHAT WE ARE WAITING FOR:
//           </h1>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             After the initial move of 30pips or more, there must be a pullback
//             at least 5 one-minutes price bars. This means that if the initial
//             move was up, we want to see the price drop off the high of the
//             initial move and stay below that for at least 5 bars( they don't all
//             need to be down bars.) Preferably the pullback makes sure
//             significant downward progress, but it must not drop below the 8:30AM
//             price where the initial move began. If the initial move was down, we
//             want to see the price rally off the low of the Initial move and stay
//             above low that for at least 5 bars. Preferably, the pullback makes
//             significant upward progress, but it most not rise above the 8:30AM
//             price, when the Initial down move began.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             By waiting for at least a 5-price-bar pullback, you can draw a
//             trendline across the Heights of the price bar(if initial move was
//             up) or across the lows of the price bars.(if the initial move was
//             down). Note: We are drawing the trendline on the price bars that
//             compose the pull back.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             If the Initial move was up, buy when the bid price breaks above the
//             trendline. If the initial move was down, enter a short trade when
//             the bid price moves below the trendline. This is the simplest form
//             of the strategy and is useful in most situations. Unfortunately, it
//             is quite general, so occasionally the pullback may not provide a
//             trendline that is useful for signaling an entry. In such cases, the
//             alternative entry discussed in the next section maybe helpful.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             If a long trade is taken, place a stop loss one pip below the recent
//             low that just formed prior to entry.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             If a short trade is taken, place a stop loss one pip(plus the size
//             of your spread) above the recent high that formed prior to entry.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             After the Initial move, if the price pulls back more than half of
//             the distance of the Initial move(before breaking the pull back trend
//             line and signaling an entry, this is an alternative method. Once the
//             price has pulled back more than 50%(can use a Fibonacci retracement
//             tool), wit for the price to consolidate for at least two price bars.
//             That means, the price moves sideways for at least two minutes. Draw
//             a line along the high and low prices of those two price bars once
//             the second bar completes and the third bar is starting to form.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             If the initial move was up, buy if the bid price moves above the
//             high of the consolidation.if the initial move was down, enter a
//             short trade if the bid price drops below the low of the
//             consolidation.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             If a long trade triggers, place a stop loss one pip, below the low
//             of the consolidation.
//           </p>
//           <p className=" leading-10 text-xs sm:text-lg ">
//             If a short trade triggers, place a stop loss one pip(plus the size
//             of your spread) above the high of the consolidation.
//           </p>
//         </div>
//         <hr className="mx-auto w-4/5 mt-10 pb-4" />
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function Nfp() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'INVESTMENT PRODUCTS'}
        h1={'NFP'}
        h1Blue={''}
        backgroundImage={'../img/nfp-bg.webp'}
      />
      <main className="">
        <div className="bg-gray-200 px-6 py-10">
          <h1 className="font-bold text-2xl md:text-3xl py-4 mb-4">
            NON FARM PAYROLL
          </h1>
          <div className="flex flex-col md:flex-row items-center gap-6 pb-10">
            <p className="w-full md:w-1/2 leading-7 text-sm md:text-lg">
              The Non-farm payrolls report is one of the most anticipated
              economic news reports in the forex markets. It is published the
              first Friday of the month at 8:30AM Eastern time by the U.S.
              Bureau of Labor Statistics. The data release actually includes a
              number of statistics, and not just the NFP (which is the change in
              the number of employees in the country, not including farm,
              government, private and non-profit employees). Another metric
              included in the data release is the unemployment rate. As one of
              the most anticipated economic news events of the months,
              Integrated Binary Options Services focus mainly on currency pairs
              (especially those involving the U.S. Dollar) and typically see big
              price movements in the minutes and hours after the data is
              released. This makes it a great opportunity for our traders with a
              sound strategy to take advantage of the volatility.
            </p>
            <p className="w-full md:w-1/2 leading-7 text-sm md:text-lg">
              Most times, we trade the EUR/USD after the NFP report. The EUR/USD
              is the most heavily traded currency pair in the world. Therefore,
              it typically provides the smallest spread and ample price movement
              for making trades. There is little reason to day trade another
              pair during the NFP report.
            </p>
          </div>
        </div>

        <hr className="mx-auto w-4/5 mt-10 pb-4" />
        <h1 className="font-bold px-6 md:px-10 text-gray-500 text-xl mb-4">
          BELOW IS OUR STEP BY STEP STRATEGY IN TRADING NFP REPORT
        </h1>
        <div className="flex flex-col md:flex-row items-start gap-8 text-sm md:text-lg px-6 md:px-10 py-8">
          <div className="w-full md:w-1/2 flex flex-col gap-8">
            <p>
              We close all prior day trading positions at least ten minutes
              prior to 8:30AM ET when the data is scheduled to be released. For
              this strategy, we do not take positions before the announcement;
              rather, we do nothing until the NFP number has been released. When
              that occurs, the price will see a big rise or decline which
              typically lasts for a few minutes (sometimes more). During that
              initial move, we do nothing. We just wait. For this strategy, we
              use a 1-minute EUR/USD chart.
            </p>
            <span className="flex-col gap-4">
              <p>
                Our initial move establishes the first trade direction. Just
                after 8:30AM ET, the price will rise or fall rapidly, typically
                at least 30 pips or more within a couple of minutes. The bigger
                this initial move, the better for day trading purposes. The
                initial move gives us the trade direction (long or short) for
                our first trade. If the price moves more than 30 pips higher, we
                will want to go long, but only if and when we get a valid trade
                setup, which is discussed shortly.
              </p>
              <p>
                If the price drops more than 30 pips in the few minutes after
                the 8:30AM ET release, then we will be looking to go short for
                our first trade, when and if a trade setup occurs.
              </p>
              <p>
                Again, the initial rise or fall in the moments after 8:30AM ET
                lets us know in which direction we will be trading. The next
                setup is to wait for a trade setup. A trade setup is a sequence
                of events that must unfold for us to get into a trade. Since
                there is often a lot of volatility surrounding the news, we will
                look at a few variations of the setup, as no two days are ever
                exactly alike.
              </p>
            </span>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="../img/nfp-1.webp"
              alt="NFP"
              className="w-full h-auto"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 px-6 md:px-10 text-gray-500 py-8">
          <h1 className="font-bold text-gray-600 text-xl mb-4">
            HERE IS WHAT WE ARE WAITING FOR:
          </h1>
          <p className="leading-7 text-sm md:text-lg">
            After the initial move of 30 pips or more, there must be a pullback
            of at least 5 one-minute price bars. This means that if the initial
            move was up, we want to see the price drop off the high of the
            initial move and stay below that for at least 5 bars (they don't all
            need to be down bars). Preferably, the pullback makes significant
            downward progress, but it must not drop below the 8:30AM price where
            the initial move began. If the initial move was down, we want to see
            the price rally off the low of the initial move and stay above that
            for at least 5 bars. Preferably, the pullback makes significant
            upward progress, but it must not rise above the 8:30AM price where
            the initial down move began.
          </p>
          <p className="leading-7 text-sm md:text-lg">
            By waiting for at least a 5-price-bar pullback, you can draw a
            trendline across the highs of the price bars (if the initial move
            was up) or across the lows of the price bars (if the initial move
            was down). Note: We are drawing the trendline on the price bars that
            compose the pullback.
          </p>
          <p className="leading-7 text-sm md:text-lg">
            If the initial move was up, buy when the bid price breaks above the
            trendline. If the initial move was down, enter a short trade when
            the bid price moves below the trendline. This is the simplest form
            of the strategy and is useful in most situations. Unfortunately, it
            is quite general, so occasionally the pullback may not provide a
            trendline that is useful for signaling an entry. In such cases, the
            alternative entry discussed in the next section may be helpful.
          </p>
          <p className="leading-7 text-sm md:text-lg">
            If a long trade is taken, place a stop loss one pip below the recent
            low that just formed prior to entry. If a short trade is taken,
            place a stop loss one pip (plus the size of your spread) above the
            recent high that formed prior to entry.
          </p>
          <p className="leading-7 text-sm md:text-lg">
            After the initial move, if the price pulls back more than half of
            the distance of the initial move (before breaking the pullback trend
            line and signaling an entry), this is an alternative method. Once
            the price has pulled back more than 50% (you can use a Fibonacci
            retracement tool), wait for the price to consolidate for at least
            two price bars. That means, the price moves sideways for at least
            two minutes. Draw a line along the high and low prices of those two
            price bars once the second bar completes and the third bar is
            starting to form.
          </p>
          <p className="leading-7 text-sm md:text-lg">
            If the initial move was up, buy if the bid price moves above the
            high of the consolidation. If the initial move was down, enter a
            short trade if the bid price drops below the low of the
            consolidation.
          </p>
          <p className="leading-7 text-sm md:text-lg">
            If a long trade triggers, place a stop loss one pip below the low of
            the consolidation. If a short trade triggers, place a stop loss one
            pip (plus the size of your spread) above the high of the
            consolidation.
          </p>
        </div>
        <hr className="mx-auto w-4/5 mt-10 pb-4" />
      </main>
      <Footer />
    </div>
  );
}
