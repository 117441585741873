import React from 'react';

const PlanCard = ({
  planName,
  dailyInterest,
  minInvestment,
  maxInvestment,
  referralCommission,
  withdrawalAvailability,
  representativeInfo,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{planName}</div>
        <div className="text-gray-700 mb-4">
          <p className="mb-2">Daily Interest: {dailyInterest}%</p>
          <p className="mb-2">Minimum Investment: ${minInvestment}</p>
          <p className="mb-2">Maximum Investment: ${maxInvestment}</p>
          <p className="mb-2">Referral Commission: {referralCommission}%</p>
          <p className="mb-2">
            Withdrawal Availability: {withdrawalAvailability}
          </p>
          <p className="font-bold mb-2">Representative Info:</p>
          <p>{representativeInfo}</p>
        </div>
        <div className="mt-4">
          <a
            href="/login"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

const PlanContainer = () => {
  const plans = [
    {
      id: 1,
      planName: 'Corporate Plan',
      dailyInterest: 2.5,
      minInvestment: 100,
      maxInvestment: 5000,
      referralCommission: 10,
      withdrawalAvailability: '24/7',
      representativeInfo:
        'Junior Representative: 50 active members - earn $1,000 monthly.',
    },
    {
      id: 2,
      planName: 'Ultimate Plan',
      dailyInterest: 3.5,
      minInvestment: 5000,
      maxInvestment: 10000,
      referralCommission: 10,
      withdrawalAvailability: '24/7',
      representativeInfo:
        'Senior Representative: 100 active members - earn $2,000 monthly.',
    },
    {
      id: 3,
      planName: 'Premium Plan',
      dailyInterest: 4,
      minInvestment: 15000,
      maxInvestment: 20000,
      referralCommission: 10,
      withdrawalAvailability: '24/7',
      representativeInfo:
        'Senior Representative: 100 active members - earn $2,000 monthly.',
    },
    {
      id: 4,
      planName: 'Master Plan',
      dailyInterest: 5,
      minInvestment: 20000,
      maxInvestment: 25000,
      referralCommission: 10,
      withdrawalAvailability: '24/7',
      representativeInfo:
        'Senior Representative: 100 active members - earn $2,000 monthly.',
    },
    {
      id: 5,
      planName: 'Standard Plan',
      dailyInterest: 6,
      minInvestment: 30000,
      maxInvestment: 'unlimited',
      referralCommission: 10,
      withdrawalAvailability: '24/7',
      representativeInfo:
        'Senior Representative: 100 active members - earn $2,000 monthly.',
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div key={plan.id}>
            <PlanCard
              planName={plan.planName}
              dailyInterest={plan.dailyInterest}
              minInvestment={plan.minInvestment}
              maxInvestment={plan.maxInvestment}
              referralCommission={plan.referralCommission}
              withdrawalAvailability={plan.withdrawalAvailability}
              representativeInfo={plan.representativeInfo}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanContainer;
