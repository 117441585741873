import TradingViewWidget from 'react-tradingview-widget';

const TradingChart = () => {
  return (
    <div
      className="tradingview-widget-container h-70 mt-11 pt-11"
      style={{ height: '70vh' }}>
      <TradingViewWidget
        symbol="NASDAQ:AAPL"
        theme="light"
        locale="en"
        autosize
      />
    </div>
  );
};

export default TradingChart;
