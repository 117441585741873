// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';

// export default function PrivateWealth() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'SERVICES'}
//         h1={'PRIVATE'}
//         h1Blue={'WEALTH MANAGEMENT'}
//         backgroundImage={'../img/private-bg.jpg'}
//       />
//       <main>
//         <div className="bg-gray-200 text-lg px-11 py-11">
//           <h1 className="text-gray-500 mb-4 font-bold text-3xl">
//             THE ONE FINANCIAL PARTNERSHIP THAT GOES BEYOND YOUR PORTFOLIO
//           </h1>
//           <p className="py-8 w-1/2">
//             Our comprehensive approach to private wealth management will help
//             you and your family enjoy your wealth today, and for generations to
//             come.
//           </p>
//           <h2 className="font-bold text-xl mb-2">
//             FINANCIAL THOUGHT LEADERS, ON YOUR TEAM
//           </h2>
//           <p className="pb-11">
//             When major market movements happen, our Wealth Management Investment
//             Team is called upon by national news and media outlets to provide
//             their expertise. Our Private Client Group advisors work with them,
//             including our Chief Investment Strategist, Brent Schutte, to bring
//             their insights and philosophy into your portfolio.
//           </p>
//         </div>

//         <div className="py-11 my-11">
//           <h1 className="text-center font-bold uppercase text-3xl my-4">
//             OUR SERVICES, TAILORED TO YOU
//           </h1>
//           <hr className="w-1/2 mx-auto" />
//           <div className="flex gap-6 items-center justify-center">
//             <div class="text-gray-900 text-lg my-4">
//               <ul class="list-disc ml-6">
//                 <li>Financial Planning</li>
//                 <li>Investment Planning & Strategies</li>
//                 <li>Advanced Portfolio Management</li>
//                 <li>Retirement Planning & Income Strategies</li>
//                 <li>Estate Planning & Trust Services</li>
//                 <li>Risk Management</li>
//               </ul>
//             </div>
//             <div class="text-gray-900 text-lg">
//               <ul class="list-disc ml-6">
//                 <li>Tax Efficient Strategies</li>
//                 <li>Advisor Coordination</li>
//                 <li>Business Planning & Succession</li>
//                 <li>Multigenerational Planning</li>
//                 <li>Charitable Planning</li>
//                 <li>Education Funding</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </main>
//       <hr />
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function PrivateWealth() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'SERVICES'}
        h1={'PRIVATE'}
        h1Blue={'WEALTH MANAGEMENT'}
        backgroundImage={'../img/private-bg.jpg'} // Replace with correct image path
      />
      <main className="text-sm">
        <div className="bg-gray-200 text-sm sm:text-lg px-8 py-8 md:py-12 lg:py-16 xl:py-20">
          <h1 className="text-gray-500 mb-4 font-bold text-lg sm:text-3xl md:text-4xl lg:text-5xl">
            THE ONE FINANCIAL PARTNERSHIP THAT GOES BEYOND YOUR PORTFOLIO
          </h1>
          <p className="py-6 md:py-8 lg:py-10 xl:py-12 w-full md:w-3/4">
            Our comprehensive approach to private wealth management will help
            you and your family enjoy your wealth today, and for generations to
            come.
          </p>
          <h2 className="font-bold text-sm sm:text-xl md:text-2xl lg:text-3xl mb-2">
            FINANCIAL THOUGHT LEADERS, ON YOUR TEAM
          </h2>
          <p className="pb-8 md:pb-10 lg:pb-12 xl:pb-16">
            When major market movements happen, our Wealth Management Investment
            Team is called upon by national news and media outlets to provide
            their expertise. Our Private Client Group advisors work with them,
            including our Chief Investment Strategist, Brent Schutte, to bring
            their insights and philosophy into your portfolio.
          </p>
        </div>

        <div className="py-8 md:py-12 lg:py-16 xl:py-20">
          <h1 className="text-center font-bold uppercase text-lg sm:text-3xl md:text-4xl lg:text-5xl my-4">
            OUR SERVICES, TAILORED TO YOU
          </h1>
          <hr className="w-1/2 mx-auto" />
          <div className="flex flex-col md:flex-row gap-6 items-center sm:justify-center">
            <div className="text-gray-900 text-lg my-4 md:w-1/2">
              <ul className="list-disc ml-6">
                <li>Financial Planning</li>
                <li>Investment Planning & Strategies</li>
                <li>Advanced Portfolio Management</li>
                <li>Retirement Planning & Income Strategies</li>
                <li>Estate Planning & Trust Services</li>
                <li>Risk Management</li>
              </ul>
            </div>
            <div className="text-gray-900 text-lg md:w-1/2">
              <ul className="list-disc ml-6">
                <li>Tax Efficient Strategies</li>
                <li>Advisor Coordination</li>
                <li>Business Planning & Succession</li>
                <li>Multigenerational Planning</li>
                <li>Charitable Planning</li>
                <li>Education Funding</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <hr />
      <Footer />
    </div>
  );
}
