import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import {
  FaMoneyCheckAlt,
  FaPiggyBank,
  FaExchangeAlt,
  FaTicketAlt,
  FaUsers,
} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const AdminDashboardPage = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta
          name="viewport"
          content="width=1024"
        />
      </Helmet>
      <div className="flex h-screen overflow-hidden">
        <aside className="w-64 bg-gray-800 text-white flex flex-col fixed top-0 left-0 h-full border-r border-gray-700">
          <div className="p-6">
            <span className="block text-xl font-bold text-blue-400 mb-6">
              Admin Dashboard
            </span>
            <ul className="space-y-4">
              <li>
                <NavLink
                  to="/admin-dashboard"
                  className={({ isActive }) =>
                    isActive
                      ? 'flex items-center text-blue-300 font-semibold transition duration-300 ease-in-out'
                      : 'flex items-center hover:text-blue-300 transition duration-300 ease-in-out'
                  }>
                  <FaMoneyCheckAlt className="text-xl mr-2" /> Pending
                  Transactions
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin-dashboard/all-deposits"
                  className={({ isActive }) =>
                    isActive
                      ? 'flex items-center text-blue-300 font-semibold transition duration-300 ease-in-out'
                      : 'flex items-center hover:text-blue-300 transition duration-300 ease-in-out'
                  }>
                  <FaPiggyBank className="text-xl mr-2" /> Deposits
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin-dashboard/all-withdrawal"
                  className={({ isActive }) =>
                    isActive
                      ? 'flex items-center text-blue-300 font-semibold transition duration-300 ease-in-out'
                      : 'flex items-center hover:text-blue-300 transition duration-300 ease-in-out'
                  }>
                  <FaExchangeAlt className="text-xl mr-2" /> Withdrawals
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin-dashboard/pending-tickets"
                  className={({ isActive }) =>
                    isActive
                      ? 'flex items-center text-blue-300 font-semibold transition duration-300 ease-in-out'
                      : 'flex items-center hover:text-blue-300 transition duration-300 ease-in-out'
                  }>
                  <FaTicketAlt className="text-xl mr-2" /> Tickets
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin-dashboard/see-users"
                  className={({ isActive }) =>
                    isActive
                      ? 'flex items-center text-blue-300 font-semibold transition duration-300 ease-in-out'
                      : 'flex items-center hover:text-blue-300 transition duration-300 ease-in-out'
                  }>
                  <FaUsers className="text-xl mr-2" /> Onboarded Users
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin-dashboard/allAccounts"
                  className={({ isActive }) =>
                    isActive
                      ? 'flex items-center text-blue-300 font-semibold transition duration-300 ease-in-out'
                      : 'flex items-center hover:text-blue-300 transition duration-300 ease-in-out'
                  }>
                  <FaUsers className="text-xl mr-2" /> User Accounts
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
        <main className="flex-1 ml-64 bg-gray-100 p-6 overflow-auto">
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default AdminDashboardPage;
