// import BackgroundImageWithText from './bg-img';
// import Footer from './footer.comp';
// import NavBar from './homenav';
// export default function RenewableEnergy() {
//   return (
//     <div>
//       <NavBar />
//       <BackgroundImageWithText
//         p={'INVESTMENT PRODUCTS'}
//         h1={'RENEWABLE'}
//         h1Blue={'POWER'}
//         backgroundImage={'../img/renewable-bg.jpg'}
//       />
//       <main className="">
//         <div className="bg-gray-200 px-6 py-10 pb-10 pt-10">
//           <h1 className="font-bold text-3xl py-4 mb-4 pt-10">
//             RENEWABLE POWER
//           </h1>
//           <span className="flex items-center gap-6 pb-10">
//             <p className="w-1/2 leading-10 text-xs sm:text-lg">
//               We are one of the world's largest investors in renewable power,
//               with approximately 21,000 megawatts of generating capacity. Our
//               assets, located in North and South America, Europe, India and
//               China, comprise a diverse technology base of hydro, wind,
//               utility-scale solar, distributed generation, storage and other
//               renewable technologies. We utilize our fully
//             </p>
//             <p className="w-1/2 leading-10 text-xs sm:text-lg ">
//               integrated global operating platform and in-house expertise to
//               maintain facilities, organically add value and efficiently
//               integrate new assets, realizing cost synergies in the process. Our
//               business is underpinned by stable cash flows, with the majority of
//               our power contracted under long-term, inflation-linked contracts.
//             </p>
//           </span>
//         </div>
//         <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
//           RENEWABLE POWER FOR A CLEANER, BRIGHTER TOMORROW
//         </h1>

//         <div className="py-11 px-6">
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-center justify-center">
//               <p>
//                 The acquisition of the TerraForm companies strengthened Matrix
//                 Ace’s position as a global leader in renewable power, adding
//                 significant wind and solar assets as well as operating platforms
//                 in India and China.
//               </p>
//               <div>
//                 <h2 className="font-bold text-xl mb-4 text-gray-500 mt-10">
//                   RENEWABLE POWER LEADERSHIP
//                 </h2>
//                 <p>
//                   Our leaders share a commitment to our time-tested approach to
//                   investing, hands-on value creation and practices that have a
//                   positive impact on our communities.
//                 </p>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/ren.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>
//         <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-2xl text-center">
//           RENEWABLE POWER SECTORS
//         </h1>
//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             HRYDRO
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our hydro power assets are characterized by a perpetual asset
//                 life, high cash margins, and storage capacity.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 py-2 uppercase mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     8,000 MW
//                   </h1>
//                   <p className="text-gray-600">HYDRO CAPACITY</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl sm:text-4xl font-bold">
//                     222
//                   </h1>
//                   <p className="text-gray-600">HYDRO GENERATION FACILITIES</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl sm:text-4xl font-bold">
//                     83
//                   </h1>
//                   <p className="text-gray-600"> RIVER SYSTEMS</p>
//                 </span>
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/ren-1.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             wind
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our growing wind portfolio is diversified across attractive
//                 power markets in North America, South America, Europe and Asia.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     2006
//                   </h1>
//                   <p className="text-gray-600">
//                     DEVELOPMENT OF FIRST WIND PROJECT
//                   </p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     5,800
//                   </h1>
//                   <p className="text-gray-600">MW INSTALLED CAPACITY</p>
//                 </span>
//                 {/* <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     13
//                   </h1>
//                   <p className="text-gray-600">PORT</p>
//                 </span> */}
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/ren-3.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             solar
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our energy businesses in North America and Asia Pacific generate
//                 stable revenues through long-term contracts with large-scale
//                 customers.One of the fastest-growing sources of renewable
//                 energy, utility-scale solar offers high cash margins and diverse
//                 and scalable applications.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     156
//                   </h1>
//                   <p className="text-gray-600"> INSTALLED CAPACITY</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     4
//                   </h1>
//                   <p className="text-gray-600"> CONTINENTS</p>
//                 </span>
//                 {/* <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     1.6 million
//                   </h1>
//                   <p className="text-gray-600">
//                     RESIDENTIAL INFRASTRUCTURE CUSTOMERS
//                   </p>
//                 </span> */}
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/ren-4.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             DISTRIBUTED GENERATION
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our commercial and industrial distributed-solar generation
//                 portfolio offers consumers access to power at the point of
//                 consumption.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     1,300 MW
//                   </h1>
//                   <p className="text-gray-600">
//                     DISTRIBUTED GENERATION PORTFOLIO
//                   </p>
//                 </span>
//                 {/* <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     20,000 km
//                   </h1>
//                   <p className="text-gray-600">OF FIBER BACKBONE</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     52
//                   </h1>
//                   <p className="text-gray-600">DATA CENTERS</p>
//                 </span> */}
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/ren-5.jpg"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="py-11 px-6">
//           <h2 className="font-bold uppercase text-xl mb-4 text-gray-500 mt-10">
//             storage
//           </h2>
//           <hr className="mx-auto py-8" />
//           <div className="flex flex-shrink gap-8 justify-center ">
//             <span className="w-1/2 sm:text-lg text:sm flex flex-col gap-8 items-start justify-start">
//               <p>
//                 Our pumped hydro and battery storage facilities in the U.S. and
//                 U.K. help to stabilize the electrical grid.
//               </p>
//               <div className="flex-col flex gap-4">
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     2,700 MW
//                   </h1>
//                   <p className="text-gray-600"> INSTALLED CAPACITY</p>
//                 </span>
//                 <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     306
//                   </h1>
//                   <p className="text-gray-600"> TRIPLE NET LEASE ASSETS</p>
//                 </span>
//                 {/* <span>
//                   <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl sm:text-4xl font-bold">
//                     1.6 million
//                   </h1>
//                   <p className="text-gray-600">
//                     RESIDENTIAL INFRASTRUCTURE CUSTOMERS
//                   </p>
//                 </span> */}
//               </div>
//             </span>
//             <div className="w-1/2">
//               <img
//                 className="w-full"
//                 src="../img/ren-6.png"
//                 alt="estate-1"
//               />
//             </div>
//           </div>
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

import BackgroundImageWithText from './bg-img';
import Footer from './footer.comp';
import NavBar from './homenav';

export default function RenewableEnergy() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'INVESTMENT PRODUCTS'}
        h1={'RENEWABLE'}
        h1Blue={'POWER'}
        backgroundImage={'../img/renewable-bg.jpg'}
      />
      <main className="">
        <div className="bg-gray-200 px-6 py-10">
          <h1 className="font-bold text-2xl md:text-3xl py-4 mb-4">
            RENEWABLE POWER
          </h1>
          <span className="flex flex-col md:flex-row items-center gap-6 pb-10">
            <p className="w-full md:w-1/2 leading-7 text-sm md:text-lg">
              We are one of the world's largest investors in renewable power,
              with approximately 21,000 megawatts of generating capacity. Our
              assets, located in North and South America, Europe, India and
              China, comprise a diverse technology base of hydro, wind,
              utility-scale solar, distributed generation, storage and other
              renewable technologies. We utilize our fully
            </p>
            <p className="w-full md:w-1/2 leading-7 text-sm md:text-lg">
              integrated global operating platform and in-house expertise to
              maintain facilities, organically add value and efficiently
              integrate new assets, realizing cost synergies in the process. Our
              business is underpinned by stable cash flows, with the majority of
              our power contracted under long-term, inflation-linked contracts.
            </p>
          </span>
        </div>
        <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-xl md:text-2xl text-center">
          RENEWABLE POWER FOR A CLEANER, BRIGHTER TOMORROW
        </h1>

        <div className="py-11 px-6">
          <hr className="mx-auto py-8" />
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            <span className="w-full md:w-1/2 text-sm md:text-lg flex flex-col gap-8 items-center justify-center">
              <p>
                The acquisition of the TerraForm companies strengthened Convex
                Integrated position as a global leader in renewable power,
                adding significant wind and solar assets as well as operating
                platforms in India and China.
              </p>
              <div>
                <h2 className="font-bold text-lg md:text-xl mb-4 text-gray-500 mt-10">
                  RENEWABLE POWER LEADERSHIP
                </h2>
                <p>
                  Our leaders share a commitment to our time-tested approach to
                  investing, hands-on value creation and practices that have a
                  positive impact on our communities.
                </p>
              </div>
            </span>
            <div className="w-full md:w-1/2">
              <img
                className="w-full"
                src="../img/ren.png"
                alt="estate-1"
              />
            </div>
          </div>
        </div>
        <h1 className="font-bold font-sans w-72 mx-auto py-4 mt-6 text-xl md:text-2xl text-center">
          RENEWABLE POWER SECTORS
        </h1>
        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-lg md:text-xl mb-4 text-gray-500 mt-10">
            HYDRO
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            <span className="w-full md:w-1/2 text-sm md:text-lg flex flex-col gap-8 items-start justify-start">
              <p>
                Our hydro power assets are characterized by a perpetual asset
                life, high cash margins, and storage capacity.
              </p>
              <div className="flex-col flex gap-4">
                <span>
                  <h1 className="border-b-4 py-2 uppercase mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    8,000 MW
                  </h1>
                  <p className="text-gray-600">HYDRO CAPACITY</p>
                </span>
                <span>
                  <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl md:text-4xl font-bold">
                    222
                  </h1>
                  <p className="text-gray-600">HYDRO GENERATION FACILITIES</p>
                </span>
                <span>
                  <h1 className="border-b-4 py-2 mb-2 uppercase border-blue-900 text-xl md:text-4xl font-bold">
                    83
                  </h1>
                  <p className="text-gray-600">RIVER SYSTEMS</p>
                </span>
              </div>
            </span>
            <div className="w-full md:w-1/2">
              <img
                className="w-full"
                src="../img/ren-1.png"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-lg md:text-xl mb-4 text-gray-500 mt-10">
            WIND
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            <span className="w-full md:w-1/2 text-sm md:text-lg flex flex-col gap-8 items-start justify-start">
              <p>
                Our growing wind portfolio is diversified across attractive
                power markets in North America, South America, Europe and Asia.
              </p>
              <div className="flex-col flex gap-4">
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    2006
                  </h1>
                  <p className="text-gray-600">
                    DEVELOPMENT OF FIRST WIND PROJECT
                  </p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    5,800
                  </h1>
                  <p className="text-gray-600">MW INSTALLED CAPACITY</p>
                </span>
              </div>
            </span>
            <div className="w-full md:w-1/2">
              <img
                className="w-full"
                src="../img/ren-3.png"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-lg md:text-xl mb-4 text-gray-500 mt-10">
            SOLAR
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            <span className="w-full md:w-1/2 text-sm md:text-lg flex flex-col gap-8 items-start justify-start">
              <p>
                Our energy businesses in North America and Asia Pacific generate
                stable revenues through long-term contracts with large-scale
                customers. One of the fastest-growing sources of renewable
                energy, utility-scale solar offers high cash margins and diverse
                and scalable applications.
              </p>
              <div className="flex-col flex gap-4">
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    156
                  </h1>
                  <p className="text-gray-600">INSTALLED CAPACITY</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    4
                  </h1>
                  <p className="text-gray-600">CONTINENTS</p>
                </span>
              </div>
            </span>
            <div className="w-full md:w-1/2">
              <img
                className="w-full"
                src="../img/ren-4.png"
                alt="estate-1"
              />
            </div>
          </div>
        </div>

        <div className="py-11 px-6">
          <h2 className="font-bold uppercase text-lg md:text-xl mb-4 text-gray-500 mt-10">
            DISTRIBUTED GENERATION
          </h2>
          <hr className="mx-auto py-8" />
          <div className="flex flex-col md:flex-row gap-8 justify-center">
            <span className="w-full md:w-1/2 text-sm md:text-lg flex flex-col gap-8 items-start justify-start">
              <p>
                Our distributed generation business focuses on implementing
                distributed energy technologies that are highly efficient,
                modular and flexible, and offer compelling economics and low
                environmental impact.
              </p>
              <div className="flex-col flex gap-4">
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    1,000
                  </h1>
                  <p className="text-gray-600">MW DISTRIBUTED CAPACITY</p>
                </span>
                <span>
                  <h1 className="border-b-4 uppercase py-2 mb-2 border-blue-900 text-xl md:text-4xl font-bold">
                    150
                  </h1>
                  <p className="text-gray-600">PROJECTS</p>
                </span>
              </div>
            </span>
            <div className="w-full md:w-1/2">
              <img
                className="w-full"
                src="../img/ren-5.png"
                alt="estate-1"
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
