import FAQItem from '../component/faq';
import Footer from '../component/home/footer.comp';
import NavBar from '../component/home/homenav';
import BackgroundImageWithText from '../component/home/bg-img';

export default function FaqPage() {
  return (
    <div>
      <NavBar />
      <BackgroundImageWithText
        p={'FAQ'}
        h1={'FREQUENTLY ASKED'}
        h1Blue={'QUESTION'}
        backgroundImage={'../img/faq.jpg'}
      />
      <FAQItem
        question={'What is convex integrated?'}
        answer={`Convex Integrated is a nex-Gen trading platform offering tailored crypto and commodity investment opportunities to distinct individuals coming from diverse backgrounds. midastouchlimited.net's purpose is to create long-term wealth and income for investors all over the world by leveraging its diverse team and unyielding AI tools.`}
      />
      <FAQItem
        question={'How do we Earn Profit?'}
        answer={`We generate revenue by trading in multiple segments using artificial intelligence and machine learning. We employ master brokers and automation tools to effectively screen and trade in different segments`}
      />
      <FAQItem
        question={'How do we utilize the Investor funds?'}
        answer={`Investor funds are used to trade in a variety of markets, employing a variety of strategies and collaborating with a variety of professional experts.`}
      />
      <FAQItem
        question={'What is Crypto Trading?'}
        answer={`The act of speculating on cryptocurrency price movements through a CFD trading account, or buying and selling the underlying coins through an exchange, is known as "crypto trading."`}
      />
      <FAQItem
        question={
          'What are the major segaments that Convex Integrated trades in?'
        }
        answer={`Convex Integrated is actively involved in crypto, precious metals, oil and natural gas trading.`}
      />

      <FAQItem
        question={'Where is the Company created?'}
        answer={`Convex Integrated is  registered and incorporation in US, New Uk and Luxembourge.`}
      />
      <FAQItem
        question={'How does trading in Convex Integrated trades works?'}
        answer={`Following a thorough planning process, Convex Integrated  employs recognized expertise knowledge and automation tools to effectively screen and trade in various segments. Our members simply make a deposit and then withdraw their profits. There is no prior trading experience required.`}
      />
      <FAQItem
        question={
          'What are the advantages of  Convex Integrated over other competitor?'
        }
        answer={`There are a few distinguishing characteristics that set us apart from the competition. We create informative trading solutions and market opinions, and we support our team of experts by encouraging innovative trading approaches and ensuring good returns for investors.`}
      />
      <FAQItem
        question={
          'who is eligible to participate in Convex Integrated Do you accept investors from all over the world?'
        }
        answer={`Anyone who is a legal resident of any country in the world is eligible to join Convex Integrated.`}
      />
      <FAQItem
        question={'Why choose Convex Integrated ?'}
        answer={`Convex Integrated distinguished by the caliber of our diverse line of business. Our culture of operating in multiple lines of business has contributed to the formation of our legacy and the shaping of our future, allowing us to strengthen our business and provide value to our clients.`}
      />
      <Footer />
    </div>
  );
}
