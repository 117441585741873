import React, { useState, useNavigation } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';

const SupportTicket = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const token = Cookies.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      if (file) {
        formData.append('file', file);
      }

      await axios.post(`${BASEURLDEV}/support/bookTicket`, formData, {
        headers,
      });
      setSuccess(true);
    } catch (error) {
      console.error('Error submitting support ticket:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccess = () => {
    setSuccess(false);
    setTitle('');
    setDescription('');
    setFile(null);
  };

  return (
    <div className="mt-8 bg-gray-900 text-white rounded p-6">
      <h2 className="text-lg font-semibold mb-4">Submit a Support Ticket</h2>
      {success ? (
        <div className="p-4 bg-green-500 text-white rounded">
          <h3 className="text-lg font-semibold">Thank You!</h3>
          <p>Your support ticket was submitted successfully.</p>
          <button
            onClick={handleCloseSuccess}
            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Close
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block font-medium text-gray-300">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block font-medium text-gray-300">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="file"
              className="block font-medium text-gray-300">
              Attach File
            </label>
            <input
              type="file"
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Ticket'}
          </button>
        </form>
      )}
    </div>
  );
};

export default SupportTicket;
