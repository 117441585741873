import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';

const ViewAllTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = Cookies.get('token');

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(`${BASEURLDEV}/support/tickets`, {
          headers,
        });
        console.log(response);
        setTickets(response.data.tickets);
      } catch (error) {
        console.error('Error fetching tickets:', error.response);
        handleServerError(error.response.status, error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [token]);

  return (
    <div className="mt-8 bg-gray-900 text-white rounded p-6">
      <h2 className="text-lg font-semibold mb-4">All Support Tickets</h2>
      {loading ? (
        <p>Loading tickets...</p>
      ) : (
        <div>
          {tickets.length > 0 ? (
            tickets.map((ticket) => (
              <div
                key={ticket.id}
                className="mb-4 p-4 bg-gray-800 rounded">
                <h3 className="text-lg font-semibold">{ticket.title}</h3>
                <p>{ticket.description}</p>
                {ticket.file && (
                  <a
                    href={`${BASEURLDEV}/support/ticket/file/${ticket.file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline">
                    View Attachment
                  </a>
                )}
              </div>
            ))
          ) : (
            <p>No tickets found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllTickets;
