import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASEURLDEV } from '../../constants/api';
import { handleAdminServerError } from './handleAdminErr';
import Pagination from '../pagination';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

const SeeAllDeposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // Fetch deposits with pagination, month, and year
  const fetchDeposits = async (page = 1, month = '', year = currentYear) => {
    setLoading(true);
    const token = Cookies.get('adminToken');

    try {
      const response = await axios.get(`${BASEURLDEV}/admin/deposits`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          month,
          year,
        },
      });
      console.log(response);
      setDeposits(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setError('Error fetching deposits');
      console.error('Error fetching deposits:', error.response);
      handleAdminServerError(
        error.response?.status,
        error.response?.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchDeposits(page, selectedMonth, selectedYear);
  };

  // Handle month and year changes
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // Apply filter
  const handleFilter = () => {
    fetchDeposits(1, selectedMonth, selectedYear);
  };

  useEffect(() => {
    fetchDeposits(currentPage, selectedMonth, selectedYear);
  }, [currentPage, selectedMonth, selectedYear]);

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">All Deposits</h1>

        {error && <p className="text-red-500">{error}</p>}

        <div className="mb-6 flex flex-col sm:flex-row justify-between items-center">
          <div className="flex flex-col sm:flex-row items-center mt-4 sm:mt-0">
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm mt-2 sm:mt-0 sm:ml-2">
              <option value="">Select Month</option>
              {months.map((month, index) => (
                <option
                  key={index}
                  value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              value={selectedYear}
              onChange={handleYearChange}
              className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm mt-2 sm:mt-0 sm:ml-2">
              <option value="">Select Year</option>
              {years.map((year) => (
                <option
                  key={year}
                  value={year}>
                  {year}
                </option>
              ))}
            </select>
            <button
              onClick={handleFilter}
              className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-sm">
              Filter
            </button>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center py-8">
            <svg
              className="animate-spin h-8 w-8 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path
                fill="none"
                d="M0 0h24v24H0z"
              />
              <path d="M12 4V1.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V4h-4zm4 1v4h1.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H16v4h-4v-4H9v4H5V6h4V2h1.5a.5.5 0 0 1 .5.5V4h4zM5 19v1h14v-1H5z" />
            </svg>
          </div>
        ) : (
          <div>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="border-b">
                  <th className="py-2 px-4 text-left">Name</th>
                  <th className="py-2 px-4 text-left">Email</th>
                  <th className="py-2 px-4 text-left">Amount</th>
                  <th className="py-2 px-4 text-left">Status</th>
                  <th className="py-2 px-4 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {deposits.map((deposit) => (
                  <tr
                    key={deposit._id}
                    className="border-b">
                    <td className="py-2 px-4">{deposit.user?.firstname}</td>
                    <td className="py-2 px-4">{deposit.user?.email}</td>

                    <td className="py-2 px-4">{deposit.amount}</td>
                    <td className="py-2 px-4 text-green-600">
                      {deposit.status}
                    </td>
                    <td className="py-2 px-4">
                      {new Date(deposit.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SeeAllDeposits;
