import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserAuthComp from '../component/usercomp.auth';
import countries from '../constants/country';
import AtSymbolIcon from '../icons/at.icon';
import FlagIcon from '../icons/flag.icon';
import GenderIcon from '../icons/gender.icon';
import PasswordIcon from '../icons/password.icon';
import SignUpIcon from '../icons/signin.icon';
import UserIcon from '../icons/user.icons';
import { useAuth } from '../context/user.context';
import { useState, useEffect } from 'react';
import PROFILEPICS from '../constants/profile';
import Notify from '../component/toastify.comp';
import Spinner from '../component/spinner';

export default function SignUpPage() {
  const { signUp, isLoading } = useAuth();
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referrerId, setReferrerId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the referral ID from the query parameters
    const queryParams = new URLSearchParams(location.search);
    const refId = queryParams.get('ref');
    setReferrerId(refId);
  }, [location.search]);

  async function handleSignUp(e) {
    e.preventDefault();
    const isSignedUp = await signUp(
      firstname,
      lastname,
      email,
      gender,
      country,
      password,
      confirmPassword,
      PROFILEPICS,
      referrerId
    );
    if (isSignedUp) navigate('/login');
  }

  return (
    <div>
      <UserAuthComp
        handler={handleSignUp}
        type={'Register'}>
        <div className="md:grid md:grid-cols-2 md:gap-6 flex flex-col gap-4">
          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <UserIcon />
            <input
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              placeholder="Firstname"
              value={firstname}
              className="bg-transparent text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <UserIcon />
            <input
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              placeholder="Lastname"
              value={lastname}
              className="bg-transparent text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>
          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <AtSymbolIcon />
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="youremail@gmail.com"
              value={email}
              className="bg-transparent text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <GenderIcon />
            <select
              onChange={(e) => setGender(e.target.value)}
              value={gender}
              className="bg-transparent text-slate-950 flex-1 outline-none">
              <option
                value=""
                disabled>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <FlagIcon />
            <select
              onChange={(e) => setCountry(e.target.value)}
              value={country}
              className="bg-transparent text-slate-950 flex-1 outline-none">
              <option
                value=""
                disabled>
                Select country
              </option>
              {countries.map((country) => (
                <option
                  key={country}
                  value={country}>
                  {country}
                </option>
              ))}
            </select>
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <PasswordIcon />
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="***************"
              value={password}
              className="bg-transparent text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>

          <span className="flex items-center gap-2 p-2 rounded-md bg-transparent border border-gray-300">
            <PasswordIcon />
            <input
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              className="bg-transparent text-slate-50 placeholder-gray-500 flex-1 outline-none"
            />
          </span>
        </div>

        <div className="flex flex-wrap items-center text-slate-300 justify-between mt-8">
          <span className="flex items-center mt-8 md:mt-0 gap-2">
            <p className="mr-2">Already have an account?</p>
            <Link
              to="/login"
              className="underline">
              Login
            </Link>
          </span>
          <span className="px-4 py-2 flex items-center mt-4 rounded-md gap-2 border border-gray-300 bg-slate-800 hover:bg-slate-700 transition-colors">
            <SignUpIcon />
            {!isLoading ? (
              <button
                className="outline-none text-slate-50"
                onClick={handleSignUp}>
                Sign Up
              </button>
            ) : (
              <Spinner />
            )}
          </span>
        </div>
      </UserAuthComp>
      <Notify />
    </div>
  );
}
