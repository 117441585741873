import { useEffect, useState } from 'react';
import RecentTab from './overview.comp';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import Cookies from 'js-cookie';

export default function RecentWithdrawComp() {
  const headers = [
    '#',
    'Date',
    'Type',
    'Amount',
    'Method',
    'Balance',
    'Remark',
    'TxId',
  ];

  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const token = Cookies.get('token');

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  const fetchWithdrawals = async () => {
    try {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BASEURLDEV}/account/getwithdrawTx`, {
        headers,
      });

      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

      const recentWithdrawals = response.data.doc.transactions.filter(
        (transaction) =>
          transaction.type === 'debit' &&
          transaction.status === 'successful' &&
          new Date(transaction.createdAt) >= twentyFourHoursAgo
      );

      console.log(response, 'rwTx');
      setData(recentWithdrawals);
    } catch (error) {
      console.error('Error fetching withdrawals:', error.response);
      handleServerError(error.response.status, error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RecentTab
      title="Recent Withdrawal"
      headers={headers}
      data={data || []}
      link={'/dashboard/deposit-history'}
    />
  );
}
