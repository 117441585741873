import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from 'react';
import axios from 'axios';
import { BASEURLDEV } from '../constants/api';
import { handleServerError } from '../utils/error.handler';
import notifySuccessMsg from '../utils/notify.succes';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode'; // Correct import

const AuthContext = createContext();

const initialState = {
  user: null,
  isAuthenticated: false,
  token: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        token: action.payload.token,
      };
    case 'signUp':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        token: action.payload.token,
      };
    case 'updateUser':
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      };
    case 'logout':
      return { ...state, user: null, isAuthenticated: false, token: '' };
    default:
      throw new Error('No action was found');
  }
}

function AuthProvider({ children }) {
  const [{ user, isAuthenticated, token }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const [isLoading, setIsLoading] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [ROI, setROI] = useState(0);
  const [invNotification, setInvNotification] = useState({
    type: '',
    message: '',
  });

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const { user, exp } = jwtDecode(token);
      if (exp * 1000 > new Date().getTime()) {
        dispatch({ type: 'login', payload: { user, token } });
      }
    }
  }, []);

  async function login(email, password) {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASEURLDEV}/user/login`, {
        email,
        password,
      });
      const { doc } = response.data;
      const { token, user } = doc;
      const { exp: expiresIn } = jwtDecode(token);

      console.log(user);

      notifySuccessMsg(response.data.message);

      localStorage.setItem('user', JSON.stringify(user));

      const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
      Cookies.set('token', token, {
        expires: expirationDate,
        secure: true,
        sameSite: 'strict',
      });

      dispatch({ type: 'login', payload: { user, token } });

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      handleServerError(error.response?.status, error.response?.data?.message);
      console.log('Login failed:', error.response);
    }
  }

  function logout() {
    Cookies.remove('token');
    dispatch({ type: 'logout' });
    return true;
  }

  async function signUp(
    firstname,
    lastname,
    email,
    gender,
    country,
    password,
    confirmPassword,
    image,
    referrerId = null
  ) {
    try {
      setIsLoading(true);

      // Ensure confirmPassword is not empty
      if (!confirmPassword) {
        throw new Error('Confirm password is required');
      }

      const response = await axios.post(`${BASEURLDEV}/user/newUser`, {
        firstname,
        lastname,
        email,
        gender,
        country,
        password,
        confirmPassword,
        image,
        referrerId,
      });

      if (response.status === 201) {
        const { user, token } = response.data;
        notifySuccessMsg(response.data.message);
        dispatch({ type: 'signUp', payload: { user, token } });
        localStorage.setItem('user', JSON.stringify(user));
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log('Signup failed:', error.response);
        handleServerError(error.response.status, error.response.data.message);
      } else {
        console.error('Signup failed:', error.response);
      }
    }
  }

  async function updateUser(userData) {
    setIsLoading(true);
    try {
      const token = Cookies.get('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.put(
        `${BASEURLDEV}/user/updateMe`,
        userData,
        {
          headers,
        }
      );
      const updatedUser = response.data.user;

      // Update local storage with the updated user data
      localStorage.setItem('user', JSON.stringify(updatedUser));

      // Dispatch the updateUser action
      dispatch({ type: 'updateUser', payload: { user: updatedUser } });

      notifySuccessMsg('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error.response);
      handleServerError(error.response?.status, error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function reinvestFunds(
    investmentId,
    totalReturn,
    type,
    duration,
    interest
  ) {
    try {
      console.log(totalReturn, 'return');
      setIsLoading(true);
      const token = Cookies.get('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${BASEURLDEV}/investment/reinvest`,
        { investmentId, totalReturn, type, duration, interest },
        { headers }
      );

      console.log(response);
      notifySuccessMsg(response.data.message);
      setInvNotification({
        type: 'success',
        message: 'Investment reinvested successfully!',
      });
      fetchInvestments();
    } catch (error) {
      setInvNotification({
        type: 'error',
        message: error.response?.data?.message,
      });
      console.error('Error reinvesting funds:', error.response);
      handleServerError(error.response?.status, error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function addToBalance(investmentId, totalReturn) {
    try {
      setIsLoading(true);
      const token = Cookies.get('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${BASEURLDEV}/investment/add-to-balance`,
        { investmentId, totalReturn },
        { headers }
      );

      console.log(response, 'add to balance');
      notifySuccessMsg(response.data.message);
      setInvNotification({
        type: 'success',
        message: 'Amount added to balance successfully!',
      });
      fetchInvestments();
    } catch (error) {
      console.error('Error adding to balance:', error.response);
      setInvNotification({
        type: 'error',
        message: error.response?.data?.message,
      });
      handleServerError(error.response?.status, error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }
  const fetchInvestments = async () => {
    const token = Cookies.get('token');
    try {
      setIsLoading(true);

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BASEURLDEV}/investment/getInv`, {
        headers,
      });
      console.log(response, 'investment');
      setInvestments(response.data.data.investments);

      setROI(response.data.data.totalAccumulatedInterest);
    } catch (error) {
      console.error('Error fetching investments:', error.response);
      handleServerError(error.response?.status, error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        invNotification,
        setInvNotification,
        user,
        isAuthenticated,
        token,
        login,
        signUp,
        logout,
        isLoading,
        fetchInvestments,
        investments,
        ROI,
        updateUser,
        reinvestFunds,
        addToBalance,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
