// import React from 'react';
// import Logo from '../logocomp';

// export default function Footer() {
//   return (
//     <footer className="bg-gray-900 text-slate-50 ">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8 lg:px-8">
//         <div className="mb-8">
//           <Logo />
//           <hr className="bg-blue-700 my-4" />
//           <p className="text-sm sm:text-base">
//             Convex Integrated is more than just finance, it’s hope for an
//             average entrepreneur to stand, evolve.
//           </p>
//           <a
//             href="https://ibb.co/K06xNJF"
//             alt="certificate"
//             className="bg-blue-600 mt-8 mb-8 block text-center uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
//             View Certification of Incorporation
//           </a>
//         </div>
//         <div className="flex flex-wrap justify-between space-y-8 sm:space-y-0">
//           <div className="w-full sm:w-1/3">
//             <h2 className="font-semibold mb-4">MAIN SERVICES</h2>
//             <ul className="space-y-2 text-sm">
//               <li>
//                 <a
//                   href="/crypto"
//                   className="hover:underline">
//                   Cryptocurrency
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/real-estate"
//                   className="hover:underline">
//                   Real Estate
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/stocks"
//                   className="hover:underline">
//                   Stock
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/fixed-income"
//                   className="hover:underline">
//                   Fixed Incomes
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/multi-asset"
//                   className="hover:underline">
//                   Multi Assets
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="w-full sm:w-1/3">
//             <h2 className="font-semibold mb-4">USEFUL LINKS</h2>
//             <ul className="space-y-2 text-sm">
//               <li>
//                 <a
//                   href="/about-us"
//                   className="hover:underline">
//                   About Us
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/responsibility"
//                   className="hover:underline">
//                   Corporate Responsibility
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/financial-planning"
//                   className="hover:underline">
//                   Planning Services
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/contact"
//                   className="hover:underline">
//                   Contact Us
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/signUp"
//                   className="hover:underline">
//                   FAQs
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="w-full sm:w-1/3">
//             <h2 className="font-semibold mb-4">CONTACT US</h2>
//             <ul className="space-y-2 text-sm">
//               <li>
//                 <a
//                   href="#"
//                   className="hover:underline">
//                   6th Floor Point House 6 Front Street Hamilton, HM 11, Bermuda
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="#"
//                   className="hover:underline">
//                   52 Lime Street, London, EC3M 7AF, UK
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="#"
//                   className="hover:underline">
//                   Bucktrout House, Glategny Esplanade, Guernsey GY1 1WR,
//                   Guernsey
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="#"
//                   className="hover:underline">
//                   37 Boulevard Joseph II, 2ème étage, L-1840 Luxembourg,
//                   Grand-Duchy of Luxembourg.
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="mailto:support@convexintegrated.com"
//                   className="hover:underline">
//                   Support Team
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div className="w-full bg-black text-xs text-center py-4 mt-8">
//         <div className="flex justify-center space-x-4">
//           <a
//             href="/about"
//             className="hover:underline">
//             About Us
//           </a>
//           <a
//             href="/faq"
//             className="hover:underline">
//             FAQ
//           </a>
//           <a
//             href="/blog"
//             className="hover:underline">
//             Blog
//           </a>
//           <a
//             href="/contact"
//             className="hover:underline">
//             Contact Us
//           </a>
//         </div>
//       </div>
//     </footer>
//   );
// }

import React from 'react';
import Logo from '../logocomp';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-slate-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8 lg:px-8">
        <div className="mb-8">
          <Logo />
          <hr className="bg-blue-700 my-4" />
          <p className="text-sm sm:text-base">
            Convex Integrated is more than just finance, it’s hope for an
            average entrepreneur to stand, evolve.
          </p>
          <a
            href="/verify"
            alt="certificate"
            className="bg-blue-600 mt-8 mb-8 block text-center uppercase w-full sm:w-60 text-white px-4 py-3 text-xs sm:text-sm hover:bg-blue-300 transition">
            View Certification of Incorporation
          </a>
        </div>
        <div className="grid grid-cols-2 gap-6 items-center sm:grid-cols-3 justify center">
          <div className="w-full sm:w-1/3">
            <h2 className="font-semibold mb-4">MAIN SERVICES</h2>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="/crypto"
                  className="hover:underline">
                  Cryptocurrency
                </a>
              </li>
              <li>
                <a
                  href="/real-estate"
                  className="hover:underline">
                  Real Estate
                </a>
              </li>
              <li>
                <a
                  href="/stocks"
                  className="hover:underline">
                  Stock
                </a>
              </li>
              <li>
                <a
                  href="/fixed-income"
                  className="hover:underline">
                  Fixed Incomes
                </a>
              </li>
              <li>
                <a
                  href="/multi-asset"
                  className="hover:underline">
                  Multi Assets
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full sm:w-1/3">
            <h2 className="font-semibold mb-4">USEFUL LINKS</h2>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="/about-us"
                  className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/responsibility"
                  className="hover:underline">
                  Corporate Responsibility
                </a>
              </li>
              <li>
                <a
                  href="/financial-planning"
                  className="hover:underline">
                  Planning Services
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="hover:underline">
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  className="hover:underline">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full sm:w-4/5">
            <h2 className="font-semibold mb-4">CONTACT US</h2>
            <ul className="space-y-2 text-sm">
              <li>
                6th Floor Point House 6 Front Street Hamilton, HM 11, Bermuda
              </li>
              <li>52 Lime Street, London, EC3M 7AF, UK</li>
              <li>
                Bucktrout House, Glategny Esplanade, Guernsey GY1 1WR, Guernsey
              </li>
              <li>
                37 Boulevard Joseph II, 2ème étage, L-1840 Luxembourg,
                Grand-Duchy of Luxembourg.
              </li>
              <li>
                <a
                  href="mailto:support@convexintegrated.com"
                  className="hover:underline">
                  Support Team
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full bg-black text-xs text-center py-4 mt-8">
        <div className="flex justify-center space-x-4">
          <a
            href="/about-us"
            className="hover:underline">
            About Us
          </a>
          <a
            href="/faq"
            className="hover:underline">
            FAQ
          </a>
          <a
            href="/blog"
            className="hover:underline">
            Blog
          </a>
          <a
            href="/contact"
            className="hover:underline">
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
}
